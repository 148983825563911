import { DashboardRbacService } from "@sk/services";
import { AppCard, BusyLoader, PageInfo } from "@sk/uis";
import { useState } from "react";
import RoleDashAccess from "./components/RoleDashAccess";
import RoleDashEmp from "./components/RoleDashEmp";
import RoleDashEmpInfo from "./components/RoleDashEmpInfo";
import RoleDashTabs from "./components/RoleDashTabs";
import RoleDashMgmt from "./components/RoleDashMgmt";
import RoleDashAudit from "./components/RoleDashAudit";
import RoleDashStoreAccess from "./components/RoleDashStoreAccess";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Roles List",
  },
  {
    name: "Manage Employee Access",
  },
];

const containerStyle = {
  height: "calc(100vh - 320px)",
  overflowY: "auto",
  overflowX: "hidden",
};

const dashboardOptions = DashboardRbacService.getRbacs().map((e) => ({
  label: e.name,
  value: e.key,
}));

dashboardOptions.unshift({ label: "Choose", value: "" });

const RolesDashboard = () => {
  const navigate = useNavigate();

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [busyLoader, setBusyLoader] = useState({ show: false });

  const [activeTab, setActiveTab] = useState("dashboard");

  const handleEmployeeInfo = (e) => {
    if (e.action === "default") {
      setSelectedEmployee(e.emp);
    }

    if (e.action === "select") {
      setActiveTab("dashboard");
      setSelectedEmployee(e.emp);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab.key);
  };

  return (
    <>
      <PageInfo
        title="Manage Employee Access"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      <div className="row">
        <div className="col-3">
          <RoleDashEmp callback={handleEmployeeInfo} />
        </div>
        <div className="col-9">
          {selectedEmployee?._id && (
            <>
              <RoleDashEmpInfo data={selectedEmployee} />
              <AppCard noPad>
                <RoleDashTabs
                  callback={handleTabChange}
                  activeTabKey={activeTab}
                />
                <div className="p-3 custom-scrollbar" style={containerStyle}>
                  {activeTab === "dashboard" && (
                    <RoleDashAccess empId={selectedEmployee?._id} />
                  )}
                  {activeTab === "management" && (
                    <RoleDashMgmt empId={selectedEmployee?._id} />
                  )}
                  {activeTab === "audit" && (
                    <RoleDashAudit empId={selectedEmployee?._id} />
                  )}
                  {activeTab === "store" && (
                    <RoleDashStoreAccess empId={selectedEmployee?._id} />
                  )}
                </div>
              </AppCard>
            </>
          )}
        </div>
      </div>

      {/* <RolesDashViewEmpListModal
        show={empModal.show}
        callback={empCb}
        feature={dashboard}
      /> */}

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default RolesDashboard;
