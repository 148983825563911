import { useFetchUrlQueryString } from "@sk/hooks";
import { appConfigs, NavService, NotificationService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  PageInfo,
  PageLoader,
  SelectInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import WhatsAppTemplateActions from "./components/WhatsAppTemplateActions";
import WhatsAppTemplateBody from "./components/WhatsAppTemplateBody";
import WhatsAppTemplateFooter from "./components/WhatsAppTemplateFooter";
import WhatsAppTemplateHeader from "./components/WhatsAppTemplateHeader";
import WhatsAppTemplatePreviewContainer from "./components/WhatsAppTemplatePreviewContainer";
import validationSchema from "./validationSchema"; // Adjust the path as necessary
import { yupResolver } from "@hookform/resolvers/yup";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "WhatsApp Templates",
    link: "/utility/whatsapp-template",
  },
  {
    name: "Manage Template",
  },
];

const previewStyle = {
  top: "100px",
};

const defaultValues = {
  actions: [],
  mediaType: "none",
  headerText: "",
  title: "",
  language: "en",
  partner: "",
  bodyDynamicSample: "",
  headerDynamicSample: "",
  body: "",
  footer: "",
  headerImage: "",
  action: {
    type: "",
    couponCode: "",
    websiteUrl: "",
    phoneNumber: "",
    quickReplyText: "",
    commonButtonText: "",
  },
  to: "",
  status: "active",
};

const languages = Object.keys(NotificationService.languageCodes).map((key) => ({
  value: key,
  label: NotificationService.languageCodes[key],
}));

const statusOptions = [
  { value: "", label: "Select Status" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const WhatsAppTemplateManage = () => {
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema), // Correct usage
  });
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busyloader, setBusyloader] = useState({ show: false });

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await NotificationService.getPartner({
          filter: {
            service: "WhatsApp",
          },
          select: "name,key",
        });
        if (Array.isArray(response.resp)) {
          setPartners([
            { value: "", label: "Select Partner" },
            ...response.resp.map((p) => ({ value: p.key, label: p.name })),
          ]);
        } else {
          setPartners([]);
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, []);

  useEffect(() => {
    const fetchTemplateDetails = async () => {
      const id = query.id;
      if (id) {
        setLoading(true);
        try {
          const response = await NotificationService.getList({
            filter: {
              _id: id,
            },
          });
          if (response.statusCode === 200) {
            const templateData = response.resp?.[0] || {};

            formMethods.setValue("title", templateData.name);
            formMethods.setValue(
              "status",
              templateData.isActive ? "active" : "inactive"
            );
            formMethods.setValue("partner", templateData.partner);
            formMethods.setValue("mediaType", templateData.default.headerType);
            formMethods.setValue("body", templateData.default.body);
            formMethods.setValue("footer", templateData.default.footer);
            formMethods.setValue(
              "bodyDynamicSample",
              templateData.default.bodyText.join(",")
            );
            formMethods.setValue(
              "headerDynamicSample",
              templateData.default.headerText.join(",")
            );
            formMethods.setValue("to", templateData.to);
            formMethods.setValue("language", templateData.languageCode);

            if (templateData.default.headerType === "text") {
              formMethods.setValue("headerText", templateData.default.header);
            } else if (templateData.default.headerType === "image") {
              formMethods.setValue("headerImage", templateData._headerAsset);
            }

            formMethods.setValue("to", (templateData.to || []).join(","));
          } else {
            console.error("Error fetching template details:", response.message);
          }
        } catch (error) {
          console.error("Error fetching template details:", error);
        } finally {
          const t = setTimeout(() => {
            clearTimeout(t);
            setLoading(false);
          }, 1000);
        }
      }
    };

    fetchTemplateDetails();
  }, [formMethods, query.id]);

  const getActionsPayload = () => {
    let payload = {
      buttons: [],
    };
    const actionsArray = formMethods.getValues("actions") || [];
    actionsArray.forEach((action) => {
      if (action.type === "quick_replies") {
        payload.buttons.push({
          type: "QUICK_REPLY",
          text: action.quickReplyText,
        });
      } else if (action.type === "website_url") {
        payload.buttons.push({
          type: "URL",
          text: action.buttonText,
          url: action.websiteUrl,
        });
      } else if (action.type === "phone_number") {
        payload.buttons.push({
          type: "PHONE_NUMBER",
          text: action.buttonText,
          phone_number: action.phoneNumber,
        });
      }
    });
    return payload;
  };

  const getSkPayload = () => {
    const formData = formMethods.getValues();

    const payload = {
      name: formData.title,
      mode: "WhatsApp",
      type: "SMS",
      isActive: formData.status === "active",
      businessEntity: "customer",
      partner: formData.partner,
      default: {
        headerType: formData.mediaType,
        header: "",
        body: formData.body,
        bodyText: formData.bodyDynamicSample
          ?.split(",")
          ?.map((e) => e.trim())
          ?.filter((e) => e),
        footer: formData.footer,
      },
      to: formData.to.split(",").map((e) => e.trim()),
      languageCode: formData.language,
    };

    if (formData.mediaType === "image") {
      payload.default.header =
        appConfigs.ASSET + "/" + formData.headerImage + ".jpg";
    } else if (formData.mediaType === "text") {
      payload.default.header = formData.headerText;
      payload.default.headerText = formData.headerDynamicSample
        ?.split(",")
        ?.map((e) => e.trim())
        ?.filter((e) => e);
    } else {
      payload.default.header = "";
    }

    const actionsArray = formMethods.getValues("actions") || [];

    if (actionsArray.length > 0) {
      payload.default.buttonType = "Call To Action";
      payload.default.buttons = getActionsPayload().buttons;
    }

    return payload;
  };

  const handleSubmit = async () => {
    let response;

    const payload = getSkPayload();

    setBusyloader({ show: true });

    if (query.id) {
      response = await NotificationService.updateTemplate(query.id, payload);
    } else {
      response = await NotificationService.createTemplate(payload);
    }

    if (response.statusCode === 200) {
      // if partner is interakt_whatsapp, then we need to create the template in interakt_whatsapp
      if (response?.resp?.partner == "interakt_whatsapp") {
        let createInPartner = false;

        const partnerResponse =
          await NotificationService.fetchTemplateFromPartner();

        const templates = partnerResponse.resp?.results?.templates || [];

        if (templates.length) {
          const template = templates.find(
            (t) => t.id === response?.partnerConfig?.id
          );
          if (!template) {
            createInPartner = true;
          }
        }

        if (createInPartner) {
          await NotificationService.syncPartnerTemplate(response?.resp);
        }
      }

      setBusyloader({ show: false });

      Toaster.success(
        query.id
          ? "Template updated successfully"
          : "Template created successfully"
      );
      NavService.replace(navigate, "/utility/whatsapp-template/view", {
        id: response.resp._id,
      });
    } else {
      Toaster.error("Error creating template");
      setBusyloader({ show: false });
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageInfo
        title="Manage Marketing Campaign"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />
      <FormProvider {...formMethods}>
        <AppCard>
          <div className="input-row">
            <div className="row">
              <div className="col">
                <TextInput
                  name="title"
                  label="Title"
                  isMandatory={true}
                  placeholder="Enter Title"
                  register={formMethods.register}
                  error={formMethods.formState.errors.title?.message}
                />
              </div>
              <div className="col">
                <SelectInput
                  name="language"
                  label="Language"
                  isMandatory={true}
                  options={languages}
                  register={formMethods.register}
                  error={formMethods.formState.errors.language?.message}
                />
              </div>

              <div className="col">
                <SelectInput
                  name="partner"
                  label="Partner"
                  isMandatory={true}
                  options={partners}
                  register={formMethods.register}
                  error={formMethods.formState.errors.partner?.message}
                />
              </div>
              <div className="col">
                <SelectInput
                  name="status"
                  label="Status"
                  isMandatory={true}
                  options={statusOptions}
                  register={formMethods.register}
                  error={formMethods.formState.errors.status?.message}
                />
              </div>
            </div>
          </div>
        </AppCard>

        <div className="row">
          <div className="col">
            <WhatsAppTemplateHeader />
            <WhatsAppTemplateBody />
            <WhatsAppTemplateFooter />
            <WhatsAppTemplateActions />

            <AppCard title="Recipients">
              <TextInput
                name="to"
                label="Recipients"
                isMandatory={true}
                placeholder="Enter Recipients"
                register={formMethods.register}
                error={formMethods.formState.errors.to?.message}
              />
            </AppCard>

            <div className="shadow-sm p-3 mb-5 bg-white rounded text-end position-sticky bottom-0">
              <button
                className="btn btn-primary"
                onClick={formMethods.handleSubmit(handleSubmit)}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </div>
          <div
            className="col-auto align-self-start position-sticky"
            style={previewStyle}
          >
            <WhatsAppTemplatePreviewContainer />
          </div>
        </div>
      </FormProvider>

      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default WhatsAppTemplateManage;
