import { PaginationBlock, TableHeader } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const header = [
  { label: "Sl.no", isCentered: true, width: "10%" },
  { label: "ID" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 25,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 25,
};

const getDefaultPaginationConfig = (totalRecords) => {
  return {
    ...defaultPagination,
    totalRecords,
  };
};

const PendingJobsTable = ({ pendingJobs }) => {
  const [data, setData] = useState([]);

  const paginationRef = useRef(getDefaultPaginationConfig(data.length));

  useEffect(() => {
    paginationRef.current = getDefaultPaginationConfig(pendingJobs.length);
    setData(pendingJobs.slice(0, 25));
  }, [pendingJobs]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = pendingJobs.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setData((prev) => d);
    },
    [pendingJobs]
  );

  return (
    <>
      <table className="table table-bordered table-sm px-4 mx-0">
        <TableHeader data={header} />
        <tbody>
          {data.length == 0 && (
            <tr>
              <td colSpan={header.length} className="text-center py-2">
                No data found
              </td>
            </tr>
          )}

          {data.length > 0 &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-md">
                <td className="text-center">
                  {paginationRef.current.startSlNo + i}{" "}
                </td>
                <td>{x}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </>
  );
};

export default memo(PendingJobsTable);
