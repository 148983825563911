const notifications = {
  tabs: [
    {
      tabName: "Template Config",
      key: "config",
      navTo: "/utility/notifications/list",
    },
    {
      tabName: "Event Config",
      key: "events",
      navTo: "/utility/notifications/events/list",
    },
    {
      tabName: "Notification Logs",
      key: "logs",
      navTo: "/utility/notifications/logs/list",
    },
    {
      tabName: "Promotion Config",
      key: "promotionConfig",
      navTo: "/configs/marketing/promotion",
    },
  ],
};

export default notifications;
