import { TextInput } from "@sk/uis";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const NotifTempPartnerDetailsForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const mode = useWatch({ control, name: "mode" });

  const partner = useWatch({ control, name: "partner" });

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (mode == "WhatsApp" && ["wanotifier_whatsapp"].indexOf(partner) != -1) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [mode, partner]);

  return (
    <>
      {show ? (
        <>
          <div className=" fs-val-lg  border-top fw-semibold mb-4 pt-4">
            Partner Config
          </div>
          <div className="row mb-3">
            {/* Title Name */}

            <div className="col-6">
              <TextInput
                type="text"
                label="Partner Template Code"
                placeholder="Enter Partner Template Code Here"
                register={register}
                name="partnerConfigId"
                error={errors?.partnerConfigId?.message}
                isMandatory={true}
              />
            </div>

            <div className="col-6 mb-3">
              <TextInput
                type="text"
                label="Partner Webhook Code"
                placeholder="Enter Partner Webhook code Here"
                register={register}
                name="partnerConfigWebHookCode"
                error={errors?.partnerConfigWebHookCode?.message}
                isMandatory={true}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default NotifTempPartnerDetailsForm;
