import { EmployeeService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import RolesDashMgmtManageModal from "../modals/RolesDashMgmtManageModal";
import { produce } from "immer";

const headers = [
  { label: "S.No", key: "sno", width: "5%" },
  { label: "Feature", key: "feature" },
  { label: "Access Summary", key: "accessSummary" },
  { label: "Action", key: "action", width: "5%" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const getData = async (params) => {
  const res = await EmployeeService.getRbacFeatures(params);
  return {
    data: Array.isArray(res.resp)
      ? res.resp.map((r) => ({
          ...r,
          groups: r.groups.map((g) => ({ label: g })),
        }))
      : [],
  };
};

const getCount = async (empId, params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const res = await EmployeeService.getRbacFeatures(p);
  return {
    count: Array.isArray(res.resp) && res.resp.length ? res.resp[0]?.total : 0,
  };
};

const prepareFilter = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      type: "Management",
    },
  };

  if (filter.searchText) {
    p.filter._id = { $regex: filter.searchText, $options: "i" };
  }

  if (Object.keys(p.filter).length === 0) {
    delete p.filter;
  }

  return p;
};

const RoleDashMgmt = ({ empId }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [viewModal, setViewModal] = useState({
    show: false,
    data: null,
    index: null,
  });

  const filterDataRef = useRef({});
  const paginationRef = useRef({ ...defaultPagination });

  const debouncedSearch = useCallback(
    debounce((value) => {
      filterDataRef.current = { ...filterDataRef.current, searchText: value };
      paginationRef.current.activePage = 1;
      init();
    }, 500),
    [init]
  );

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  const loadData = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    try {
      const res = await getData(
        prepareFilter(filterDataRef.current, paginationRef.current)
      );

      const features = res.data.map((f) => f._id);

      const empAccess = await EmployeeService.getEmpRbacGroup(empId, {
        page: 1,
        count: features.length,
        filter: {
          feature: { $in: features },
        },
      });

      if (Array.isArray(empAccess.resp) && empAccess.resp.length) {
        res.data.forEach((d) => {
          const f = empAccess.resp.find((g) => g.feature === d._id);
          d.permissions = f?.groups?.filter((g) => g.enabled)?.length || 0;
          d.groups = d.groups.map((g) => ({
            ...g,
            checked:
              f?.groups?.find((g2) => g2.id === g.label)?.enabled || false,
          }));
        });
      }

      console.log(res);
      setData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  }, [empId]);

  const applyFilter = useCallback(() => {
    loadData();
  }, [loadData]);

  const init = useCallback(async () => {
    applyFilter();

    setLoadingTotalRecords(true);
    try {
      const c = await getCount(
        filterDataRef.current.empId,
        prepareFilter(filterDataRef.current, paginationRef.current)
      );
      setLoadingTotalRecords(false);
      paginationRef.current.totalRecords = c.count;
    } catch (error) {
      console.error("Error fetching count:", error);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = { empId };
    init();
  }, [empId, init]);

  const paginationCb = ({ activePage, startSlNo, endSlNo }) => {
    paginationRef.current.activePage = activePage;
    paginationRef.current.startSlNo = startSlNo;
    paginationRef.current.endSlNo = endSlNo;
    loadData();
  };

  const handleManageModal = (d, index) => {
    setViewModal({ show: true, data: d, index });
  };

  const manageModalCb = ({ action, permissions, updatedGroups }) => {
    if (action === "save") {
      setData(
        produce((draft) => {
          draft[viewModal.index].permissions = permissions;
          draft[viewModal.index].groups = updatedGroups;
        })
      );
    }

    setViewModal({ show: false, data: null, index: null });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search by feature name..."
            value={searchText}
            onChange={handleSearch}
          />
        </div>
      </div>

      <table className="table table-sm table-hover">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {loadingData && (
            <TableSkeletonLoader cols={headers.length} rows={10} />
          )}

          {!loadingData && data.length === 0 && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                <NoDataFound>No data found</NoDataFound>
              </td>
            </tr>
          )}

          {data.map((d, i) => (
            <tr key={d._id}>
              <td>{paginationRef.current.startSlNo + i}</td>
              <td>{d._id}</td>
              <td>
                {d.permissions || 0} permission{d.permissions > 1 ? "s" : ""}{" "}
                granted
              </td>
              <td>
                <button
                  className="btn btn-sm btn-outline-primary fs-val-sm"
                  onClick={() => handleManageModal(d, i)}
                >
                  Manage
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-end mt-2">
        <PaginationBlock
          loadingTotalRecords={loadingTotalRecords}
          paginationCb={paginationCb}
          paginationConfig={paginationRef.current}
          size="sm"
        />
      </div>
      <RolesDashMgmtManageModal
        show={viewModal.show}
        callback={manageModalCb}
        data={viewModal.data}
        empId={empId}
      />
    </>
  );
};

export default RoleDashMgmt;
