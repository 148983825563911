import {
  TableHeader,
  BusyLoader,
  NoDataFound,
  TableSkeletonLoader,
} from "@sk/uis";
import { useState, useEffect } from "react";
import { EmployeeService } from "@sk/services";
import { Toaster } from "@sk/uis";
import { StoresModal } from "@sk/features";

const headers = [
  { label: "#", key: "index", width: "5%" },
  { label: "Store Name", key: "name", width: "25%" },
  { label: "ID", key: "id", width: "10%" },
  { label: "Town", key: "town", width: "15%" },
  { label: "District", key: "district", width: "15%" },
  { label: "State", key: "state", width: "10%" },
  { label: "Pincode", key: "pincode", width: "10%" },
  { label: "Action", key: "action", width: "5%" },
];

const RoleDashStoreAccess = ({ empId }) => {
  const [showStoresModal, setShowStoresModal] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [busyLoader, setBusyLoader] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch stores when empId changes
  useEffect(() => {
    const fetchStores = async () => {
      if (empId) {
        setLoading(true);
        try {
          const result = await EmployeeService.getEmpRbacGroup(empId, {
            filter: {
              feature: "StoreAccess",
            },
          });
          if (result.statusCode === 200) {
            const stores =
              Array.isArray(result.resp) && result.resp.length > 0
                ? result.resp[0].stores || []
                : [];
            setSelectedStores(stores);
          } else {
            Toaster.error("Failed to fetch stores");
          }
        } catch (error) {
          Toaster.error("Error fetching stores");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStores();
  }, [empId]);

  const handleStoreSelection = async (data) => {
    if (data && data.action === "selectedStore") {
      setBusyLoader({ show: true });

      const stores = data.store.map((s) => ({
        id: s._id,
        name: s.name,
        town: s.town,
        district: s.district,
        state: s.state,
        pincode: s.pincode,
        enabled: true,
      }));

      const payload = {
        feature: "StoreAccess",
        empId,
        stores: stores,
        groups: [], // Assuming groups are the same as store IDs
      };

      try {
        const result = await EmployeeService.createEmpRbac(payload);

        if (result.statusCode === 200) {
          Toaster.success("Store access updated successfully");
          setSelectedStores(stores);
        } else {
          Toaster.error("Failed to update store access");
        }
      } catch (error) {
        Toaster.error("Error updating store access");
      } finally {
        setBusyLoader({ show: false });
      }
    }
  };

  const removeStore = async (index) => {
    const updatedStores = selectedStores.filter((_, i) => i !== index);

    setBusyLoader({ show: true });

    const payload = {
      feature: "StoreAccess",
      empId,
      stores: updatedStores.map((s) => ({
        id: s.id,
        name: s.name,
        town: s.town,
        district: s.district,
        state: s.state,
        pincode: s.pincode,
        enabled: true,
      })),
      groups: [],
    };

    try {
      const result = await EmployeeService.createEmpRbac(payload);

      if (result.statusCode === 200) {
        Toaster.success("Store removed successfully");
        setSelectedStores(updatedStores);
      } else {
        Toaster.error("Failed to remove store access");
      }
    } catch (error) {
      Toaster.error("Error removing store access");
    } finally {
      setBusyLoader({ show: false });
    }
  };

  // Filtered stores based on search query
  const filteredStores = selectedStores.filter((store) =>
    store.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Search stores..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-auto ms-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowStoresModal(true)}
          >
            Add Store
          </button>
        </div>
      </div>

      <table className="table table-sm table-hover">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {!loading && filteredStores.length === 0 ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No stores found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader rows={3} cols={headers.length} />
          ) : null}

          {filteredStores.map((store, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{store.name}</td>
              <td>{store.id}</td>
              <td>{store.town}</td>
              <td>{store.district}</td>
              <td>{store.state}</td>
              <td>{store.pincode}</td>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => removeStore(index)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <StoresModal
        show={showStoresModal}
        callback={(data) => {
          handleStoreSelection(data);
          setShowStoresModal(false);
        }}
        multiSelect={true}
        selectedStoresIds={selectedStores.map((store) => store.id)}
      />

      <BusyLoader show={busyLoader.show} />
    </div>
  );
};

export default RoleDashStoreAccess;
