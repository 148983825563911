import { UtilityService } from "@sk/services";
import { SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

const defaultFormValues = {
  groups: "",
};

const typeOptions = UtilityService.getRbacTypes();
typeOptions.unshift({ label: "All", value: "" });

const Filter = ({ callback }) => {
  // React-hook-Form
  const { getValues, register } = useForm({
    defaultValues: defaultFormValues,
  });

  const onSearch = useCallback(
    debounce((e) => {
      callback({ formData: getValues() });
    }, 800),
    [getValues, callback]
  );

  const onTypeChange = useCallback(() => {
    callback({ formData: getValues() });
  }, [getValues, callback]);

  return (
    <div className="row">
      <div className="col-4">
        <TextInput
          label="Search"
          placeholder="Search by Id/Name/Features"
          type="text"
          register={register}
          name="groups"
          callback={onSearch}
        />
      </div>
      <div className="col-2">
        <SelectInput
          label="Type"
          options={typeOptions}
          register={register}
          name="type"
          callback={onTypeChange}
        />
      </div>
    </div>
  );
};

export default memo(Filter);
