import { Tabs } from "@sk/uis";
import { useEffect, useState } from "react";

const tabs = [
  {
    tabName: "Dashboard Access",
    key: "dashboard",
  },
  // {
  //   tabName: "Store Access",
  //   key: "store",
  // },
  {
    tabName: "Management Access",
    key: "management",
  },
  {
    tabName: "Audit Logs",
    key: "audit",
  },
];

const RoleDashTabs = ({ callback, activeTabKey }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabChange = (tab) => {
    setActiveTab(tab.value);
    callback(tab.value);
  };

  useEffect(() => {
    if (activeTabKey !== activeTab.key) {
      setActiveTab(tabs.find((t) => t.key === activeTabKey));
    }
  }, [activeTab.key, activeTabKey]);

  return (
    <>
      <Tabs data={tabs} activeTab={activeTab} callback={handleTabChange} />
    </>
  );
};

export default RoleDashTabs;
