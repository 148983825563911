import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, NavService, NotificationService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Tabs,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView, { filterView } from "../constantService";
import NotifLogsSummaryCard from "./components/NotifLogsSummaryCard";
import NotifLogsTable from "./components/NotifLogsTable";
import NotifLogsAdvanceFilterModal from "./modals/NotifLogsAdvanceFilterModal";
import NotifLogsViewModal from "./modals/NotifLogsViewModal";

import { MarketingDashboardNavComponent } from "@sk/features";
import notifications from "../../constantService";

const getData = async (params) => {
  const r = await NotificationService.getLogsList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await NotificationService.getLogsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.recepientNo?.length
    ? { $regex: d.recepientNo.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [
        { recepientNo: searchKey },
        { eventId: searchKey },
        { templateId: searchKey },
      ],
    };
  }

  if (d?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d?.status) {
    p.filter.status = d.status;
  }

  if (d?.type) {
    p.filter.type = d.type;
  }

  if (d?.entity) {
    p.filter.entity = d.entity;
  }

  if (d?.franchise?.length) {
    p.filter.franchiseId = d.franchise[0]?.value?._id || "";
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const NotifLogsList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Custom HOOK For To attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [summaryList, setSummaryList] = useState([...listView.summaryData]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showLogsViewModal, setShowLogsViewModal] = useState({
    status: false,
    data: {},
  });

  const filterDataRef = useRef({ ...listView.filterFromData });

  const paginationRef = useRef({ ...listView.pagination });

  const sortRef = useRef({ ...listView.defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...listView.pagination };

    prepareFilterLabels();
    loadList();
    loadCount();
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadCount, loadList, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Summary count
  const loadCount = useCallback(async () => {
    const p = getFilterParams();
    delete p.count;
    delete p.page;

    const r = await NotificationService.getLogsCountByStatus(p);
    let d = r?.resp ? r?.resp : {};

    setSummaryList(
      produce((draft) => {
        draft.forEach((e, k) => {
          if (e.key == "total") {
            e.value = d.totalCOUNT || 0;
          } else if (e.key == "success") {
            e.value = d.successTOTAL || 0;
          } else if (e.key == "error") {
            e.value = d.errorTOTAL || 0;
          }
          e.loading = false;
        });
      })
    );
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.recepientNo;
    if (v.entity) {
      let s = filterView.businessEntityOptions.find(
        (k) => v.entity === k.value
      );
      v.entity = s.label;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        recepientNo: getValues("recepientNo"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const viewCb = (data) => {
    setShowLogsViewModal({ status: true, data: data });
  };

  const closeViewModal = () => {
    setShowLogsViewModal({ status: false, data: {} });
  };

  const tabCb = (d) => {
    NavService.to(navigate, d.value.navTo, { tab: d.value.key });
  };

  return (
    <>
      <PageInfo
        title="Notification Logs"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      <MarketingDashboardNavComponent
        activeTab="notification"
        router={navigate}
      />

      <div className="py-3 bg-white">
        <Tabs
          data={notifications.tabs}
          callback={tabCb}
          activeTab={{ key: "logs" }}
          template="2"
        />
      </div>
      <div className="bg-white p-3 pt-1">
        <NotifLogsSummaryCard summaryList={summaryList} />

        {/* Filter Block */}
        <div className="row align-items-center">
          <div className="col-6">
            <div className="row">
              {/* Name/ID Search Input */}
              <div className="col-8 align-self-center">
                <TextInput
                  name="recepientNo"
                  type="text"
                  register={register}
                  callback={onSearch}
                  placeholder="Search By  Recipient No / ID"
                />
              </div>

              {/* Filter Button */}
              <div className="col-auto">
                <button
                  className="btn app-filter-btn"
                  type="button"
                  onClick={openAdvFilterModal}
                >
                  <i className="bi bi-funnel"></i> FILTER
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Filter selected Label */}
        <div className="my-3">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>

        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
      </div>

      {/* Table Component */}
      <NotifLogsTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
      />

      {/* Advance Filter Modal  */}
      <NotifLogsAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      <NotifLogsViewModal
        show={showLogsViewModal.status}
        callback={closeViewModal}
        id={showLogsViewModal.data.templateId}
        logData={showLogsViewModal.data.current}
      />
    </>
  );
};

export default NotifLogsList;
