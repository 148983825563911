import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Logs",
    },
  ],
  // Breadcrumbs Ends Here

  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,contact_details",
      }),
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    type: {
      label: "Type",
    },
    entity: {
      label: "Business Entity",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
  },

  // Form Labels Ends Here

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Recepient No" },
    { label: "Type" },
    {
      label: "Business Entity",
      isCentered: true,
    },
    { label: "Employee Id" },
    { label: "Franchise" },
    { label: "Template Id", key: "templateId", enableSort: true },
    { label: "Event Id" },
    { label: "Created At", priority: 8, key: "createdAt", enableSort: true },
    { label: "Status", priority: 10, isCentered: true },
    { label: "Actions", width: "7%", priority: 11, isCentered: true },
  ],

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      color: "primary",
      img: totalImg,
      key: "total",
    },
    {
      label: "Success Count",
      value: 0,
      loading: true,
      color: "success",
      img: activeImg,
      key: "success",
    },
    {
      label: "Error Count",
      value: 0,
      loading: true,
      img: inactiveImg,
      key: "error",
    },
  ],
};

export const filterView = {
  typeOptions: [
    { label: "All", value: "" },
    { label: "PUSH", value: "PUSH" },
    { label: "SMS", value: "SMS" },
  ],

  statusOptions: [
    { label: "All", value: "" },
    { label: "Error", value: "error" },
    { label: "Success", value: "success" },
  ],

  businessEntityOptions: [
    { label: "All", value: "" },
    { label: "Account", value: "account" },
    { label: "Aeps", value: "aeps" },
    { label: "Asset", value: "asset" },
    { label: "Banner", value: "banner" },
    { label: "Brand", value: "brand" },
    { label: "Category", value: "category" },
    { label: "Campaign", value: "campaign" },
    { label: "Commission", value: "commission" },
    { label: "Config", value: "config" },
    { label: "Coupons", value: "coupons" },
    { label: "Courier", value: "courier" },
    { label: "Customer", value: "customer" },
    { label: "Deal", value: "deal" },
    { label: "Digital Partner", value: "digitalPartner" },
    { label: "Dmt", value: "dmt" },
    { label: "Employee", value: "employee" },
    { label: "Franchise", value: "franchise" },
    { label: "Gateway", value: "gateway" },
    { label: "Grn", value: "grn" },
    { label: "Insurance", value: "insurance" },
    { label: "Investment", value: "investment" },
    { label: "King Alerts", value: "kingalerts" },
    { label: "King Sale", value: "kingsale" },
    { label: "Kyf", value: "kyf" },
    { label: "Lending", value: "lending" },
    { label: "Logistics", value: "logistics" },
    { label: "Medical Service", value: "Medical Service" },
    { label: "Oms", value: "oms" },
    { label: "Payment Requests", value: "paymentrequests" },
    { label: "Pg", value: "pg" },
    { label: "Po", value: "po" },
    { label: "Product", value: "product" },
    { label: "Recharge", value: "recharge" },
    { label: "Reports", value: "reports" },
    { label: "Returns", value: "returns" },
    { label: "Scheduler", value: "scheduler" },
    { label: "Seller", value: "seller" },
    { label: "Sourcing Partner", value: "sourcingPartner" },
    { label: "Ticket", value: "ticket" },
    { label: "User", value: "user" },
    { label: "Vendor", value: "vendor" },
    { label: "Wallet", value: "wallet" },
    { label: "Wh", value: "wh" },
    { label: "Widgets", value: "widgets" },
    { label: "Wypbid Request", value: "wypbidrequest" },
  ],

  filterFormData: {
    status: "",
    type: "",
    entity: "",
    franchise: [],
    createdAt: [],
  },
};

export default listView;
