import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, RolesService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  Rbac,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterForm from "./components/FilterForm";
import Table from "./components/Table";
import ManageRolesModal from "./modals/ManageRolesModal";
import ViewRolesModal from "./modals/ViewRolesModal";

const rbac = {
  addRole: ["AddRole"],
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Roles List",
  },
];

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await RolesService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const defaultPaginationData = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const formLabelsConfig = {
  roleType: {
    label: "Role Type",
  },
  roles: {
    label: "Role",
    // valuePath: "[0].value.name",
  },
};

const getData = async (params) => {
  const r = await RolesService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultSortOpt = { key: "lastUpdated", value: "asc" };

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };
  let d = filter;

  if (d?.roles && d?.roles.trim()) {
    let roleRegex = { $regex: d.roles.trim(), $options: "i" };
    p["filter"]["$or"] = [{ name: roleRegex }, { _id: roleRegex }];
  }

  if (d.roleType) {
    p["filter"]["roleType"] = d.roleType;
  }

  if (sort.key) {
    p.sort = {
      [sort.key]: sort.value == "desc" ? -1 : 1,
    };
  }
  return p;
};

const ListRoles = () => {
  // For Category List data
  const [data, setData] = useState([]);

  // For Category List Data Loader
  const [loadingData, setLoadingData] = useState(true);

  // For Pagination Count Loader
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  //add and Edit Modal
  const [showModal, setShowModal] = useState(false);

  // View Modal
  const [showViewModal, setShowViewModal] = useState(false);

  const filterDataRef = useRef({});

  const paginationRef = useRef({ ...defaultPaginationData });

  //  Modal Data setting Mode and Id
  const modalDataRef = useRef({ mode: "add", id: "" });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  // Init
  const init = useCallback(async () => {
    applyFilter({ ...defaultSortOpt });
  }, [applyFilter]);

  // Apply Filter
  const applyFilter = useCallback(async () => {
    // List data call
    loadList();

    // Filter label
    // prepareFilterLabels();

    paginationRef.current = { ...defaultPaginationData };

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList, prepareFilterLabels]);

  // Fetching List Data
  const loadList = useCallback(
    async (sort) => {
      setLoadingData(true);
      const p = getFilterParams(sort);
      const r = await getData(p);
      const d = await loadParentRoleDetails(r);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
      setData(d);
      setLoadingData(false);
    },
    [attachAllData, getFilterParams, loadParentRoleDetails, setAdditionalData]
  );

  // Fetching Parent Role Details
  const loadParentRoleDetails = useCallback(async (d) => {
    let ids = d.map((e) => {
      return e?.parentRoleId || "";
    });

    const r = await getData({ filter: { _id: { $in: ids } } });
    if (r.length) {
      let x = d.map((k) => {
        return {
          ...k,
          _parent: (r || []).find((e) => e._id == k.parentRoleId),
        };
      });
      return x;
    } else {
      return d;
    }
  }, []);

  // Preparing Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // Filter Form Cb
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      init();
    },
    [init]
  );

  // Filter Label
  const prepareFilterLabels = useCallback(() => {
    const v = filterDataRef.current || {};
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  //  edit Role - form  modal
  const editCb = useCallback((d) => {
    modalDataRef.current = { mode: "edit", id: d._id };
    setShowModal(true);
  }, []);

  // Add Role - form modal
  const addRole = useCallback(() => {
    modalDataRef.current = { mode: "add", id: "" };
    setShowModal(true);
  }, []);

  // close call back -form modal
  const closeFormModal = useCallback(
    (d) => {
      modalDataRef.current = { mode: "add", id: "" };
      setShowModal(false);
      if (d?.status && d?.status == "submit") {
        init();
      }
    },
    [init]
  );

  // View Modal call back
  const viewCb = useCallback((d) => {
    modalDataRef.current.id = d._id;
    setShowViewModal(true);
  }, []);

  // View Modal close Call back
  const closeViewModal = useCallback(() => {
    modalDataRef.current.id = "";
    setShowViewModal(false);
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter({ ...data });
    },
    [applyFilter]
  );

  // To Delete Access Role
  const deleteAccessRole = useCallback(
    async (d) => {
      // asking confirm to delete
      let res = await Alert.confirm({
        title: "Please confirm",
        text: "Are you sure you want to delete the Role " + d?._id + " ?",
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      setLoadingData(true);
      const r = await RolesService.deleteAccessRole(d?._id);
      setLoadingData(false);

      if (r.statusCode == 200) {
        Toaster.success("Access Group Deleted Successfully");
        init();
      } else {
        Toaster.error("Oops, Something Went Wrong, Please Try Later");
      }
    },
    [init]
  );

  return (
    <>
      {/* Page title Info */}
      <div className="pt-2">
        <PageInfo
          title="Manage Roles"
          breadcrumbs={breadcrumbs}
          navigate={router}
        />
      </div>

      <AppCard>
        <div className="row align-items-end">
          <div className="col">
            <FilterForm callback={filterFormCb} />
          </div>
          <div className="col-auto">
            <Rbac roles={rbac.addRole}>
              <button className="btn btn-primary" onClick={addRole}>
                <span className="bi bi-plus-square me-1"></span>
                Add Role
              </button>
            </Rbac>
          </div>
        </div>
      </AppCard>

      <AppCard>
        {/* Filter selected Label */}
        {filterLabels.length > 0 ? (
          <div className="my-3">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}

        {/* Table */}
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editCb={editCb}
          viewCb={viewCb}
          sort={sortRef.current}
          sortCb={sortCb}
          deleteCb={deleteAccessRole}
        />
      </AppCard>

      {/* <BusyLoader show={loadingData} /> */}

      {/* Form Modal */}
      <ManageRolesModal
        show={showModal}
        closeModal={closeFormModal}
        mode={modalDataRef.current.mode}
        roleId={modalDataRef.current.id}
      />

      {/* View Modal */}
      <ViewRolesModal
        show={showViewModal}
        closeModal={closeViewModal}
        roleId={modalDataRef.current.id}
      />
    </>
  );
};

export default memo(ListRoles);
