import { SelectInput } from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { filterView } from "../../constantService";

const canvasStyle = { width: "30%" };

const defaultFormData = filterView.filterFormData;

const NotifEventsAdvanceFilterModal = ({ show, callback }) => {
  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset({ ...defaultFormData });
    callback({ formData: { ...defaultFormData }, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              {/* Status */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Status"
                  register={register}
                  name="isActive"
                  isMandatory={false}
                  options={filterView.statusOptions}
                />
              </div>

              {/* Type  options */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Mode"
                  register={register}
                  name="mode"
                  isMandatory={false}
                  options={filterView.modeOptions}
                />
              </div>

              {/* Business Entity */}
              <div className="col-12 mb-3">
                <SelectInput
                  label="Business Entity"
                  register={register}
                  name="businessEntity"
                  isMandatory={false}
                  options={filterView.businessEntityOptions}
                />
              </div>
            </div>

            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(NotifEventsAdvanceFilterModal);
