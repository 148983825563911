import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Popover, OverlayTrigger, Spinner } from "react-bootstrap";
import { ProjectService } from "@sk/services";
import { DateFormatter } from "@sk/uis";

const defaultPagination = { page: 1, count: 3 };

const containerStyle = { maxHeight: "400px", overflowY: "auto" };

const getData = async (params) => {
  const r = await ProjectService.getAllComments(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const prepareFilterParams = (pagination) => {
  return {
    page: pagination.page,
    count: pagination.count,
    sort: { createdAt: -1 },
  };
};

const ProjectTaskNotifications = ({ taskId }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const paginationRef = useRef({ ...defaultPagination });

  const fetchComments = useCallback(async () => {
    setLoading(true);
    try {
      const r = await getData(prepareFilterParams(paginationRef.current));
      setComments(r.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
    setLoading(false);
  }, []);

  const loadMore = async () => {
    paginationRef.current.page++;
    setLoadMoreLoading(true);
    try {
      const r = await getData(prepareFilterParams(paginationRef.current));
      setComments((prevComments) => [...prevComments, ...r.data]);
      setShowAll(true);
    } catch (error) {
      console.error("Error loading more comments:", error);
    }
    setLoadMoreLoading(false);
  };

  useEffect(() => {
    fetchComments();
  }, [fetchComments, taskId]);

  const renderComments = () => (
    <Popover id="popover-basic">
      <Popover.Header
        as="h3"
        className="d-flex justify-content-between align-items-center"
      >
        <span>Comments</span>
        <span className="badge bg-secondary">{comments.length}</span>
      </Popover.Header>
      <Popover.Body className="p-0">
        {loading ? (
          <div className="text-center py-4">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <div className="comment-list custom-scrollbar" style={containerStyle}>
            {comments.length === 0 ? (
              <div className="text-center py-4">
                <i className="bi bi-chat-left-dots fs-4 text-muted mb-2"></i>
                <p className="text-muted mb-0">No comments available.</p>
              </div>
            ) : (
              <>
                {comments.map((comment) => (
                  <div key={comment._id} className="p-3 border-bottom">
                    <div className="d-flex justify-content-between mb-1">
                      <strong className="text-primary fs-val-sm">
                        {comment.createdBy || "Unknown User"}
                      </strong>
                      <small className="text-muted fs-val-sm">
                        <DateFormatter date={comment.createdAt} />
                      </small>
                    </div>
                    <p className="mb-0 fs-val-sm">{comment.text}</p>
                  </div>
                ))}
                {!showAll && comments.length >= defaultPagination.count && (
                  <div className="p-2 text-center">
                    <Button
                      variant="link"
                      size="sm"
                      onClick={loadMore}
                      disabled={loadMoreLoading}
                      className="text-decoration-none"
                    >
                      {loadMoreLoading ? (
                        <span>
                          <Spinner
                            animation="border"
                            size="sm"
                            className="me-2"
                          />
                          Loading...
                        </span>
                      ) : (
                        <span>Load More</span>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        placement="auto"
        overlay={renderComments()}
        rootClose
      >
        <button className="btn btn-outline-primary btn-sm">
          <i className="bi bi-chat-dots me-1"></i>
          Comments ({comments.length})
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default ProjectTaskNotifications;
