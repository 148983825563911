import {
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";
import listView from "../../constantService";

const rbac = {
  editButton: ["EditEvent"],
  viewButton: ["ViewEvent"],
};

const NotifEventsTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  editCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Notification Events Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* ID */}
                <td>{x?._id}</td>

                {/* Event Code */}
                <td>{x.name}</td>

                {/* Bussiness Entity */}
                <td className="text-center">{x.businessEntity}</td>

                {/* No of Rules */}
                <td className="text-center">{x.rules.length}</td>

                {/* Status */}
                <td className="text-center">
                  {
                    <HighlightText
                      status={x.isActive ? "Active" : "Inactive"}
                      isBadge={true}
                    />
                  }
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewCb(x);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => {
                        editCb(x);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(NotifEventsTable);
