import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, FranchiseService, NavService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import NotifCallLogsTable from "./components/NotifCallLogsTable";
import NotifSummaryCard from "./components/NotifSummaryCard";
import NotifCallLogsAdvanceFilterModal from "./modals/NotifCallLogsAdvanceFilterModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSort = { key: "createdAt", value: "desc" };

const getData = async (params) => {
  const r = await FranchiseService.getCallLogs(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await FranchiseService.getCallLogsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [{ "franchise.id": searchKey }, { "franchise.name": searchKey }],
    };
  }

  if (d?.employee?.length) {
    p.filter = { "employee.id": d.employee?.[0]?.value._id };
  }

  if (d?.status?.length) {
    p.filter.status = d.status;
  }

  if (d?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d?.reason) {
    p.filter.reason = d.reason;
  }

  if (sort?.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const NotifCallLogsList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [filterLabels, setFilterLabels] = useState([]);

  const [summaryCard, setSummaryCard] = useState([]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const filterDataRef = useRef({ ...defaultFilterFormData });

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef(defaultSort);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    prepareFilterLabels();
    loadList();
    loadSummaryCard();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummaryCard, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    d.forEach((e) => {
      e._status = getStatus(e.status);
    });
    setData(d);
    setLoadingData(false);
  }, []);

  const loadSummaryCard = useCallback(() => {
    let k = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter) {
        delete p.filter?.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(k);
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    v.status = getStatus(v.status);
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const getStatus = (status) => {
    let s = "";
    if (status == "in-progress") {
      s = "In progress";
    } else if (status == "no-answer") {
      s = "No Answer";
    } else if (status == "completed") {
      s = "Completed";
    } else if (status == "failed") {
      s = "Failed";
    } else {
      s = status;
    }
    return s;
  };

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  return (
    <>
      <PageInfo
        title="Notification Call Logs "
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-2" key={i}>
            <NotifSummaryCard
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/* Name/ID Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By Franchise Name /ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table Component */}
      <NotifCallLogsTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
      />

      {/* Advance Filter Modal  */}
      <NotifCallLogsAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />
    </>
  );
};

export default NotifCallLogsList;
