import { MarketingDashboardNavComponent } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, NavService, NotificationService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Rbac,
  Tabs,
  TextInput,
  Toaster,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import notifications from "../../constantService";
import listView, { filterView } from "../constantService";
import NotifSummaryCard from "./components/NotifSummaryCard";
import NotifTempTable from "./components/NotifTempTable";
import NotifTempAdvanceFilterModal from "./modals/NotifTempAdvanceFilterModal";
import NotifTempViewModal from "./modals/NotifTempViewModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSort = listView.defaultSortOpt;

const getData = async (params) => {
  const r = await NotificationService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await NotificationService.getListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const rbac = {
  addButton: ["AddNotificationTemplate"],
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      mode: {
        $nin: "WhatsApp",
      },
    },
  };

  let d = filter || {};
  let searchKey = d.name.length ? { $regex: d.name.trim(), $options: "i" } : "";

  if (searchKey) {
    p.filter = { $or: [{ name: searchKey }, { _id: searchKey }] };
  }

  if (d?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d?.status) {
    p.filter.isActive = d.status == "Active";
  }

  if (d?.mode) {
    p.filter.mode = d.mode;
  }

  if (d?.businessEntity) {
    p.filter.businessEntity = d.businessEntity;
  }

  if (d?.language) {
    p.filter.languages = d.language;
  }

  if (sort?.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const NotifTempList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Custom HOOK For To attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showViewModal, setShowViewModal] = useState({
    status: false,
    data: {},
  });

  const [summaryCard, setSummaryCard] = useState([]);

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef(defaultSort);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    prepareFilterLabels();
    loadSummaryCard();
    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummaryCard, prepareFilterLabels]);

  const loadSummaryCard = useCallback(() => {
    let d = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      // if (p.filter) {
      //   delete p.filter?.isActive;
      // }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(d);
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    if (v.businessEntity) {
      let s = filterView.businessEntityOptions.find(
        (k) => v.businessEntity === k.value
      );
      v.businessEntity = s.label;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(() => {
    setShowAdvFilterModal(false);
  }, []);

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const addTemplate = () => {
    NavService.openInNewTab("/utility/notifications/manage");
  };

  const viewCb = useCallback((d) => {
    setShowViewModal({ status: true, data: d });
  }, []);

  const closeViewModal = useCallback(() => {
    setShowViewModal({ status: false, data: {} });
  }, []);

  const viewModalCb = useCallback(
    async (d) => {
      if (d.status == "updateStatus") {
        setLoadingData(true);
        let params = {
          isActive: !d.data.isActive,
        };
        const r = await NotificationService.updateTemplate(d.data._id, params);
        setLoadingData(false);

        if (r.statusCode == 200) {
          Toaster.success("Access Group Deleted Successfully");
          init();
        } else {
          Toaster.error("Oops, Something Went Wrong, Please Try Later");
        }
      }
    },
    [init]
  );

  const tabCb = (d) => {
    NavService.to(navigate, d.value.navTo, { tab: d.value.key });
  };

  return (
    <>
      <PageInfo
        title="Notification Templates"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      <div className="my-4">
        <Tabs
          data={notifications.tabs}
          callback={tabCb}
          activeTab={{ key: "config" }}
          template={3}
        />
      </div>
      <div>
        {/* Summary Card Overview */}
        <div className="row mb-2">
          {summaryCard.map((card, i) => (
            <div className="col-2" key={i}>
              <NotifSummaryCard
                label={card.label}
                color={card.color}
                filterParams={card.filterParams}
                img={card.img}
              />
            </div>
          ))}
        </div>

        {/* Filter  Block  */}
        <div className="mb-4">
          <AppCard>
            <div className="row align-items-center">
              <div className="col-6">
                <div className="row">
                  {/* Name/ID Search Input */}
                  <div className="col-8 align-self-center">
                    <TextInput
                      name="name"
                      type="text"
                      register={register}
                      callback={onSearch}
                      placeholder="Search By  Name /ID"
                      gap={0}
                    />
                  </div>

                  {/* Filter Button */}
                  <div className="col-auto">
                    <button
                      className="btn app-filter-btn"
                      type="button"
                      onClick={openAdvFilterModal}
                    >
                      <i className="bi bi-funnel"></i> FILTER
                    </button>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="col-auto ms-auto align-self-center">
                <Rbac roles={rbac.addButton}>
                  <button
                    className="btn btn-primary btn-sm fs-val-md me-1"
                    onClick={addTemplate}
                  >
                    +Add Template
                  </button>
                </Rbac>
              </div>
            </div>
          </AppCard>
        </div>

        <AppCard>
          {/* Filter selected Label */}
          <div className="mb-3">
            {filterLabels.length > 0 ? (
              <AppliedFilterLabel labels={filterLabels} />
            ) : null}
          </div>

          {/* PAGINATION SUMMARY */}
          <div className="mb-3">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={loadingTotalRecords}
            />
          </div>

          {/* Table Component */}
          <NotifTempTable
            data={data}
            loading={loadingData}
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            loadingTotalRecords={loadingTotalRecords}
            sortCb={sortCb}
            sort={sortRef.current}
            viewCb={viewCb}
            callback={viewModalCb}
          />
        </AppCard>
      </div>

      {/* Advance Filter Modal  */}
      <NotifTempAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* View Modal */}
      <NotifTempViewModal
        callback={closeViewModal}
        show={showViewModal.status}
        id={showViewModal.data}
      />
    </>
  );
};

export default NotifTempList;
