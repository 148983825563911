import { memo, useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";

const style = {
  modalBodyStyle: {
    maxHeight: "80vh",
    overflow: "scroll",
  },
};
function ViewRecordModal({ show, callback, details = {} }) {
  const onHideCb = useCallback(() => {
    callback({ action: "close" });
  }, [callback]);

  const recordDetails = useMemo(
    () => JSON?.stringify(details.record, null, 10),
    [details]
  );

  if (details._id) {
  }

  return (
    <Modal show={show} backdrop="static" onHide={onHideCb} size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">
          Records Details of #{details._id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={style.modalBodyStyle}>
        <div className="p-4">
          <pre className="fs-val-md">{recordDetails}</pre>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(ViewRecordModal);
