import { AppCard, DateFormatter, TableHeader } from "@sk/uis";
import React from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "45%" };

const storeColumns = [
  { label: "#", key: "sno", width: "5%" },
  { label: "Store ID", key: "_id", width: "15%" },
  { label: "Store Name", key: "name", width: "50%" },
  { label: "Date", key: "date", width: "25%" },
];

const groupColumns = [
  { label: "#", key: "sno", width: "5%" },
  { label: "Access Name", key: "name", width: "50%" },
  { label: "Date", key: "date", width: "25%" },
];

const RolesDashAuditViewModal = ({ show, onHide, data }) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Audit Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <AppCard title="Summary">
          <div className="fs-val-md">{data?.remarks}</div>
        </AppCard>

        {data?.changes && (
          <>
            {data.changes.addedStores?.length > 0 && (
              <AppCard title="Added Stores" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={storeColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.addedStores.map((store, i) => (
                        <tr key={store._id}>
                          <td>{i + 1}</td>
                          <td>{store.id}</td>
                          <td>{store.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}

            {data.changes.updatedStores?.length > 0 && (
              <AppCard title="Updated Stores" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={storeColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.updatedStores.map((store, i) => (
                        <tr key={store._id}>
                          <td>{i + 1}</td>
                          <td>{store.id}</td>
                          <td>{store.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}

            {data.changes.removedStores?.length > 0 && (
              <AppCard title="Removed Stores" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={storeColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.removedStores.map((store, i) => (
                        <tr key={store._id}>
                          <td>{i + 1}</td>
                          <td>{store.id}</td>
                          <td>{store.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}

            {data.changes.addedGroups?.length > 0 && (
              <AppCard title="Added Access" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={groupColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.addedGroups.map((group, i) => (
                        <tr key={group._id}>
                          <td>{i + 1}</td>
                          <td>{group.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}

            {data.changes.updatedGroups?.length > 0 && (
              <AppCard title="Updated Access" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={groupColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.updatedGroups.map((group, i) => (
                        <tr key={group._id}>
                          <td>{i + 1}</td>
                          <td>{group.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}

            {data.changes.removedGroups?.length > 0 && (
              <AppCard title="Removed Access" className="mb-4">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <TableHeader data={groupColumns} noBg />
                    <tbody className="fs-val-md">
                      {data.changes.removedGroups.map((group, i) => (
                        <tr key={group._id}>
                          <td>{i + 1}</td>
                          <td>{group.name}</td>
                          <td>
                            <DateFormatter date={data.createdAt} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            )}
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RolesDashAuditViewModal;
