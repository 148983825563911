import { ProjectService } from "@sk/services";
import { EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ProjectTaskFilterModal from "../modals/ProjectTaskFilterModal";

const defaultFormData = {
  search: "",
  status: "",
  priority: "",
  employee: [],
  taskMode: "",
};

const employeeFilterParams = {
  filter: {
    "assignment_details.dep": "Technology",
    status: "Active",
  },
};

const taskDateOptions = [
  { label: "All", value: "" },
  { label: "Today's Tasks", value: "today" },
  { label: "Yesterday's Tasks", value: "yesterday" },
  { label: "Delayed Tasks", value: "delayed" },
  { label: "Tomorrow's Tasks", value: "tomorrow" },
];

const empTemplate = (employee) => {
  return (
    <div>
      <div className="fs-val-md">{employee.label}</div>
      <div className="fs-val-xs text-muted">
        {employee.value?.assignment_details?.designation}
      </div>
    </div>
  );
};

const statusOptions = ProjectService.getStatuses();
statusOptions.unshift({ label: "All Status", value: "" });

const priorityOptions = ProjectService.getPriorities();
priorityOptions.unshift({ label: "All Priority", value: "" });

const taskTypeOptions = ProjectService.getTaskTypes();
taskTypeOptions.unshift({ label: "All Task Type", value: "" });

const ProjectTaskListFilter = ({
  callback,
  hideEmpSearch = false,
  statuses,
}) => {
  const { register, getValues, setValue, control } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    if (statuses?.length) {
      setValue("status", "");
    }
  }, [setValue, statuses]);

  const handleFilterChange = () => {
    callback({ formData: getValues() });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      handleFilterChange();
    }, 800), // Adjust the debounce delay as needed
    [] // Dependencies can be added here if needed
  );

  const onEmployeeChange = (chngFn) => (value) => {
    chngFn(value);
    handleFilterChange();
  };

  const download = () => {
    callback({ action: "download" });
  };

  const onFilterModalClose = ({ action, data }) => {
    if (action === "apply" || action === "reset") {
      callback({ formData: { ...getValues(), ...data } });
    }
    setShowFilterModal(false);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <TextInput
            placeholder="Search Task Name"
            name="search"
            callback={onSearchChange}
            register={register}
            size="sm"
            gap={0}
          />
        </div>
        {!hideEmpSearch && (
          <div className="col-3">
            <Controller
              control={control}
              name="employee"
              render={({ field }) => (
                <EntitySearchInput
                  type="employee"
                  callback={onEmployeeChange(field.onChange)}
                  value={field.value}
                  placeholder="Search Employee"
                  filterParams={employeeFilterParams}
                  size="sm"
                  gap={0}
                  uid={"task-employee"}
                  template={empTemplate}
                />
              )}
            />
          </div>
        )}
        <div className="col-auto">
          <SelectInput
            name="status"
            callback={handleFilterChange}
            options={statusOptions}
            register={register}
            size="sm"
            gap={0}
          />
        </div>
        <div className="col-auto">
          <SelectInput
            name="priority"
            callback={handleFilterChange}
            options={priorityOptions}
            register={register}
            size="sm"
            gap={0}
          />
        </div>
        <div className="col-auto">
          <SelectInput
            name="taskType"
            callback={handleFilterChange}
            options={taskTypeOptions} // Using the new options
            register={register}
            size="sm"
            gap={0}
          />
        </div>
        <div className="col-auto">
          <SelectInput
            name="taskMode"
            callback={handleFilterChange}
            register={register}
            size="sm"
            gap={0}
            options={taskDateOptions}
          />
        </div>
        <div className="col-auto">
          <button
            type="submit"
            className="btn btn-sm btn-outline-light"
            onClick={() => setShowFilterModal(true)}
          >
            <i className="bi bi-funnel"></i>
          </button>
        </div>
        <div className="col-auto">
          <button className="btn btn-sm btn-outline-light" onClick={download}>
            <i className="bi bi-download"></i>
          </button>
        </div>
      </div>
      <ProjectTaskFilterModal
        show={showFilterModal}
        callback={onFilterModalClose}
      />
    </>
  );
};

export default ProjectTaskListFilter;
