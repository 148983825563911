import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, BatchService, CommonService } from "@sk/services";
import {
  AppliedFilterLabel,
  DatePickerInput,
  PageInfo,
  PaginationSummary,
  Rbac,
  SummaryCard,
} from "@sk/uis";
import { set as dtSet } from "date-fns";
import { produce } from "immer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Table from "./components/Table";
import listView from "./constantService";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import CreateFormModal from "./modals/CreateFormModal";
import ViewDetailsModal from "./modals/ViewDetailsModal";
const rbac = {
  upload: ["BulkUploadExcel"],
};

// To Get The Brand List Data
const getData = async (params) => {
  const r = await BatchService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const isSuperAdmin = AuthService.isSuperAdmin();

const dateRangeConfig = {
  maxDate: new Date(),
  dateFormat: "d M Y ",
  enableTime: false,
  mode: "range",
  defaultDate: [
    dtSet(new Date(), { hours: 0, minutes: 0 }),
    dtSet(new Date(), { hours: 23, minutes: 59 }),
  ],
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      createdAt: {
        $gte: dtSet(new Date(), { hours: 0, minutes: 0 }),
        $lte: dtSet(new Date(), { hours: 23, minutes: 59 }),
      },
    },
    sort: `"-createdAt"`,
  };

  let d = filter || {};
  // Brand FILTER - BASED ON ID AND NAME
  if (d.status && d.status != "All") {
    p["filter"]["status"] = d.status;
  }

  if (d.dateRange.length) {
    p["filter"]["createdAt"] = {
      $gte: dtSet(d.dateRange[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: dtSet(d.dateRange[1], { hours: 23, minutes: 59, seconds: 59 }),
    };
  }

  if (d.templateCode.length) {
    p["filter"]["templateCode"] = d.templateCode[0]["value"];
  }

  if (!isSuperAdmin) {
    p["filter"]["createdBy"] = AuthService.getLoggedInEmp()["userId"];
  }

  if (d.createdBy.length) {
    p["filter"]["createdBy"] = d.createdBy[0]["value"];
  }

  return p;
};

const BulkUploadList = () => {
  // React Hook From For Handling Filter Form Data
  const { control, setValue } = useForm({
    defaultValues: listView.filterFromData,
  });

  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // loading State for total count
  const [loadingSummary, setLoadingSummary] = useState(true);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  // To Show Details  Modal
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  // To Create Form   Modal
  const [showFormModal, setShowFormModal] = useState(false);

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // Navigation Object For Routing
  const router = useNavigate();

  // Filter data Label displaying
  const [filterLabels, setFilterLabels] = useState([]);

  // To Store Filter Data
  const filterDataRef = useRef({ ...listView.filterFromData });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  // Selected Modal Data
  const formModalDataRef = useRef("");

  useEffect(() => {
    init();
  }, [init]);

  // Init
  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...listView.pagination };

    loadList();

    loadSummary();

    prepareFilterLabels();
  }, [loadList, loadSummary, prepareFilterLabels]);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterDataRef.current };

    if (!v.dateRange.length) {
      delete v.dateRange;
    }
    if (!v.templateCode.length) {
      delete v.templateCode;
    }
    if (v.status) {
      let s = listView.statusOptions.find((k) => v.status === k.value);
      v.status = s.label;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // summary card count
  const loadSummary = useCallback(async () => {
    setLoadingSummary(true);

    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = false;
        });
      })
    );

    const p = getFilterParams();
    let r = await BatchService.getCount(p);

    paginationRef.current.totalRecords = r.resp.TOTAL || 0;
    setLoadingSummary(false);

    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = false;
          x.value = r?.resp[x.key] || 0;
        });
      })
    );
  }, []);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Preparing Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(paginationRef.current, filterDataRef.current);
  };

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  const viewDetailsModal = useCallback((d) => {
    formModalDataRef.current = d;
    setShowDetailsModal(true);
  }, []);

  const closeViewModal = useCallback(() => {
    formModalDataRef.current = "";
    setShowDetailsModal(false);
  }, []);

  const viewFormModal = useCallback(() => {
    setShowFormModal(true);
  }, []);

  const closeFormModal = useCallback(
    (d) => {
      if (d?.status == "submit") {
        init();
      }
      setShowFormModal(false);
    },
    [init]
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (data.status == "applied" || data.status == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    if (data.status == "reset") {
      setValue("createdDateRange", dateRangeConfig.defaultDate);
    }
    closeAdvFilterModal(false);
  };

  const onDateSelect = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      filterDataRef.current = {
        ...filterDataRef.current,
        dateRange: val,
      };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <PageInfo
        title="Bulk Upload"
        breadcrumbs={listView.breadcrumb}
        navigate={router}
      />

      {/* Summary card */}
      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center mb-3">
        <div className="col-6">
          <div className="row">
            {/* Brand Search Input */}
            <div className="col-8 align-self-center">
              <label className="mb-1">Choose Created On</label>
              <Controller
                control={control}
                name="createdDateRange"
                render={({ field: { onChange, value } }) => (
                  <DatePickerInput
                    inpChange={onDateSelect(onChange)} // send value to hook form
                    value={value}
                    config={dateRangeConfig}
                  />
                )}
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn mt-4"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          <Rbac roles={rbac.upload}>
            <button
              className="btn btn-primary btn-sm fs-val-md me-1"
              onClick={viewFormModal}
            >
              Bulk Upload
            </button>
          </Rbac>
        </div>
      </div>

      {filterLabels.length > 0 ? (
        <div className="mb-3">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingSummary}
        />
      </div>

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingSummary}
        viewCb={viewDetailsModal}
      />

      {/* Advance Filter Modal  */}
      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* View Details Modal */}
      <ViewDetailsModal
        show={showDetailsModal}
        id={formModalDataRef.current}
        closeModal={closeViewModal}
      />

      {/* Creation Form Modal */}
      <CreateFormModal show={showFormModal} closeModal={closeFormModal} />
    </>
  );
};

export default BulkUploadList;
