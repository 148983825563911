import { AjaxService, NotificationService } from "@sk/services";
import {
  Alert,
  AppTitle,
  AutoCompleteInput,
  NoDataFound,
  PageLoader,
  SelectInput,
  SwitchInput,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { editView } from "../../constantService";

const canvasStyle = { width: "45%" };

const NotifEventsEditModal = ({ show, id, callback }) => {
  const { register, reset, control, getValues, setValue } = useForm({
    defaultValues: { ...editView.defaultFormData, rules: [] },
  });

  const [display, setDisplay] = useState("loading");

  const [data, setData] = useState({});

  const templateList = useWatch({ control, name: "rules" });

  const mode = useWatch({ control, name: "mode" });

  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && id) {
      loadTemplateDetails(id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadTemplateDetails, id, show]);

  const loadTemplateDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await NotificationService.getEventsList({ filter: { _id: id } });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    setValue("isActive", d.isActive);

    setData(d);

    if (d._id) {
      autoFilTemplateList(d);
      setDisplay("form");
    } else {
      setDisplay("noDataFound");
    }
  }, [id, setValue, autoFilTemplateList]);

  const autoFilTemplateList = useCallback(
    async (d) => {
      let ids = d.rules.map((e) => {
        return e.template;
      });
      setDisplay("loading");
      let p = { filter: { _id: { $in: ids } } };
      const r = await NotificationService.getList(p);
      let temp = Array.isArray(r.resp) ? r.resp : [];
      if (temp.length) {
        let x = (d?.rules).map((e) => {
          let i = temp.findIndex((k) => {
            return k._id == e.template;
          });
          return { ...e, name: temp?.[i]?.name || "" };
        });
        setValue("rules", [...x]);
      }
    },
    [setValue]
  );

  const onTemplateSearch = useCallback(
    async (val, callback) => {
      let filter = {
        businessEntity: data.businessEntity,
        mode: getValues("mode"),
      };

      if (val) {
        let v = "/" + val + "/";
        filter["$or"] = [{ name: v }, { _id: v }];
      }

      // ignore the template already selected
      if (templateList.length > 0) {
        let ids = templateList.map((x) => x.template);
        filter._id = {
          $nin: ids,
        };
      }

      let p = {
        page: 1,
        count: 10,
        select: "name,_id",
        filter: filter,
      };

      const r = await NotificationService.getList(p);
      callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
    },
    [data.businessEntity, getValues, templateList]
  );

  const addTemplate = () => {
    let f = getValues();
    if (!f.template?.length) {
      Toaster.error("Please Select Template");
      return;
    } else if (!f.mode) {
      Toaster.error("Please Select Mode Type");
      return;
    }
    templateList.push({
      mode: f.mode,
      isActive: false,
      template: f.template?.[0]?.value,
      name: f.template?.[0]?.label,
    });
    reset({ template: [], rules: templateList, mode: "", isActive: false });
  };

  const deleteTemplate = async (index) => {
    let text = "Do you want to remove " + templateList[index].name + "?";
    const r = await Alert.confirm({
      title: "Please confirm",
      text: text,
      cancelText: "No",
      okText: "Yes",
    });
    if (r.isConfirmed) {
      templateList.splice(index, 1);
      setValue("rules", templateList);
    }
  };

  const onSubmit = async () => {
    if (!templateList.length) {
      Toaster.error("Please Add Min One Template");
      return;
    }
    let f = getValues();
    let p = {
      isActive: f.isActive,
      rules: templateList.map((e) => {
        delete e.name;
        return e;
      }),
    };

    const r = await NotificationService.updateEvent(id, p);
    if (r.statusCode == 200) {
      Toaster.success("Event Updated Successfully");
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Update Notification Event Details #" + id} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "form" && (
            <div>
              <div className="fw-semibold fs-val-lg mb-4">
                {data?.name} ({data?.businessEntity})
              </div>

              {/* Is Active  for Event */}
              <div>
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field: { onChange, value } }) => (
                    <SwitchInput
                      label="Is Active"
                      isMandatory={false}
                      value={value}
                      callback={onChange}
                    />
                  )}
                />
              </div>

              {/* Template Add flow */}
              <div className="row">
                {/* Mode Type  options */}
                <div className="col-3 mb-3 ">
                  <SelectInput
                    label="Mode"
                    register={register}
                    name="mode"
                    isMandatory={false}
                    options={editView.modeOptions}
                  />
                </div>

                {mode?.length > 0 ? (
                  <div className="col">
                    <Controller
                      control={control}
                      name="template"
                      render={({ field: { onChange, value } }) => (
                        <AutoCompleteInput
                          label="Template"
                          placeholder="Search and Select Template"
                          value={value}
                          onSearch={onTemplateSearch}
                          callback={onChange}
                          uid="template"
                        />
                      )}
                    />
                  </div>
                ) : null}

                <div className="col-2 pt-4">
                  <button
                    className="btn  btn-primary py-2"
                    type="button"
                    onClick={addTemplate}
                  >
                    Add
                  </button>
                </div>

                {templateList?.length > 0 && (
                  <>
                    <div className="fw-semibold fs-val-lg mb-2">
                      Template List
                    </div>
                    <table className="table table-bordered my-3">
                      <TableHeader data={editView.defaultTableHeader} />
                      <tbody>
                        {templateList.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{item?.name}</td>

                            <td className="text-center">
                              <div>
                                <Controller
                                  control={control}
                                  name={`rules.${index}.isActive`}
                                  render={({ field: { onChange, value } }) => (
                                    <SwitchInput
                                      isMandatory={false}
                                      value={value}
                                      callback={onChange}
                                    />
                                  )}
                                />
                              </div>
                            </td>

                            <td className="text-center">
                              <span
                                onClick={() => deleteTemplate(index)}
                                className="cursor-pointer"
                                role="button"
                                tabIndex={0}
                              >
                                <i className="bi-trash3-fill font-16 text-danger"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>

              <div className="mt-2 text-end">
                <button
                  className="btn  btn-primary py-2"
                  type="button"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NotifEventsEditModal;
