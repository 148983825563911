import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, CronJobService } from "@sk/services";
import {
  Alert,
  DatePickerInput,
  InputErrorMsg,
  SelectInput,
  Toaster,
  Spinner,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import { Modal } from "react-bootstrap";

let startDateConfig = {
  enableTime: false,
};

let endDateConfig = {
  enableTime: false,
};

const typeOptions = [
  { label: "Select Type", value: "" },
  { label: "Franchises", value: "franchises" },
  { label: "Vendors", value: "vendors" },
  { label: "Bills", value: "bills" },
  { label: "New Franchise Sales", value: "newfranchisesales" },
  { label: "New Franchise Deposits", value: "newfranchisedeposits" },
  { label: "New Vendor Bills", value: "newvendorbills" },
  { label: "Invoices", value: "invoices" },
  { label: "Sales Returns", value: "salesReturns" },
  { label: "DMT", value: "dmt" },
  { label: "Recharge", value: "recharge" },
  { label: "Franchise Deposits", value: "franchiseDeposits" },
];
// Validation Schema
const validationSchema = yup.object({
  type: yup.string().trim().required("Job Type is Required"),
  startDate: yup
    .date()
    .typeError("Valid Start Date is Required")
    .required("Valid Start Date is Required "),
  endDate: yup
    .date()
    .typeError("Valid End Date is Required")
    .required("Valid End Date is Required "),
});

function ManageJobsModal({ show, callback }) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { type: "", startDate: "", endDate: "" },
  });

  const [submitting, setSubmitting] = useState(false);

  const startDate = useWatch({ control: methods.control, name: "startDate" });

  useEffect(() => {
    methods.reset();
  }, []);

  const onSubmit = async (data) => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }
    let f = methods.getValues();

    setSubmitting(true);

    let p = {
      type: f?.type,
      startDate: f?.startDate,
      endDate: f?.endDate,
    };

    let r = await CronJobService.createAccountsToZoho(p);
    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error?.msg || "Failed to update");
      return;
    }

    methods.reset();
    Toaster.success("Job Created Successfully");
    callback({ action: "submit" });
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      methods.setValue(key, val[0]);
      if (key == "startDate") {
        endDateConfig = { ...endDateConfig, minDate: val[0] };
        methods.setValue("endDate", val[0]);
      }
      methods.trigger("endDate");
    };
  };

  const onHideCb = useCallback(() => {
    methods.reset();
    callback({ action: "close" });
  }, [callback]);

  return (
    <Modal show={show} backdrop="static" onHide={onHideCb} size="md">
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">Create Job</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div className="p-4">
          <FormProvider {...methods}>
            <form autoComplete="off">
              <div className="row">
                {/* Job Type */}
                <div className="col-12 mb-2">
                  <SelectInput
                    type="text"
                    label="Job Type"
                    register={methods.register}
                    name="type"
                    error={methods?.formState?.errors?.type?.message}
                    options={typeOptions}
                    isMandatory={true}
                  />
                </div>

                {/* Start Date */}
                <div className="col-6 mb-3">
                  <label className="form-label mb-0">
                    Start Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={methods.control}
                    name="startDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange, "startDate")}
                          config={startDateConfig}
                        />
                      );
                    }}
                  />
                  <InputErrorMsg
                    msg={methods?.formState?.errors?.startDate?.message}
                  />
                </div>

                {/* End Date */}
                <div className="col-6 mb-3">
                  <label className="form-label mb-0">
                    End Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={methods.control}
                    name="endDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePickerInput
                        placeholder="Choose"
                        value={[value]}
                        disabled={!startDate}
                        inpChange={dateChangeCb(onChange, "endDate")}
                        config={endDateConfig}
                      />
                    )}
                  />
                  <InputErrorMsg
                    msg={methods?.formState?.errors?.endDate?.message}
                  />
                </div>
              </div>

              <div className="my-3 text-end">
                <button
                  type="button"
                  className="btn btn-outline-dark me-1"
                  onClick={onHideCb}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={submitting}
                >
                  Submit {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(ManageJobsModal);
