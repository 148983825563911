import { CallPartnerAgentAssign } from "@sk/features";
import { AppCard, DateFormatter, InfoPopover, KeyVal } from "@sk/uis";

const RoleDashEmpInfo = ({ data }) => {
  return (
    <>
      <AppCard title="Employee Info">
        <div className="row">
          <div className="col-12">
            <div className="fs-val-lg fw-bold mb-2">
              {data?.firstName} {data?.lastName} - {data?.empCode}
            </div>
          </div>
          <div className="col">
            <KeyVal label="Email" labelCol="col-4" contentCol="col-8">
              : {data?.contact_details?.office_email_id}
            </KeyVal>
            <KeyVal label="Designation" labelCol="col-4" contentCol="col-8">
              : {data?.assignment_details?.designation}
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal label="Department" labelCol="col-4" contentCol="col-8">
              : {data?.assignment_details?.dep || "N/A"}
            </KeyVal>
            <KeyVal label="Joined On" labelCol="col-4" contentCol="col-8">
              : <DateFormatter date={data?.createdAt} format="dd-MMM-yyyy" />
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal label="Last Login" labelCol="col-4" contentCol="col-8">
              : <DateFormatter date={data?.lastLogin?.at} />{" "}
              <InfoPopover
                content={`IP Address: ${data?.lastLogin?.ipAddress || "N/A"}`}
              />
            </KeyVal>
          </div>
          <div className="col-12 mt-3 border-top pt-3">
            <CallPartnerAgentAssign empId={data?._id} />
          </div>
        </div>
      </AppCard>
    </>
  );
};

export default RoleDashEmpInfo;
