import { AuthService, BatchService, UserService } from "@sk/services";
import { AutoCompleteInput, SelectInput } from "@sk/uis";
import { memo, useCallback, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import listView from "../constantService";

const defaultFormData = listView.filterFromData;

const statusOptions = listView.statusOptions;

const canvasStyle = { width: "30%" };

const isSuperAdmin = AuthService.isSuperAdmin();

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  // React Hook Form To Handle Advance Filter Label
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset(defaultFormData);
      }
    }
  }, [show, setValue, formData, reset]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ status: "reset", formData: defaultFormData });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  // Created By search
  const onUserSearch = useCallback(async (val, callback) => {
    let filter = {};
    if (val) {
      filter = { name: "/" + val + "/" };
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };
    const r = await UserService.getList(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name,
        value: x._id,
      }))
    );
  }, []);

  const onTemplateSearch = useCallback(async (val, callback) => {
    let filter = { templateType: "import" };

    if (val) {
      filter = {
        $or: [
          {
            templateName: { $regex: val, $options: "gi" },
          },
          { groupCode: { $regex: val, $options: "gi" } },
        ],
      };
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
      select: "templateName,_id,code,templateCode",
    };
    const r = await BatchService.getTemplateList(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.templateName,
        value: x.templateCode,
      }))
    );
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          <>
            <div className="row">
              {/* Template Code */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="templateCode"
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      label="Search  for Template"
                      placeholder="Search  by Template code/name"
                      value={value}
                      onSearch={onTemplateSearch}
                      callback={onChange}
                      uid="template"
                      emptyLabel="Template Not Found"
                    />
                  )}
                />
              </div>

              {/* Status */}
              <div className="col-12 mb-3">
                <SelectInput
                  type="text"
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                  isMandatory={true}
                />
              </div>

              {/* Created By */}
              {isSuperAdmin ? (
                <div className="col-12 mb-3">
                  <Controller
                    control={control}
                    name="createdBy"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search  Created by"
                        placeholder="Search by Name Here "
                        value={value}
                        onSearch={onUserSearch}
                        callback={onChange}
                        uid="user-info"
                        emptyLabel="No Data Found"
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(AdvanceFilterModal);
