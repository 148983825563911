import { Modal } from "react-bootstrap";
import { AppCard, DateFormatter, NoDataFound, TableHeader } from "@sk/uis";

const MarketingLogModal = ({ show, callback, data, refId }) => {
  const columns = [
    { key: "slno", label: "Sl. No", width: "10%" },
    { key: "status", label: "Status", width: "20%" },
    { key: "remarks", label: "Remarks", width: "50%" },
    { key: "loggedOn", label: "Logged On", width: "20%" },
  ];

  const onClose = () => {
    callback({ action: "close" });
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Logs for {refId}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <AppCard>
          <table className="table table-sm">
            <TableHeader data={columns} noBg={true} />
            <tbody className="fs-val-sm">
              {data.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    <NoDataFound>No data found</NoDataFound>
                  </td>
                </tr>
              ) : null}
              {(data || []).map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.status || "--"}</td>
                  <td>{row.remark || "--"}</td>
                  <td>
                    <DateFormatter date={row.loggedOn} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AppCard>
      </Modal.Body>
    </Modal>
  );
};

export default MarketingLogModal;
