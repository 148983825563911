import { NavService, NotificationService } from "@sk/services";
import { AppCard, PageInfo, Rbac, SummaryCard } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WhatsappTemplateFilter from "./components/WhatsappTemplateFilter";
import WhatsappTemplateTable from "./components/WhatsappTemplateTable";

const rbac = {
  addWhatsappTemplate: ["AddWhatsappTemplate"],
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "WhatsApp Templates",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const defaultSummaryData = [
  {
    label: "Total Templates",
    value: 0,
    color: "primary",
    key: "total",
  },
  {
    label: "Active Templates",
    value: 0,
    color: "success",
    key: "active",
  },
  {
    label: "Inactive Templates",
    value: 0,
    color: "danger",
    key: "inactive",
  },
];

const getData = async (params) => {
  const r = await NotificationService.getList(params);
  return { data: r.resp || [] };
};

const getCount = async (params) => {
  delete params.page;
  delete params.count;
  const r = await NotificationService.getListCount(params);
  return { count: r.resp || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      mode: "WhatsApp",
    },
  };

  if (filter.status === "Active") {
    p.filter.isActive = true;
  } else if (filter.status === "Inactive") {
    p.filter.isActive = false;
  }

  if (filter.search) {
    p.filter.$or = [
      { title: { $regex: filter.search, $options: "i" } },
      { _id: filter.search },
    ];
  }

  if (filter.partner) {
    p.filter.partner = filter.partner;
  }

  if (filter.dateRange) {
    p.filter.createdAt = {
      $gte: set(filter.dateRange[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter.dateRange[1], { hours: 23, minutes: 59, seconds: 59 }),
    };
  }

  return p;
};

const MarketingCampaignList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [summary, setSummary] = useState([...defaultSummaryData]);

  const filterRef = useRef({});
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const loadSummary = async () => {
    const totalCount = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    const activeCount = await getCount(
      prepareFilterParams(
        { ...filterRef.current, isActive: "Active" },
        paginationRef.current
      )
    );
    const inactiveCount = await getCount(
      prepareFilterParams(
        { ...filterRef.current, status: "Inactive" },
        paginationRef.current
      )
    );

    setSummary((prev) => {
      const newSummary = [...prev];
      newSummary[0].value = totalCount.count;
      newSummary[1].value = activeCount.count;
      newSummary[2].value = inactiveCount.count;
      return newSummary;
    });
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();
    loadSummary();

    setLoadingTotalRecords(true);
    const r = await getCount(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    paginationRef.current.totalRecords = r.count;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const r = await getData(
      prepareFilterParams(filterRef.current, paginationRef.current)
    );
    setData(r.data || []);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const handleTableCallback = ({ action, id }) => {
    if (action === "edit") {
      NavService.openInNewTab(`/utility/whatsapp-template/manage`, {
        id,
      });
    }

    if (action === "view") {
      NavService.openInNewTab(`/utility/whatsapp-template/view`, {
        id,
      });
    }
  };

  const onCreateTemplate = () => {
    NavService.to(navigate, "/utility/whatsapp-template/manage");
  };

  const handleFilterChange = ({ action, data }) => {
    if (action === "apply") {
      filterRef.current = { ...filterRef.current, ...data };
      applyFilter();
    }
  };

  return (
    <>
      <div className="row align-items-center my-3">
        <div className="col">
          <PageInfo
            title="WhatsApp Templates"
            breadcrumbs={breadcrumbs}
            noMargin={true}
          />
        </div>
        <Rbac roles={rbac.addWhatsappTemplate}>
          <div className="col-auto">
            <button className="btn btn-primary mb-3" onClick={onCreateTemplate}>
              <i className="bi bi-plus-square bg-lg"></i>
              <span> Create Template </span>
            </button>
          </div>
        </Rbac>
      </div>

      <WhatsappTemplateFilter callback={handleFilterChange} />

      <div className="row">
        {summary.map((e) => (
          <div className="col col-auto" key={e.key}>
            <SummaryCard
              value={e.value}
              title={e.label}
              template={3}
              valueColor={e.color}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <WhatsappTemplateTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          callback={handleTableCallback}
        />
      </AppCard>
    </>
  );
};

export default MarketingCampaignList;
