import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { NavService, NotificationService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageInfo,
  PageLoader,
  Rbac,
  Spinner,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { templateView } from "../constantService";

const rbac = {
  editButton: ["EditNotificationTemplate"],
};

const NotifTempView = () => {
  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);

  const navigate = useNavigate();

  const [display, setDisplay] = useState("loading");

  const [data, setData] = useState({});

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (query.id) {
      loadTemplateDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadTemplateDetails, query.id]);

  // get Product Details
  const loadTemplateDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await NotificationService.getDetailsById(query.id);
    const d = r.resp || {};
    if (d._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }

    //  adding Loading Key for Attach additional Info
    d.userLoading = true;
    d.modifiedLoading = true;
    setData(d);

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], templateView.additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == templateView.additionalTableDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }
  }, [attachAllData, query.id, setAdditionalData]);

  const editDetails = () => {
    NavService.openInNewTab("/utility/notifications/manage", { id: query.id });
  };

  return (
    <>
      <PageInfo
        title="Notification Template Details"
        breadcrumbs={templateView.breadcrumb}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}
      {display == "noDataFound" && <NoDataFound> No Data Found</NoDataFound>}
      {display == "details" && (
        <>
          <AppCard title="Basic Details">
            <div className="fs-val-lg text-primary mb-3 fw-semibold">
              {data?.name}
            </div>

            <div className="row pb-3">
              <div className="col-4">
                {/* <div className="row"> */}
                {/* ID */}
                <div className="row">
                  <KeyVal
                    label="Template ID:"
                    labelCol="col-3"
                    contentCol="col-9"
                  >
                    <span className="fw-semibold">{data._id}</span>
                  </KeyVal>
                </div>

                {/* Mode */}
                <div className="row">
                  <KeyVal label="Mode:" labelCol="col-3" contentCol="col-9">
                    <span className="fw-semibold">{data.mode}</span>
                  </KeyVal>
                </div>
              </div>

              <div className="col-4">
                {/* <div className="row"> */}
                {/* Business Entity */}
                <div className="row">
                  <KeyVal
                    label="Business Entity:"
                    labelCol="col-3"
                    contentCol="col-9"
                  >
                    <span className="fw-semibold">{data.businessEntity}</span>
                  </KeyVal>
                </div>

                {/* Status */}
                <div className="row">
                  <KeyVal label="Status:" labelCol="col-3" contentCol="col-9">
                    <span className="fw-semibold">
                      <HighlightText
                        status={data.isActive ? "Active" : "Inactive"}
                      />
                    </span>
                  </KeyVal>
                </div>
                {/* </div> */}
              </div>

              <div className="col-4">
                {/* Partner */}
                {data?.partner ? (
                  <div className="row">
                    <KeyVal
                      label="Partner:"
                      labelCol="col-3"
                      contentCol="col-9"
                    >
                      <span className="fw-semibold">{data.partner}</span>
                    </KeyVal>
                  </div>
                ) : null}

                {/* Email Template Type */}
                {data?.emailTemplateType ? (
                  <div className="row">
                    <KeyVal
                      label="Email Template Type:"
                      labelCol="col-auto"
                      contentCol="col"
                    >
                      <span className="fw-semibold">
                        {data.emailTemplateType}
                      </span>
                    </KeyVal>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row border-top pt-3 align-items-center">
              {/* Created By */}
              <div className="col-auto">
                <KeyVal
                  label="Created By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="text-primary"
                  noBottomSpace={true}
                >
                  <span className="fw-semibold">
                    {data.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      data._createdBy.name
                    )}
                  </span>
                </KeyVal>
              </div>

              {/* Created At */}
              <div className="col-auto pe-4 border-end">
                <KeyVal
                  label="Created At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              {/* Last Modified By */}
              <div className="col-auto ms-4">
                <KeyVal
                  label="Last Modified By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="text-primary"
                  noBottomSpace={true}
                >
                  <span className="fw-semibold">
                    {data.modifiedLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      data._modifiedBy.name
                    )}
                  </span>
                </KeyVal>
              </div>

              {/* Last Modified At */}
              <div className="col-auto me-auto">
                <KeyVal
                  label="Last Modified At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.lastUpdated} />
                </KeyVal>
              </div>

              {/* Edit */}
              <div className="col-auto">
                <Rbac roles={rbac.editButton}>
                  <button
                    className="btn btn-primary fs-val-sm mb-1"
                    onClick={editDetails}
                  >
                    Edit Template
                  </button>
                </Rbac>
              </div>
            </div>
          </AppCard>

          <div className="row">
            <div className="col">
              {/* Template Details */}
              <AppCard title="Template Details">
                <div className="row">
                  {/* Title */}
                  {data?.default?.title ? (
                    <div className="col-12 mb-2">
                      <KeyVal
                        label="Title:"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span className="fw-semibold">
                          {data.default?.title}
                        </span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Type */}
                  {data?.type ? (
                    <div className="col-12 mb-2">
                      <KeyVal label="Type:" labelCol="col-3" contentCol="col-9">
                        <span className="fw-semibold">{data?.type}</span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Subtype */}
                  {data?.subType ? (
                    <div className="col-12 mb-2">
                      <KeyVal
                        label="Subtype:"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span className="fw-semibold">{data?.subType}</span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Subject */}
                  {data?.default?.subject ? (
                    <div className="col-12 mb-2">
                      <KeyVal
                        label="Subject:"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span className="fw-semibold">
                          {data.default?.subject}
                        </span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Header */}
                  {data?.default?.header ? (
                    <div className="col-12 mb-2">
                      <KeyVal
                        label="Header:"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span className="fw-semibold">
                          {data.default?.header}
                        </span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Body */}
                  {data?.default?.body ? (
                    <div className="col-12 mb-2">
                      <KeyVal label="Body:" labelCol="col-3" contentCol="col-9">
                        <span className="fw-semibold">
                          {data.default?.body}
                        </span>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Footer */}
                  {data?.default?.footer ? (
                    <div className="col-12 mb-2">
                      <KeyVal
                        label="Footer:"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span className="fw-semibold">
                          {data.default?.footer}
                        </span>
                      </KeyVal>
                    </div>
                  ) : null}
                </div>
              </AppCard>

              {/* Partner Details */}
              {data?.partnerConfig?.id ? (
                <div className="mt-2">
                  <AppCard title="Partner Details">
                    <div className="row">
                      {/* ID */}
                      {data?.partnerConfig?.id ? (
                        <div className="col-12 mb-2">
                          <KeyVal
                            label="Partner Template Code:"
                            labelCol="col-3"
                            contentCol="col-9"
                          >
                            <span className="fw-semibold">
                              {data.partnerConfig?.id}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}

                      {/* webhookCode */}
                      {data?.partnerConfig?.webhookCode ? (
                        <div className="col-12 mb-2">
                          <KeyVal
                            label="Partner Webhook Code:"
                            labelCol="col-3"
                            contentCol="col-9"
                          >
                            <span className="fw-semibold">
                              {data.partnerConfig?.webhookCode}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}
                    </div>
                  </AppCard>
                </div>
              ) : null}
            </div>

            {/* Recipient  Details */}
            {data?.to?.length > 0 && (
              <div className="col-4">
                <AppCard title="Recipient List">
                  <>
                    {/* Recipient starts */}

                    <table className="table table-bordered my-3">
                      <TableHeader data={templateView.tableHeader} />
                      <tbody>
                        {data?.to.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                </AppCard>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NotifTempView;
