import { useAttachAdditionalData } from "@sk/hooks";
import { AjaxService, CronJobService } from "@sk/services";
import {
  Alert,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Rbac,
  Spinner,
  TabContent,
  Tabs,
  Toaster,
} from "@sk/uis";
import { produce } from "immer";
import { cloneDeep } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CompletedJobsTable from "./components/CompletedJobsTable";
import ErrorLogsTable from "./components/ErrorLogsTable";
import PendingJobsTable from "./components/PendingJobsTable";

const rbac = {
  editButton: ["ViewZohoDetails"],
};

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const defaultTabs = [
  { key: "completedJobs", tabName: "Completed Jobs" },
  { key: "pendingJobs", tabName: "Pending Jobs" },
  { key: "jobErrors", tabName: "Errors" },
];

const getCount = async (params) => {
  try {
    const r = await CronJobService.getErrorLogsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const ViewCronJobsModal = ({ show, closeModal, modalData, tab }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [submitting, setSubmitting] = useState(false);

  const [countSummary, setCountSummary] = useState({
    completedJobs: 0,
    pendingJobs: 0,
    jobErrors: 0,
  });

  const tabs = useMemo(
    () =>
      cloneDeep(defaultTabs).map((x) => {
        x.count = countSummary[x.key];
        x.tabName = x.tabName + ` (${countSummary[x.key]})`;
        return x;
      }),
    [countSummary]
  );

  const [activeTab, setActiveTab] = useState({
    key: defaultTabs[0].key,
  });

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const loadCountSummary = useCallback(async (d) => {
    const r = await getCount({
      filter: {
        jobId: d._id,
      },
    });
    setCountSummary(
      produce((draft) => {
        draft.completedJobs = d.completedJobIds.length || 0;
        draft.pendingJobs = d.pendingJobIds.length || 0;
        draft.jobErrors = r.count;
      })
    );
  }, []);

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const r = await CronJobService.getCronJobById(modalData?._id);

    let d = r.resp || {};

    loadCountSummary(d);

    setDetails(d);

    setLoading(false);

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setDetails((v) => ({ ...v, ...t }));
        }
      });
    } else {
      Toaster.error("Failed to fetch data, please try again");
      triggerCloseModal();
    }
  }, [
    modalData,
    triggerCloseModal,
    setAdditionalData,
    attachAllData,
    loadCountSummary,
  ]);

  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const triggerCloseModal = useCallback(() => {
    setActiveTab({
      key: defaultTabs[0].key,
    });
    closeModal();
  }, [closeModal]);

  const init = useCallback(() => {
    if (show) {
      if (modalData?._id) {
        fetchDetails();
      }
      if (tab) {
        setActiveTab((prev) => ({ key: tab }));
      }
    }
  }, [fetchDetails, modalData?._id, show, tab]);

  useEffect(() => {
    init();
  }, [init]);

  const markAsFailed = async () => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }
    setSubmitting(true);

    const params = {
      ...details,
      status: "Failed",
    };

    delete params.__v;

    let r = await CronJobService.updateCronJob(modalData?._id, params);
    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r?.resp);
      Toaster.error(error?.msg || "Failed to update");
      return;
    }

    Toaster.success("Updated Successfully");
    init();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          <div className="fs-val-lg text-dark px-2">
            {"Zoho Sync Job" + " - " + modalData?._id}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show && details._id ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {/* Jobs Details */}
                  <div className="p-4 bg-light">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Zoho Sync Jobs Details
                        </div>
                      </div>

                      {/* Title */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Job Type" template="col">
                          <span>{details.jobType}</span>
                        </KeyVal>
                      </div>

                      {/* Description */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Job ID" template="col">
                          {details._id}
                        </KeyVal>
                      </div>
                      {/* Created At */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Created At" template="col">
                          <DateFormatter date={details.createdAt} />
                        </KeyVal>
                      </div>
                      {/* Created By */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Created By" template="col">
                          <span> {details._createdBy?.name}</span>
                        </KeyVal>
                      </div>
                      {/* Last Updated */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Last Updated At" template="col">
                          <DateFormatter date={details.lastUpdated} />
                        </KeyVal>
                      </div>

                      {/* Status*/}
                      <div className="col-3 mb-2">
                        <KeyVal label="Status" template="col">
                          <HighlightText status={details.status} />
                        </KeyVal>
                      </div>

                      {/* Mark as failed */}

                      <div className="col-3 mb-2">
                        {["Completed", "Failed"].indexOf(details?.status) ==
                        -1 ? (
                          <Rbac roles={rbac.editButton}>
                            <button
                              className="btn btn-sm btn-danger me-1 mt-3 fw-semibold fs-val-xs"
                              onClick={markAsFailed}
                              disabled={submitting}
                            >
                              Mark as Failed
                              {submitting ? <Spinner isSmall={true} /> : null}
                            </button>
                          </Rbac>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
                  <TabContent>
                    <div className="py-4 px-2">
                      {activeTab.key == "completedJobs" && (
                        <CompletedJobsTable
                          completedJobs={details.completedJobIds}
                        />
                      )}
                      {activeTab.key == "pendingJobs" && (
                        <PendingJobsTable pendingJobs={details.pendingJobIds} />
                      )}
                      {activeTab.key == "jobErrors" && (
                        <ErrorLogsTable jobId={modalData?._id} />
                      )}
                    </div>
                  </TabContent>
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewCronJobsModal);

/* Old Code 

                   <div className="p-4 bg-white">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Completed Jobs
                        </div>
                      </div>
                    </div>
                  </div> 

 <div className="p-4 bg-white">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Pending Jobs
                        </div>
                      </div>
                    </div>
                  </div> 
*/
