import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Template Config",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      loading: true,
      filter: { isActive: true },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      filter: { isActive: false },
      color: "danger",
      img: inactiveImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    mode: {
      label: "Mode",
    },
    language: {
      label: "Language",
    },
    businessEntity: {
      label: "Business Entity",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    mode: "",
    businessEntity: "",
    language: "",
    name: "",
    createdAt: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", priority: 1, isCentered: true },
    { label: "ID", width: "5%", priority: 2 },
    { label: "Name", width: "17%", key: "name", enableSort: true, priority: 3 },
    { label: "Mode", priority: 4 },
    { label: "Business Entity", priority: 5 },
    { label: "SMS Partner", priority: 6 },
    { label: "Languages", priority: 7 },
    { label: "Created on", priority: 8 },
    { label: "Last Modified On", priority: 9 },
    { label: "Status", width: "9%", priority: 10, isCentered: true },
    { label: "Actions", width: "8%", priority: 11, isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  modeOptions: [
    { label: "All", value: "" },
    { label: "Email", value: "EMail" },
    { label: "In APP", value: "InApp" },
    { label: "Push", value: "PUSH" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WhatsApp" },
  ],

  statusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],

  languageOptions: [
    { label: "All", value: "" },
    { label: "Assamese", value: "Assamese" },
    { label: "Bengali", value: "Bengali" },
    { label: "English", value: "English" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Hindi", value: "Hindi" },
    { label: "Kannada", value: "Kannada" },
    { label: "Kashmiri", value: "Kashmiri" },
    { label: "Konkani", value: "Konkani" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Manipuri", value: "Manipuri" },
    { label: "Marathi", value: "Marathi" },
    { label: "Nepali", value: "Nepali" },
    { label: "Oriya", value: "Oriya" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Tamil", value: "Tamil" },
    { label: "Telugu", value: "Telugu" },
    { label: "Urdu", value: "Urdu" },
  ],

  businessEntityOptions: [
    { label: "All", value: "" },
    { label: "Account", value: "account" },
    { label: "Aeps", value: "aeps" },
    { label: "Asset", value: "asset" },
    { label: "Banner", value: "banner" },
    { label: "Brand", value: "brand" },
    { label: "Category", value: "category" },
    { label: "Campaign", value: "campaign" },
    { label: "Commission", value: "commission" },
    { label: "Config", value: "config" },
    { label: "Coupons", value: "coupons" },
    { label: "Courier", value: "courier" },
    { label: "Customer", value: "customer" },
    { label: "Deal", value: "deal" },
    { label: "Digital Partner", value: "digitalPartner" },
    { label: "Dmt", value: "dmt" },
    { label: "Employee", value: "employee" },
    { label: "Franchise", value: "franchise" },
    { label: "Gateway", value: "gateway" },
    { label: "Grn", value: "grn" },
    { label: "Insurance", value: "insurance" },
    { label: "Investment", value: "investment" },
    { label: "King Alerts", value: "kingalerts" },
    { label: "King Sale", value: "kingsale" },
    { label: "Kyf", value: "kyf" },
    { label: "Lending", value: "lending" },
    { label: "Logistics", value: "logistics" },
    { label: "Medical Service", value: "Medical Service" },
    { label: "Oms", value: "oms" },
    { label: "Payment Requests", value: "paymentrequests" },
    { label: "Pg", value: "pg" },
    { label: "Po", value: "po" },
    { label: "Product", value: "product" },
    { label: "Recharge", value: "recharge" },
    { label: "Reports", value: "reports" },
    { label: "Returns", value: "returns" },
    { label: "Scheduler", value: "scheduler" },
    { label: "Seller", value: "seller" },
    { label: "Sourcing Partner", value: "sourcingPartner" },
    { label: "Ticket", value: "ticket" },
    { label: "User", value: "user" },
    { label: "Vendor", value: "vendor" },
    { label: "Wallet", value: "wallet" },
    { label: "Wh", value: "wh" },
    { label: "Widgets", value: "widgets" },
    { label: "Wypbid Request", value: "wypbidrequest" },
  ],

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    mode: "",
    businessEntity: "",
    language: "",
    createdAt: [],
  },
};

export const formView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Templates",
      link: "/utility/notifications/list",
    },
    {
      name: "Add Notification Templates",
    },
  ],

  // Breadcrumbs Ends Here
  defaultFormValues: {
    businessEntity: "",
    subject: "",
    body: "",
    type: "",
    to: [],
    mode: "",
    isActive: true,
    emailTemplateType: "",
    languages: [],
    actions: [
      { title: "", icon: "", callback: "" },
      { title: "", icon: "", callback: "" },
      { title: "", icon: "", callback: "" },
    ],
  },

  imgTypeOptions: [
    { label: "By Uploading Image", value: "upload" },
    { label: "By Image Link", value: "link" },
  ],
};

export const templateView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Templates",
      link: "/utility/notifications/list",
    },
    {
      name: "Notification Templates Details",
    },
  ],
  // Breadcrumbs Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  tableHeader: [
    { label: "Sl No", width: "15%", priority: 1, isCentered: true },
    { label: "Name", priority: 3 },
  ],
};

export default listView;
