import { yupResolver } from "@hookform/resolvers/yup";
import { ProjectService } from "@sk/services";
import {
  AppCard,
  DateInput,
  EntitySearchInput,
  RichTextEditorInput,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { add } from "date-fns";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import styles from "../ProjectManagement.module.scss";
import ProjectTaskAttachmentUpload from "../components/ProjectTaskAttachmentUpload";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Task name is required")
    .min(3, "Task name must be at least 3 characters"),
  taskType: Yup.string().required("Task type is required"),
  priority: Yup.string().required("Priority is required"),
  dueDate: Yup.array()
    .of(Yup.date().typeError("Due date must be a valid date"))
    .min(1, "Due date is required") // Ensure at least one date is provided
    .required("Due date is required") // Make due date mandatory
    .transform((value, originalValue) => (originalValue === "" ? [] : value)),
  startDate: Yup.array()
    .of(Yup.date().nullable().typeError("Start date must be a valid date")) // Allow empty array, not mandatory
    .nullable() // Allow the entire field to be null
    .transform((value, originalValue) => (originalValue === "" ? [] : value))
    .notRequired(), // Allow empty array to be ignored
  endDate: Yup.array()
    .of(Yup.date().nullable().typeError("End date must be a valid date"))
    .nullable() // Allow empty array
    .notRequired() // Allow empty array to be ignored
    .test(
      "is-greater",
      "End date cannot be less than Start date",
      function (value) {
        const { startDate } = this.parent;
        return !value || startDate.length === 0 || value[0] >= startDate[0];
      }
    )
    .transform((value, originalValue) => (originalValue === "" ? [] : value)),
  assignedTo: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
        value: Yup.object().required("Value is required"),
      })
    )
    .notRequired(), // Change made here: removed the min(1) requirement
  status: Yup.string().required("Status is required"),
  description: Yup.string().label("Description"),
  numberOfHours: Yup.number()
    .min(0, "Number of hours must not be less than zero") // Validation for number of hours
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .notRequired(), // Adjust as needed based on your requirements
});

// Define options and styles
const taskTypeOptions = ProjectService.getTaskTypes();
const priorityOptions = ProjectService.getPriorities();
const statusOptions = ProjectService.getStatuses();

const dateConfig = {
  startDate: {
    enableTime: true,
    position: "above",
    dateFormat: "d M Y, h:i K",
  },
  endDate: {
    enableTime: true,
    position: "above",
    dateFormat: "d M Y, h:i K",
  },
  dueDate: {
    enableTime: true,
    position: "above",
    dateFormat: "d M Y, h:i K",
  },
};

const employeeFilter = {
  filter: {
    "assignment_details.dep": "Technology",
    status: "Active",
  },
};

// Update default form data
const defaultFormData = {
  name: "",
  priority: "Low",
  taskType: "task",
  dueDate: [],
  assignedTo: [],
  status: "Todo",
  startDate: [],
  endDate: [],
  numberOfHours: null,
  description: "",
};

const empTemplate = (employee) => {
  return (
    <div>
      <div className="fs-val-md">{employee.label}</div>
      <div className="fs-val-xs text-muted">
        {employee.value?.assignment_details?.designation}
      </div>
    </div>
  );
};

const handleDateChange = (value) => {
  return Array.isArray(value) && value.length === 0 ? null : value;
};

const ProjectTaskManageModal = ({
  show,
  callback,
  projectId,
  taskId,
  subTaskId,
  isSubTask = false,
}) => {
  const [submitting, setSubmitting] = useState(false); // Change loading to submitting
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema), // Integrate Yup validation
  });

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const loadTaskDetails = async () => {
      if (show) {
        let response;

        if (isSubTask && subTaskId) {
          response = await ProjectService.getSubTask(subTaskId);
        } else if (taskId && !isSubTask) {
          response = await ProjectService.getTask(taskId);
        } else {
          reset({ ...defaultFormData });
          return;
        }

        if (response.statusCode === 200 && response.resp.length > 0) {
          const task = response.resp[0];
          setAttachments(task.documents || []);
          reset({
            name: task.title,
            priority: task.priority,
            taskType: task.type,
            numberOfHours: task.numberOfHours,
            dueDate: task.dueDate ? [new Date(task.dueDate)] : [],
            assignedTo: task.assignedTo?.name
              ? [
                  {
                    label: task.assignedTo.name,
                    value: {
                      _id: task.assignedTo.id,
                      firstName: task.assignedTo.name,
                    },
                  },
                ]
              : [],
            status: task.status,
            startDate: task.startDate ? [new Date(task.startDate)] : null,
            endDate: task.endDate ? [new Date(task.endDate)] : null,
            description: task.description,
          });
        }
      }
    };

    loadTaskDetails();
  }, [show, taskId, projectId, reset, subTaskId, isSubTask]);

  const saveAndAddNext = (data) => {
    doSubmit(data, "addNext");
  };

  const saveAndClose = (data) => {
    doSubmit(data, "submit");
  };

  const doSubmit = async (data, type = "submit") => {
    setSubmitting(true); // Set submitting to true on submit
    const payload = {
      projectId: projectId,
      title: data.name,
      description: data.description,
      status: data.status,
      priority: data.priority,
      type: data.taskType,
      numberOfHours: data.numberOfHours,
      dueDate:
        Array.isArray(data.dueDate) && data.dueDate.length > 0
          ? data.dueDate[0]
          : null,
      startDate:
        Array.isArray(data.startDate) && data.startDate.length > 0
          ? data.startDate[0]
          : null,
      endDate:
        Array.isArray(data.endDate) && data.endDate.length > 0
          ? data.endDate[0]
          : null,
      finalCompletionDate: null,
      finalDueDate:
        Array.isArray(data.dueDate) && data.dueDate.length > 0
          ? data.dueDate[0]
          : null,
      documents: attachments.map((attachment) => {
        return {
          assetId: attachment.assetId,
          type: "image",
        };
      }),
    };

    if (data.assignedTo.length > 0) {
      payload.assignedTo = {
        id: data.assignedTo[0].value._id,
        name:
          data.assignedTo[0].value.firstName +
          " " +
          (data.assignedTo[0].value.lastName || ""),
      };
    }

    let response;

    if (isSubTask) {
      payload.type = "subTask";
      payload.parentTaskId = taskId;
    }

    if (taskId) {
      response = await ProjectService.updateTask(taskId, payload);
    } else {
      response = await ProjectService.createTask(payload);
    }

    setSubmitting(false); // Set submitting to false after response
    if (response.statusCode === 200) {
      Toaster.success(
        "Task " + (taskId ? "updated" : "submitted") + " successfully"
      );
      resetForm();
      callback({ action: type });
    } else {
      Toaster.error(
        "Failed to " +
          (taskId ? "update" : "submit") +
          " task, please try again"
      );
    }
  };

  const resetForm = () => {
    reset({ ...defaultFormData });
    setAttachments([]);
  };

  const closeModal = () => {
    callback({ action: "closed" });
  };

  const handleAttachmentsChange = (newAttachments) => {
    setAttachments(newAttachments);
  };

  const onNoHoursChange = (e) => {
    const v = e.target.value;
    const startDate = getValues("startDate");
    setValue("endDate", [add(startDate?.[0] || new Date(), { hours: v })]);
    setValue("dueDate", [add(startDate?.[0] || new Date(), { hours: v })]);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      centered
      scrollable
      autoFocus={false}
      enforceFocus={false}
      dialogClassName={classNames(styles["task-manage-modal"])}
    >
      <Modal.Header closeButton className="bg-white">
        <Modal.Title className="text-dark mb-0">
          {isSubTask
            ? subTaskId
              ? "Edit Sub Task" + " - #" + subTaskId
              : "Create Sub Task" + " - #" + taskId
            : taskId
            ? "Edit Task" + " - #" + taskId
            : "Create Task"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg custom-scrollbar">
        <AppCard>
          <form>
            <div className="row">
              <div className="col-8">
                <div className="row">
                  {!isSubTask ? (
                    <div className="col-2">
                      <SelectInput
                        label="Type"
                        options={taskTypeOptions} // Use the imported options
                        register={register}
                        name="taskType"
                        error={errors.taskType?.message} // Display error message
                      />
                    </div>
                  ) : null}
                  <div className="col">
                    <TextInput
                      label="Title"
                      placeholder="Enter Title"
                      register={register}
                      name="name"
                      error={errors.name?.message}
                      isMandatory={true}
                    />
                  </div>
                  <div className="col-12">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <RichTextEditorInput
                          value={field.value}
                          onChangeCb={(e) => field.onChange(e)}
                          label="Description"
                          error={errors.description?.message}
                          isMandatory={false}
                        />
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="assignedTo"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <EntitySearchInput
                          label="Assigned To"
                          placeholder="Search for a user"
                          type="employee"
                          callback={onChange}
                          value={value}
                          error={errors.assignedTo?.message} // Display error message
                          filterParams={employeeFilter}
                          isMandatory={false}
                          template={empTemplate}
                        />
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <SelectInput
                      label="Priority"
                      options={priorityOptions} // Use the imported options
                      register={register}
                      name="priority"
                      error={errors.priority?.message} // Display error message
                    />
                  </div>

                  <div className="col-4">
                    <SelectInput
                      label="Status"
                      options={statusOptions} // Use the imported options
                      register={register}
                      name="status"
                      error={errors.status?.message} // Display error message
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          label="Start Date"
                          placeholder="Select start date"
                          callback={(value) =>
                            field.onChange(handleDateChange(value))
                          }
                          value={field.value}
                          error={errors.startDate?.message} // Display error message
                          config={dateConfig.startDate}
                        />
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="No. of Hours"
                      placeholder="Enter number of hours"
                      register={register}
                      name="numberOfHours"
                      error={errors.numberOfHours?.message}
                      isMandatory={false}
                      type="number"
                      callback={onNoHoursChange}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          label="End Date"
                          placeholder="Select end date"
                          callback={(value) =>
                            field.onChange(handleDateChange(value))
                          }
                          value={field.value}
                          error={errors.endDate?.message} // Display error message
                          config={dateConfig.endDate}
                        />
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="dueDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          label="Due Date"
                          placeholder="Select due date"
                          callback={(value) =>
                            field.onChange(handleDateChange(value))
                          }
                          value={field.value}
                          error={errors.dueDate?.message} // Display error message
                          config={dateConfig.dueDate}
                          isMandatory={true}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="mt-4">
                  <ProjectTaskAttachmentUpload
                    attachments={attachments}
                    onAttachmentsChange={handleAttachmentsChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </AppCard>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleSubmit(saveAndAddNext)}
          disabled={submitting}
        >
          Save and Add Next
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={submitting}
          onClick={handleSubmit(saveAndClose)}
        >
          {submitting ? (
            <>
              <Spinner isSmall={true} />
              Submitting...
            </>
          ) : (
            "Submit"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectTaskManageModal;
