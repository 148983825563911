import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  language: Yup.string().required("Language is required"),
  partner: Yup.string().required("Partner is required"),
  status: Yup.string().required("Status is required"),
  mediaType: Yup.string().required("Media type is required"),
  headerText: Yup.string().when("mediaType", {
    is: (mediaType) => mediaType === "text",
    then: (schema) => schema.required("Header text is required"),
  }),
  headerImage: Yup.string().when("mediaType", {
    is: (mediaType) => mediaType === "image",
    then: (schema) => schema.required("Header image is required"),
  }),
  body: Yup.string().required("Body is required"),
  to: Yup.string().required("Recipients are required"),
  actions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Action type is required"),
      buttonText: Yup.string().when("type", {
        is: (type) => type === "website_url" || type === "phone_number",
        then: (schema) => schema.required("Button text is required"),
      }),
      websiteUrl: Yup.string().when("type", {
        is: (type) => type === "website_url",
        then: (schema) =>
          schema.url("Must be a valid URL").required("Website URL is required"),
      }),
      phoneNumber: Yup.string().when("type", {
        is: (type) => type === "phone_number",
        then: (schema) => schema.required("Phone number is required"),
      }),
      quickReplyText: Yup.string().when("type", {
        is: (type) => type === "quick_replies",
        then: (schema) => schema.required("Quick reply text is required"),
      }),
    })
  ),
  headerDynamicSample: Yup.string(),
  bodyDynamicSample: Yup.string(),
});

export default validationSchema;
