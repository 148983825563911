import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .label("Notification Name")
    .required()
    .max(100, "Max Notification length should be 100 Character"),

  businessEntity: yup.string().trim().label("Business Entity").required(),

  subject: yup
    .string()
    .trim()
    .label("subject")
    .when("mode", {
      is: (mode) => mode == "EMail",
      then: (schema) => schema.required(),
    })
    .max(100, "Max Subject length should be 100 Character"),

  body: yup.string().trim().label("Body").required(),

  type: yup
    .string()
    .trim()
    .label("Type")
    .max(100, "Max Type length should be 100 Character")
    .when("mode", {
      is: (mode) => mode == "InApp",
      then: (schema) => schema.required(),
    }),

  subType: yup
    .string()
    .trim()
    .label("Subtype")
    .max(50, "Max Subtype length should be 50 Character")
    .when("mode", {
      is: (mode) => mode == "InApp",
      then: (schema) => schema.required(),
    }),

  title: yup
    .string()
    .trim()
    .label("Title")
    .max(100, "Max Title length should be 100 Character")
    .when("mode", {
      is: (mode) => ["InApp", "PUSH"].indexOf(mode) != -1,
      then: (schema) => schema.required(),
    }),

  to: yup.array().min(1).label("Recipient").required(),

  mode: yup.string().trim().label("Mode").required(),

  isActive: yup.boolean().default(false),

  emailTemplateType: yup
    .string()
    .trim()
    .label("Email Template Type")
    .when("mode", {
      is: (mode) => mode == "EMail",
      then: (schema) => schema.required(),
    })
    .max(50, "Max Email Template Type length should be 50 Character"),

  languages: yup.array(),

  partner: yup.string().when("mode", {
    is: (mode) => ["WhatsApp", "SMS"].indexOf(mode) != -1,
    then: (schema) => schema.label("Partner").required(),
  }),

  header: yup.string().when("mode", {
    is: (mode) => mode == "WhatsApp",
    then: (schema) => schema.label("Header").required(),
  }),

  footer: yup.string().when("mode", {
    is: (mode) => mode == "WhatsApp",
    then: (schema) => schema.label("Footer").required(),
  }),

  partnerConfigWebHookCode: yup
    .string()
    .trim()
    .label("Partner Webhook Code")
    .max(50, "Max  Partner Webhook Code length should be 50 Character")
    .when(["mode", "partner"], {
      is: (mode, partner) =>
        ["InApp", "PUSH"].indexOf(mode) != -1 &&
        ["wanotifier_whatsapp"].indexOf(partner) != -1,
      then: (schema) => schema.required(),
    }),
});

export default validationSchema;
