import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID", width: "8%" },
  { label: "Name", width: "15%" },
  { label: "Role Type", width: "9%" },
  { label: "Parent Role", width: "15%" },
  { label: "Department", width: "15%" },
  { label: "Updated On", width: "12%" },
  { label: "Actions", isCentered: true, width: "12%" },
];

const rbac = {
  editRole: ["EditRole"],
  viewRole: ["ViewRole"],
  deleteRole: ["DeleteRole"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  viewCb,
  sort,
  sortCb,
  deleteCb,
}) => {
  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-striped table-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} noBg />
        <tbody>
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No  Data Found*/}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Roles Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* Table List */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                {/* Role ID */}
                <td>{x?._id}</td>

                {/* Role Name */}
                <td>{x?.name || "N/A"}</td>

                {/* Role Type */}
                <td>{x?.roleType || "N/A"}</td>

                {/*Parent Role  */}
                <td>{x?._parent?.name || "N/A"}</td>

                {/* Department */}
                <td>{x.department || "N/A"}</td>

                {/* Last updated By */}
                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>

                {/* Action buttons  */}
                <td className="text-center">
                  <Rbac roles={rbac.viewRole}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-sm"
                      onClick={() => viewCb(x)}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editRole}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-sm"
                      onClick={() => editCb(x)}
                    >
                      Edit
                    </button>
                  </Rbac>

                  <Rbac roles={rbac.deleteRole}>
                    <button
                      className="btn btn-sm btn-outline-danger me-1 fs-val-sm"
                      onClick={() => {
                        deleteCb(x);
                      }}
                    >
                      Delete
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
