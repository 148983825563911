import React from "react";
import { FileUpload2, Spinner } from "@sk/uis";
import { appConfigs } from "@sk/services";

// Define accepted file types outside the component
const acceptedFileTypes = "image/*,.xls,.xlsx,.pdf"; // Allowed file types

const ProjectTaskAttachmentUpload = ({
  attachments = [],
  onAttachmentsChange,
  submitLoading = false,
}) => {
  const handleFileUpload = (fileData) => {
    if (fileData.status === "success") {
      const newAttachment = {
        assetId: fileData.resp._id,
        type: fileData.resp.type,
      };
      const updatedAttachments = [...attachments, newAttachment];
      onAttachmentsChange?.(updatedAttachments);
    }
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    onAttachmentsChange?.(updatedAttachments);
  };

  const handleDownloadAttachment = (assetId) => {
    window.open(appConfigs.ASSET + "/" + assetId, "_blank");
  };

  return (
    <div>
      {/* Upload Button */}
      <div>
        <FileUpload2
          url={appConfigs.ASSET_UPLOAD}
          callback={handleFileUpload}
          accept={acceptedFileTypes} // Pass the accepted file types
        >
          <button
            type="button"
            className="btn btn-light btn-sm"
            disabled={submitLoading}
          >
            {submitLoading ? (
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="me-2" />
                Uploading...
              </span>
            ) : (
              <span className="d-flex align-items-center">
                <i className="bi bi-paperclip me-2"></i>
                Attach Files
              </span>
            )}
          </button>
        </FileUpload2>
      </div>

      {/* Attachments List */}
      {attachments.length > 0 && (
        <div className="mb-3 rounded p-2">
          {attachments.map((file, index) => (
            <div
              key={index}
              className="row align-items-center mb-2 bg-white rounded py-2 px-1"
            >
              <div className="col">
                <button
                  className="btn btn-link text-dark fs-val-sm p-0"
                  onClick={() => handleDownloadAttachment(file.assetId)}
                  type="button"
                >
                  <i className="bi bi-file-earmark me-1"></i>
                  <span>Attachment {index + 1}</span>
                </button>
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link text-danger p-0"
                  onClick={() => handleRemoveAttachment(index)}
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectTaskAttachmentUpload;
