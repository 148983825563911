import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";
import listView from "../../constantService";

const NotifCallLogsTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Notification Call Logs Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Employee */}
                <td>
                  <div className="text-primary">
                    {x?.employee?.name}- ({x?.employee?.id})
                  </div>
                  <div className="fs-val-sm text-muted">
                    <span className="text-muted">Mobile No: </span>
                    {x?.employee?.phone}
                  </div>
                </td>

                {/* Franchise */}
                <td>
                  <div className="text-primary">
                    {x?.franchise?.name} - ({x?.franchise?.id})
                  </div>
                  <div className="fs-val-sm text-muted">
                    <span className="text-muted">Mobile No: </span>
                    {x?.franchise?.phone}
                  </div>
                </td>

                {/* Reason */}
                <td className="text-center">{x?.reason}</td>

                {/* Remarks */}
                <td>{x.remarks}</td>

                {/*  Created At */}
                <td className="text-center">
                  <DateFormatter date={x.createdAt} />
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x._status} isBadge={true} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(NotifCallLogsTable);
