import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl No", width: "5%", key: "slNo" },
  { label: "ID", width: "8%", key: "_id" },
  { label: "Project Name", width: "23%", enableSort: true, key: "name" },
  {
    label: "Project For",
    width: "8%",
    enableSort: true,
    key: "projectFor",
  },
  {
    label: "Project Manager",
    width: "12%",
    enableSort: true,
    key: "assignedTo.name",
  },
  { label: "Status", width: "8%", enableSort: true, key: "status" },
  { label: "Start Date", width: "8%", enableSort: true, key: "startDate" },
  { label: "End Date", width: "8%", enableSort: true, key: "endDate" },
  { label: "Days Remaining", width: "10%", key: "daysRemaining" },
  { label: "Action", width: "10%", key: "action" },
];

const rbac = {
  editProject: ["EditProject"],
  viewTask: ["ViewTask"],
};

function ProjectTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
  sort,
  sortCb,
}) {
  const view = (id) => {
    // callback({ action: "view", id });
    NavService.openInNewTab(`/utility/project-management/tasks`, {
      id,
    });
  };

  const edit = (id) => {
    callback({ action: "edit", id });
  };

  const clone = (id) => {
    callback({ action: "clone", id });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <table className="table table-sm table-striped table-hover">
        <TableHeader data={headers} noBg sort={sort} sortCb={sortCb} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader cols={headers.length} rows={10} height={40} />
          ) : null}

          {data.map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x._id}</td>
              <td>
                <div className="mb-1">
                  <button
                    className="btn btn-link p-0 text-start fs-val-md text-dark"
                    onClick={() => view(x._id)}
                  >
                    {x.name}
                  </button>
                </div>
              </td>
              <td>{x.projectFor || "--"}</td>
              <td>
                <div className="mb-1">{x.assignedTo?.name}</div>
              </td>
              <td>
                <HighlightText type={x.statusType} template={2} size="sm">
                  {x.status}
                </HighlightText>
              </td>
              <td>
                <DateFormatter date={x.startDate} format="dd MMM yyyy" />
              </td>
              <td>
                <DateFormatter date={x.endDate} format="dd MMM yyyy" />
              </td>
              <td>
                <div className={x.daysRemaining < 0 ? "text-danger" : ""}>
                  {x.daysRemaining} days
                </div>
              </td>
              <td>
                <Rbac roles={rbac.editProject}>
                  <button
                    className="btn btn-outline-danger text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => edit(x._id)}
                  >
                    Edit
                  </button>
                </Rbac>

                <Rbac roles={rbac.viewTask}>
                  <button
                    className="btn btn-outline-primary text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => view(x._id)}
                  >
                    View
                  </button>
                </Rbac>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
}

export default ProjectTable;
