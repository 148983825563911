import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ViewCronJobsModal from "../modals/ViewCronJobsModal";

const rbac = {
  viewButton: ["ViewZohoDetails"],
  editButton: ["ViewZohoDetails"],
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Job ID", isCentered: true },
  { label: "Job Type", isCentered: true },
  { label: "Status", isCentered: true },
  { label: "Completed Jobs Ids", isCentered: true, priority: 9 },
  { label: "Pending Job Ids", isCentered: true },
  { label: "Created on", isCentered: true, enableSort: true, key: "createdAt" },
  {
    label: "Modified On",
    isCentered: true,
    enableSort: true,
    key: "lastUpdated",
  },

  { label: "Actions", isCentered: true, priority: 12, width: "12%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  // To Navigate to View Page
  const viewBrand = (id) => {
    NavService.openInNewTab("/cms/brand/view", { id: id });
  };

  const [showCronJobsModal, setShowCronJobModal] = useState(false);

  const viewModalRef = useRef({ modalData: {}, tab: "" });

  const openViewCronJobsModal = useCallback((x, tab = "") => {
    viewModalRef.current.modalData = x;
    viewModalRef.current.tab = tab;
    setShowCronJobModal(true);
  }, []);

  const closeViewCronJobsModal = useCallback(() => {
    viewModalRef.current.modalData = {};
    viewModalRef.current.tab = "";
    setShowCronJobModal(false);
  }, []);

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Cron Jobs Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Id */}
                <td className="text-primary">
                  <div className="text-primary">{x._id}</div>
                </td>

                <td className="text-center">{x.jobType}</td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={false} />
                </td>

                {/* Completed Jobs */}
                <td className="text-center">
                  <button
                    className="btn btn-link fs-val-md"
                    onClick={() => openViewCronJobsModal(x, "completedJobs")}
                  >
                    {x.completedJobIds.length}
                  </button>
                </td>

                {/* Pending Jobs */}
                <td>
                  <button
                    className="btn btn-link fs-val-md"
                    onClick={() => openViewCronJobsModal(x, "pendingJobs")}
                  >
                    {x.pendingJobIds.length}
                  </button>
                </td>

                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>
                    By :{" "}
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x._createdBy.name || x.createdBy || "N/A"
                    )}
                  </div>
                </td>

                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>

                {/* Action Buttons */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => openViewCronJobsModal(x)}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <ViewCronJobsModal
        show={showCronJobsModal}
        modalData={viewModalRef.current.modalData}
        closeModal={closeViewCronJobsModal}
        tab={viewModalRef.current.tab}
      />
    </>
  );
};

export default memo(Table);
