import { AjaxService, BatchService, appConfigs } from "@sk/services";
import {
  Alert,
  AppCard,
  AppTitle,
  AutoCompleteInput,
  FileUpload,
  KeyVal,
  Spinner,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const CreateFormModal = ({ show, closeModal }) => {
  const { control, setValue, getValues, trigger, reset } = useForm({
    defaultValues: {
      templateCode: [],
      uploadedFile: "",
    },
  });

  const [info, setInfo] = useState({
    loading: false,
    apiError: "",
    uploadNewFile: false,
  });

  const [summary, setSummary] = useState(false);

  const [sampleFile, setSampleFile] = useState({});

  const [selectedTemplate, setSelectedTemplate] = useState({});

  const onTemplateSearch = useCallback(async (val, callback) => {
    let filter = { templateType: "import" };

    if (val) {
      filter = { templateName: { $regex: val, $options: "gi" } };
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
      select: "templateName,_id,templateCode,sampleFilePath",
    };
    const r = await BatchService.getTemplateList(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.templateName,
        value: x,
      }))
    );
  }, []);

  const fileUploadCb = async (e) => {
    setValue("selectedFile", e.asset);

    // setting data for displaying purpose
    setSelectedTemplate(e.asset);
    await trigger("selectedFile");
  };

  const onTemplateChange = useCallback(
    (chngFn) => {
      return async (val) => {
        chngFn(val);
        setSampleFile(val.length ? val[0].value : {});
        await trigger("templateCode");
      };
    },
    [trigger]
  );

  const download = (assetId) => {
    let url = appConfigs.ASSET + "/" + assetId;
    window.open(url, "_blank");
  };

  const submit = async () => {
    let f = getValues();
    let msg = "";
    if (!f.templateCode.length) {
      msg = "Please Choose Template Code";
    } else if (!f.selectedFile) {
      msg = "Please Upload File";
    }

    if (msg) {
      await Toaster.error(msg);
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    let apiMaxCallCount = 0;

    setInfo({ ...info, loading: true });

    let p = {
      file: f.selectedFile,
      templateId: f.templateCode[0].value.templateCode,
    };

    let r = await BatchService.createBulkTemplate(p);

    if (r.statusCode == 200) {
      setInfo({ loading: true, uploadNewFile: false, apiError: "" });
      // Success
      if (r.resp?._id && r.resp.status == "PENDING") {
        let t = setInterval(async () => {
          // Api call increment;
          apiMaxCallCount++;

          if (apiMaxCallCount > 10) {
            // when api calls more than 10 times clearing Interval
            clearInterval();
            setInfo({ loading: false, uploadNewFile: true, apiError: "" });
          } else {
            let c = await checkUploadedFileStatus(r.resp?._id);
            setSummary(c.data);
            // When status is completed the clearing interval
            if (c.data && c.data.status == "COMPLETED") {
              clearInterval(t);
              setInfo({ loading: false, uploadNewFile: true, apiError: "" });
            }
          }
        }, 5000);
      }
    } else {
      let error = AjaxService.parseError(r.resp);
      setInfo({ loading: false, uploadNewFile: true, apiError: error.msg });
      // Toaster.error(error.msg || "Failed to update");
      return;
    }
  };

  const checkUploadedFileStatus = async (id) => {
    let r = await BatchService.getList({ filter: { _id: id } });
    return { data: Array.isArray(r.resp) ? r.resp[0] : {} };
  };

  const removeUploadedTemplate = () => {
    setValue("selectedFile", "");
    setSelectedTemplate("");
  };

  const closeModalCb = (status) => {
    resetForm();
    closeModal(status);
  };

  const resetForm = () => {
    reset();
    setValue("selectedFile", "");
    setSelectedTemplate("");
    setSummary({});
    setInfo({ loading: false, uploadNewFile: false, apiError: "" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle title="Create Bulk Upload" />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-3  border-top">
          <div className="row">
            {/* Template code Search */}
            <div className="col-12 mb-3">
              <Controller
                control={control}
                name="templateCode"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    label="Search  Template Code"
                    placeholder="Search By Name Here "
                    value={value}
                    onSearch={onTemplateSearch}
                    callback={onTemplateChange(onChange)}
                    uid="assign-group"
                    emptyLabel="Template Not Found"
                  />
                )}
              />
            </div>

            {/* File Upload Options */}
            <div className="col-12 mb-5">
              {!selectedTemplate?.name ? (
                <FileUpload
                  template={1}
                  callback={fileUploadCb}
                  accept={{ "text/csv": [".csv"] }}
                  acceptedFormats={[".csv", ".xls"]}
                  allowApiUpload={false}
                />
              ) : (
                <div className="row p-2 mx-0 border rounded border-success">
                  <div className="col">
                    <div className="fw-semibold mb-1">Uploaded File</div>
                    <div className="fs-val-md">
                      File Name :
                      <span className="text-success ms-2">
                        {selectedTemplate?.name}
                      </span>
                    </div>
                  </div>
                  {/* Remove Option */}
                  <div className="col-auto">
                    <button className="btn" onClick={removeUploadedTemplate}>
                      <i className="bi bi-trash-fill text-danger fs-4 pt-2"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Summary */}
          <div className="row">
            {summary._id ? (
              <div className="col-12">
                <AppCard noShadow={true}>
                  <p className="p-2">
                    <span className="text-warning">Warning : </span>
                    <span className="text-muted">
                      If you receive the template upload response as failed,
                      Please refresh and cross verify if the uploaded template
                      is processing or failed from listing before uploading new
                      template again.
                    </span>
                  </p>
                </AppCard>

                <AppCard noShadow={true}>
                  <div className="fs-val-md fw-semibold mb-3">Summary</div>
                  {/* Batch ID */}
                  <div className="row">
                    <KeyVal
                      label="Batch ID :"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <span className=" fw-semibold">{summary._id}</span>
                    </KeyVal>

                    {/* Status */}
                    <KeyVal
                      label="Status :"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <span className=" fw-semibold">{summary.status}</span>
                    </KeyVal>

                    {/* Total Records */}
                    <KeyVal
                      label="Total Records :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className="text-primary fw-semibold">
                        {summary.totalRequestCount}
                      </span>
                    </KeyVal>

                    {/* Success Records */}
                    <KeyVal
                      label="Success Records :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-success"
                    >
                      <span className="text-success fw-semibold">
                        {summary.totalSuccessRequestCount}
                      </span>
                    </KeyVal>

                    {/* Failure Records */}
                    <KeyVal
                      label="Failure Records :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-danger"
                    >
                      <span className=" fw-semibold">
                        {summary.totalFailureRequestCount}
                      </span>
                    </KeyVal>

                    {/* Remaing Records */}
                    <KeyVal
                      label="Remaining Records :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className="text-primary fw-semibold">
                        {summary.totalRequestCount -
                          summary.totalSuccessRequestCount -
                          summary.totalFailureRequestCount}
                      </span>
                    </KeyVal>
                  </div>
                  {summary?.totalFailureRequestCount > 0 ? (
                    <div>
                      <p className="mt-2">
                        <span className="text-warning">Note : </span>
                        <span className="text-muted">
                          Some records are failed, Please download file and
                          check reasons for failure
                        </span>
                      </p>
                      <div>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => download(summary?.outputFilePath)}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  ) : null}
                </AppCard>
              </div>
            ) : null}
          </div>

          {/* API Error display */}

          {info.apiError ? (
            <div className="mb-4">
              <AppCard noShadow={true}>
                <div className="mb-0">
                  <span className="text-danger">Error : </span>
                  <span className="text-muted">{info.apiError}</span>
                </div>
              </AppCard>
            </div>
          ) : null}

          {/* Sample and submit btn */}
          {!info.uploadNewFile ? (
            <div className="row mt-2">
              <div className="col-6">
                {sampleFile?.sampleFilePath?.length && (
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={() => download(sampleFile?.sampleFilePath)}
                  >
                    Download Sample File
                  </button>
                )}
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn  btn-primary"
                  onClick={submit}
                  disabled={info.loading}
                >
                  Submit
                  {info.loading ? (
                    <span className="align-middle ps-2">
                      <Spinner />
                    </span>
                  ) : null}
                </button>
              </div>
            </div>
          ) : null}

          {info.uploadNewFile && !info.loading ? (
            <div className="row mt-2">
              <div className="col-6 ">
                <button
                  className="btn  btn-outline-danger"
                  onClick={() => {
                    closeModalCb({ status: "submit" });
                  }}
                >
                  Close Modal
                </button>
              </div>
              <div className="col-6 text-end">
                <button className="btn  btn-primary" onClick={resetForm}>
                  Upload New File
                </button>
              </div>
            </div>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(CreateFormModal);
