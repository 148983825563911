import { useFetchUrlQueryString } from "@sk/hooks";
import { AuthService, NavService, ProjectService } from "@sk/services";
import { PageInfo, PageLoader, Rbac } from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProjectTaskList from "../components/ProjectTaskList";
import ProjectTaskNotifications from "../components/ProjectTaskNotifications";
import ProjectTaskManageModal from "../modals/ProjectTaskManageModal";
import ProjectTaskViewModal from "../modals/ProjectTaskViewModal";
import ProjectTaskSummary from "./ProjectTaskSummary";
import ProjectTaskStatus from "./ProjectTaskStatus";
import { orderBy } from "lodash";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Task Board",
  },
];

const selectInputStyle = {
  border: "none",
  borderBottom: "1px solid #ccc", // Adjust color as needed
  backgroundColor: "transparent",
  outline: "none",
};

const rbac = {
  createTask: ["CreateTask"],
  viewProject: ["ViewProject"],
};

const ProjectTasks = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [selectedProject, setSelectedProject] = useState(null);

  const [manageTaskModal, setManageTaskModal] = useState({
    show: false,
    taskId: null,
    projectId: null,
  });

  const [viewTaskModal, setViewTaskModal] = useState({
    show: false,
    taskId: null,
  });

  const [refreshList, setRefreshList] = useState(false);

  const [projects, setProjects] = useState([]);

  const [filterData, setFilterData] = useState(null);

  const [statuses, setStatuses] = useState([]);

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await ProjectService.getList({
        page: 1,
        count: 100,
        sort: { name: 1 },
      });

      let projects = Array.isArray(response.resp) ? response.resp : [];
      if (projects.length > 0) {
        projects.unshift({
          _id: "all",
          name: "All Projects",
        });
      }
      setProjects(orderBy(projects, ["name"], ["asc"]));
    };

    setSelectedProject(query.id);

    setStatuses(["inProgress"]);

    setLoading(true);
    const t = setTimeout(() => {
      setLoading(false);
      clearTimeout(t);
    }, 1000);

    if (AuthService.isValidUserRole(rbac.viewProject)) {
      fetchProjects();
    } else {
      setProjects([]);
    }
  }, [query.id]);

  const handleProjectChange = (e) => {
    let params = { id: e.target.value };
    if (query.taskType == "my") {
      params.taskType = "my";
    }
    NavService.replace(navigate, `/utility/project-management/tasks`, params);
  };

  const handleModalClose = ({ action }) => {
    if (action === "submit" || action === "addNext") {
      setRefreshList(true);
      const t = setTimeout(() => {
        setRefreshList(false);
        clearTimeout(t);
      }, 2000);
    }

    if (action === "addNext") {
      setManageTaskModal({ show: true, taskId: null });
    } else {
      setManageTaskModal({ show: false, taskId: null });

      if (viewTaskModal.taskId) {
        setViewTaskModal({ show: true, taskId: viewTaskModal.taskId });
      }
    }
  };

  const handleViewModalClose = ({ action }) => {
    if (action === "addSubTask") {
      setManageTaskModal({
        taskId: viewTaskModal.taskId,
        show: true,
        isSubTask: true,
        projectId: viewTaskModal.projectId,
      });
    }

    if (action === "edit") {
      setManageTaskModal({
        taskId: viewTaskModal.taskId,
        show: true,
        isSubTask: false,
        projectId: viewTaskModal.projectId,
      });
    }

    setViewTaskModal((v) => ({
      show: false,
      taskId: action === "close" ? null : v.taskId,
    }));
  };

  const handleListCb = useCallback(
    ({ action, taskId, projectId, filterData }) => {
      if (action === "viewTask") {
        setViewTaskModal({ show: true, taskId, projectId });
      }

      if (action === "viewProject") {
        NavService.replace(navigate, `/utility/project-management/tasks`, {
          id: projectId,
        });
      }

      if (action === "filter") {
        setFilterData(filterData);
      }
    },
    [navigate]
  );

  const handleStatusChange = (statuses) => {
    setStatuses(statuses);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="row my-3 align-items-end">
        <div className="col-auto">
          <PageInfo
            title="Task Board"
            breadcrumbs={breadcrumbs}
            noMargin={true}
            navigate={navigate}
          />
        </div>
        <Rbac roles={rbac.viewProject}>
          <div className="col-auto fs-val-md ps-0">
            <i className="bi bi-chevron-right text-primary"></i>
            <select
              style={selectInputStyle}
              onChange={handleProjectChange}
              value={selectedProject}
            >
              {projects.map((project) => (
                <option value={project._id} key={project._id}>
                  {project._id != "all" ? `${project._id} - ` : ""}
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </Rbac>

        <div className="col">
          <ProjectTaskStatus callback={handleStatusChange} />
        </div>

        {query.id && query.id != "all" ? (
          <Rbac roles={rbac.createTask}>
            <div className="col-auto">
              <button
                className="btn btn-primary btn-sm"
                onClick={() =>
                  setManageTaskModal({ show: true, projectId: query.id })
                }
              >
                <i className="bi bi-plus me-1"></i> Create Task
              </button>
            </div>
          </Rbac>
        ) : null}

        <div className="col-auto">
          <ProjectTaskNotifications />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-4">
          <ProjectTaskSummary
            projectId={query.id}
            onlyMyTasks={query.taskType === "my"}
            filterData={filterData}
          />

          <ProjectTaskList
            projectId={query.id}
            refreshList={refreshList}
            callback={handleListCb}
            onlyMyTasks={query.taskType === "my"}
            statuses={statuses}
          />
        </div>
      </div>

      <ProjectTaskManageModal
        show={manageTaskModal.show}
        callback={handleModalClose}
        projectId={manageTaskModal.projectId}
        taskId={manageTaskModal.taskId}
        isSubTask={manageTaskModal.isSubTask}
        subTaskId={manageTaskModal.subTaskId}
      />

      <ProjectTaskViewModal
        show={viewTaskModal.show}
        callback={handleViewModalClose}
        taskId={viewTaskModal.taskId}
      />
    </>
  );
};

export default ProjectTasks;
