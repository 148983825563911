import {
  AppCard,
  InputErrorMsg,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const tableHeader = [
  { label: "Sl No", width: "10%" },
  { label: "Recipient", width: "50%" },
  { label: "Actions" },
];
const NotifTempRecipient = () => {
  const {
    register,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [recipientList, setRecipientList] = useState(getValues("to"));
  const [edit, setEdit] = useState({ isEditing: false, index: -1 });

  const submit = async () => {
    const e = await trigger("recipientInput");

    if (!e) {
      return;
    }
    let recipientInput = getValues("recipientInput");
    if (!recipientInput || !recipientInput.trim()) {
      Toaster.error("Please Enter Recipient Name");
      return;
    }
    const list = getValues("to");
    //  if is Editing
    if (edit.isEditing) {
      let newList = list.filter((item, index) => {
        if (index === edit.index) {
          return false;
        }
        return item === recipientInput ? true : false;
      });
      if (!newList.length) {
        list.splice(edit.index, 1, recipientInput);
        setEdit({ isEditing: false, index: -1 });
      } else {
        Toaster.error(recipientInput + " already exists in List ");
      }
    } else {
      let isExist = list.find((item) => item === recipientInput);
      if (!isExist) {
        list.push(recipientInput);
      } else {
        Toaster.error(recipientInput + " already exists in List ");
      }
    }

    setValue("to", list);
    setRecipientList(getValues("to"));
    setValue("recipientInput", "");
  };

  const deleteRecipient = (index) => {
    const list = getValues("to");
    list.splice(index, 1);
    setValue("to", list);
    setRecipientList(getValues("to"));
  };

  const editRecipient = (id) => {
    setEdit({ isEditing: true, index: id });
    const val = recipientList.find((x, i) => id === i);
    setValue("recipientInput", val);
  };

  const onCancel = () => {
    setEdit({ isEditing: false, index: -1 });
    setValue("recipientInput");
  };

  return (
    <>
      <div className=" fs-val-lg fw-semibold mb-4 pt-4">Recipient Details</div>
      <div className="row">
        <div className="col-8">
          <div>
            <TextInput
              label="Add Recipient"
              register={register}
              name="recipientInput"
              type="text"
              error={errors?.recipientInput?.message}
              placeholder="Enter Recipient Here"
              isMandatory={false}
            />
          </div>
          <div>
            <InputErrorMsg msg={errors?.to?.message} />
          </div>
        </div>
        <div className="col-4 pt-4">
          {/* Add Recipient */}
          <div className="mt-1">
            <button
              onClick={submit}
              type="button"
              className={classNames({
                btn: true,
                "btn-primary": !edit.isEditing,
                "btn-success": edit.isEditing,
              })}
            >
              {edit.isEditing ? "Save" : "Add"}
            </button>
            {edit.isEditing && (
              <button
                onClick={onCancel}
                type="button"
                className="btn btn-outline-primary ms-1"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
      {/* Recipient starts */}
      {recipientList.length > 0 && (
        <table className="table table-bordered my-3">
          <TableHeader data={tableHeader} />
          <tbody>
            {recipientList.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item}</td>
                <td>
                  <span
                    className="me-3"
                    role="button"
                    tabIndex={0}
                    onClick={() => editRecipient(index)}
                  >
                    <i className="bi bi-pencil-square text-primary cursor-pointer"></i>
                    <span className=" font-14 text-primary"> Edit</span>
                  </span>
                  <span
                    className="me-5"
                    onClick={() => deleteRecipient(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <i className="bi bi-trash3-fill text-danger cursor-pointer"></i>
                    <span className=" font-14 text-danger">Remove</span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default NotifTempRecipient;
