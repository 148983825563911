import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Id", width: "10%" },
  { label: "Title", width: "30%" },
  { label: "Partner", width: "20%" },
  { label: "Status", width: "15%" },
  { label: "Language", width: "10%" },
  { label: "Created At", width: "10%" },
  { label: "Updated At", width: "10%" },
  { label: "Action", width: "20%" },
];

const rbac = {
  view: ["ViewWhatsappTemplate"],
  edit: ["AddWhatsappTemplate"],
};

const containerStyle = {
  maxHeight: "calc(100vh - 200px)",
  overflow: "auto",
};

function WhatsappTemplateTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) {
  const view = (id) => {
    callback({ action: "view", id });
  };

  const edit = (id) => {
    callback({ action: "edit", id });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar"
        style={containerStyle}
      >
        <table className="table table-sm table-striped">
          <TableHeader data={headers} noBg={true} isSticky={true} />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{x._id}</td>
                <td>{x.name}</td>
                <td>{x.partner}</td>
                <td>
                  <HighlightText
                    type={x.isActive ? "success" : "danger"}
                    template={2}
                  >
                    {x.isActive ? "Active" : "Inactive"}
                  </HighlightText>
                </td>
                <td>{x._languageName || "--"}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>
                <td>
                  <Rbac roles={rbac.edit}>
                    <button
                      className="btn btn-outline-danger text-uppercase btn-sm me-2 fs-val-sm"
                      onClick={() => edit(x._id)}
                    >
                      Edit
                    </button>
                  </Rbac>

                  <Rbac roles={rbac.view}>
                    <button
                      className="btn btn-outline-primary text-uppercase btn-sm me-2 fs-val-sm"
                      onClick={() => view(x._id)}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
}

export default WhatsappTemplateTable;
