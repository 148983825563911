import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Call Logs",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Completed ",
      value: 0,
      loading: true,
      filter: { status: "completed" },
      color: "success",
      img: activeImg,
    },
    {
      label: "No Answer",
      value: 0,
      loading: true,
      filter: { status: "no-answer" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Failure",
      value: 0,
      loading: true,
      filter: { status: "failed" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Inprogress",
      value: 0,
      loading: true,
      filter: { status: "in-progress" },
      color: "danger",
      img: activeImg,
    },
  ],
  //Summary Card Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    reason: {
      label: "Reason",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    employee: {
      label: "Employee",
      valuePath: "[0].value.firstName",
    },
    status: {
      label: "Status",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    reason: "",
    employee: [],
    createdAt: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Employee (From)" },
    { label: "Franchise (To)" },
    { label: "Reason", isCentered: true },
    { label: "Remarks", isCentered: true },
    {
      label: "Created At",
      key: "createdAt",
      enableSort: true,
      isCentered: true,
    },
    { label: "Status", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  reasonOptions: [
    { label: "Select Reason", value: "" },
    { label: "Others", value: "others" },
  ],

  filterFormData: {
    reason: "",
    employee: [],
    createdAt: [],
    status: "",
  },

  statusOptions: [
    { label: "All", value: "" },
    { label: "Completed", value: "completed" },
    { label: "Failed", value: "failed" },
    { label: "No Answer", value: "no-answer" },
    { label: "In progress", value: "in-progress" },
  ],
};

export default listView;
