import { sub } from "date-fns";

export const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Support Call Summary",
  },
];

export const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

export const defaultFilterData = {
  search: "",
  employee: "",
  customer: "",
  status: "",
  dateRange: [sub(new Date(), { months: 4 }), new Date()],
  callAnswered: "",
};

export const defaultFilterLabels = {
  status: {
    label: "Status",
  },
  employee: {
    label: "Employee",
    valuePath: "[0].value.name",
  },
  customer: {
    label: "Customer",
    valuePath: "[0].value.name",
  },
  dateRange: {
    label: "Date Range",
    type: "dateRange",
    canRemove: true,
    resetVal: [],
  },
};

export const defaultSummaryData = [
  {
    label: "Total Calls",
    value: 0,
    color: "primary",
    key: "total",
  },
  {
    label: "Success Calls",
    value: 0,
    color: "success",
    key: "success",
  },
  {
    label: "Failed Calls",
    value: 0,
    color: "danger",
    key: "failed",
  },
  {
    label: "Processing Calls",
    value: 0,
    color: "warning",
    key: "processing",
  },
];

export const statusOptions = [
  { label: "All", value: "" },
  { label: "Success", value: "Success" },
  { label: "Failed", value: "Failed" },
  { label: "Processing", value: "Processing" },
];

export const callAnsweredOptions = [
  { label: "All", value: "" },
  { label: "Answered", value: "Answered" },
  { label: "Not Answered", value: "NotAnswered" },
];
