import { EmployeeService } from "@sk/services";
import { AppCard, Toaster } from "@sk/uis";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvassStyle = { width: "40%" };

const RolesDashMgmtManageModal = ({ show, callback, data, empId }) => {
  const [groups, setGroups] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setGroups(data?.groups || []);
    }
  }, [data, show]);

  const onClose = () => {
    callback({ action: "close" });
  };

  const onSelectGroup = (index) => {
    setGroups(
      produce((draft) => {
        draft[index].checked = !draft[index].checked;
      })
    );
  };

  const onRemoveAll = () => {
    setGroups(
      produce((draft) => {
        draft.forEach((g) => {
          g.checked = false;
        });
      })
    );
  };

  const onSelectAll = () => {
    setGroups(
      produce((draft) => {
        draft.forEach((g) => {
          g.checked = true;
        });
      })
    );
  };

  const onSave = async () => {
    const payload = {
      feature: data._id,
      empId,
      stores: [],
      groups: groups.map((g) => ({
        id: g.label,
        name: g.label,
        enabled: g.checked,
      })),
    };

    setSubmitting(true);

    const resp = await EmployeeService.createEmpRbac(payload);

    setSubmitting(false);

    if (resp.statusCode === 200) {
      Toaster.success(resp.message || "Saved successfully");
      callback({
        action: "save",
        permissions: groups.filter((g) => g.checked).length,
        updatedGroups: [...groups],
      });
    } else {
      Toaster.error(resp.message || "Failed to save");
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement="end"
      style={canvassStyle}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Manage {data?._id} Access</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg position-relative">
        <div className="mb-2 fs-val-md">
          Select the permissions you want to enable for the {data?._id}.
        </div>
        <AppCard>
          <div className="row">
            {groups?.map((g, i) => (
              <div className="col-md-6" key={i}>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`group-${i}`}
                    checked={g.checked}
                    onChange={() => onSelectGroup(i)}
                  />
                  <label
                    className="form-check-label fs-val-md"
                    htmlFor={`group-${i}`}
                  >
                    {g.label}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </AppCard>

        <div className="text-end position-absolute bottom-0 start-0 end-0 p-2 bg-white">
          <button
            className="btn btn-secondary me-2"
            onClick={onSelectAll}
            disabled={submitting}
          >
            Select All
          </button>
          <button
            className="btn btn-danger me-2"
            onClick={onRemoveAll}
            disabled={submitting}
          >
            Remove All
          </button>
          <button
            className="btn btn-primary"
            onClick={onSave}
            disabled={submitting}
          >
            {submitting ? "Saving..." : "Save"}
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RolesDashMgmtManageModal;
