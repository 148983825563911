import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, CronJobService } from "@sk/services";
import {
  AppliedFilterLabel,
  DatePickerInput,
  PageInfo,
  PaginationSummary,
  SelectInput,
  SummaryCard,
  TextInput,
} from "@sk/uis";

import { produce } from "immer";

import debounce from "lodash/debounce";

import merge from "lodash/merge";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import Table from "./components/Table";
import { set } from "date-fns";
import listView from "./constantService";
import ManageJobsModal from "./modals/ManageJobsModal";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Started", value: "Started" },
  { label: "In Progress", value: "In Progress" },
  { label: "Pending", value: "Pending" },
  { label: "Partially Completed", value: "Partially Completed" },
  { label: "Completed", value: "Completed" },
  { label: "Failed", value: "Failed" },
];

const jobTypeOptions = [
  { label: "All", value: "" },
  { label: "Vendors Master", value: "vendors" },
  { label: "Franchises Master", value: "franchises" },
  { label: "Purchase Bills - PO", value: "bills" },
  { label: "Sales Invoices", value: "invoices" },
  { label: "Sales Returns", value: "salesReturns" },
  { label: "Purchase Returns", value: "purchaseReturns" },
  { label: "Franchise Deposits", value: "franchiseDeposits" },
  { label: "Credit Debits", value: "creditDebits" },
];

const getData = async (params) => {
  const r = await CronJobService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await CronJobService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};

  const id = d.id.trim();
  const idRegex = { $regex: id, $options: "gi" };
  if (id) {
    p["filter"]["_id"] = idRegex;
  }

  let status = filter.status;

  p.filter.status = status;

  if (d.jobType) {
    p["filter"]["jobType"] = d.jobType;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (!p.filter.status) {
    delete p.filter.status;
  }

  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const ListCronJobs = () => {
  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To Show Manage Modal

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // For Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  const [manageModal, setManageModal] = useState({ show: false });

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To Store Filter Data
  const filterDataRef = useRef({ ...listView.filterFromData });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  // To Store Manage Modal Data

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  // React Hook From For Handling Filter Form Data
  const { control, register, getValues, reset, setValue } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...listView.pagination };

    loadList();

    loadSummary();

    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [loadList, loadSummary, prepareFilterLabels]);

  // Init
  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Preparing Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // Summary Count card
  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      if (e.key == "total") {
        delete params.filter.status;
      }
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;
      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterCb = useCallback(() => {
    filterDataRef.current = { ...getValues() };
    applyFilter(false);
  }, [applyFilter, getValues]);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const onSummaryCardClick = useCallback(
    (data) => {
      if (!data?.isClickable) {
        return;
      }
      setValue("status", data.filter?.status);
      filterDataRef.current = { ...getValues() };

      applyFilter();
    },
    [applyFilter, setValue, getValues]
  );

  // const onDateChange = useCallback(
  //   (chngFn) => (val) => {
  //     chngFn(val);
  //     filterCb();
  //   },
  //   [filterCb]
  // );

  const resetFilter = useCallback(() => {
    reset(listView.filterFromData);
    filterCb();
  }, [reset, filterCb]);

  const createJobs = () => {
    setManageModal({ show: true });
  };

  const manageModalCallback = useCallback((d) => {
    if (d.action == "submit") {
      init();
    }
    setManageModal({ show: false });
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Zoho Sync Jobs"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <div className="row align-self-center mb-3">
        {/* Filter  Block  */}
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-2">
                <TextInput
                  name="id"
                  type="text"
                  label="Search For Job ID"
                  register={register}
                  placeholder="Search By Job ID"
                />
              </div>

              <div className="col-2">
                <SelectInput
                  name="status"
                  register={register}
                  label="Job Status"
                  options={statusOptions}
                />
              </div>

              <div className="col-2">
                <SelectInput
                  name="jobType"
                  register={register}
                  label="Job Type"
                  options={jobTypeOptions}
                />
              </div>
              <div className="col-3">
                <label className="mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
              <div className="col-auto ms-5 align-self-center mt-2">
                <button
                  className="btn btn-primary fs-val-md me-2 "
                  onClick={filterCb}
                >
                  Apply
                </button>
                <button
                  className="btn btn-outline-warning fs-val-md "
                  onClick={resetFilter}
                >
                  Reset
                </button>
              </div>
              <div className="col-auto ms-auto">
                <button
                  className="btn btn-primary fs-val-md mt-4"
                  onClick={createJobs}
                >
                  Create Job
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Summary card */}
        {summaryCard.map((e, k) => (
          <div className="col-auto" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              isClickable={e.isClickable}
              onSummaryCardClick={onSummaryCardClick}
              data={e}
              template={3}
            />
          </div>
        ))}
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* Table */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <Table
        data={data}
        sort={sortRef.current}
        sortCb={sortCb}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      {/* CReation */}
      <ManageJobsModal show={manageModal.show} callback={manageModalCallback} />
    </>
  );
};

export default memo(ListCronJobs);
