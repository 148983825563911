import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFetchUrlQueryString } from "@sk/hooks";
import { AjaxService, NavService, NotificationService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formView } from "../constantService";
import NotifTempBasicForm from "./components/form/NotifTempBasicForm";
import NotifTempMessageContent from "./components/form/NotifTempMessageContent";
import NotifTempRecipient from "./components/form/NotifTempRecipient";

import { Alert } from "@sk/uis";
import validationSchema from "./NotifTempManageValidationSchema";
import NotifTempPartnerDetailsForm from "./components/form/NotifTempPartnerDetailsForm";

const preparePayload = (d) => {
  let p = {
    businessEntity: d.businessEntity,
    isActive: d.isActive,
    mode: d.mode,
    name: d.name,
    default: {
      body: d.body,
    },
  };

  if (["WhatsApp", "SMS"].indexOf(d.mode) != -1 && d?.partner?.length) {
    p.partner = d.partner;
  }

  if (d.mode == "EMail" && d?.subject?.length) {
    p.default.subject = d.subject;
    p.emailTemplateType = d.emailTemplateType;
  }

  if (d.mode == "InApp") {
    if (d?.title?.length) {
      p.default.title = d.title;
    }
    p.type = d.type;
    p.subType = d.subType;
  }

  if (d.mode == "WhatsApp") {
    p.default.footer = d.footer;
    p.default.header = d.header;
    p.partnerConfig = {
      id: d.partnerConfigId,
      webhookCode: d.partnerConfigWebHookCode,
    };
  }

  if (d.mode == "PUSH") {
    p.default.style = d.style;
    p.default.ledColor = d.led;
    p.default.summaryText = d.summaryText;
    p.default.style = d.style;
    p.default.url = d.url;
    p.default.urlData = {
      id: d.urlId,
      type: d.urlType,
    };
    p.default.actions = d.actions;
    if (d?.imageType?.length) {
      p.default.picture = d.imageType == "link" ? d.link : d.image;
    }
    if (d?.title?.length) {
      p.default.title = d.title;
    }
  }

  if (d?.to?.length) {
    p.to = d.to;
  }

  return p;
};

const NotifTempForm = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formView.defaultFormValues,
  });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [breadcrumbs, setBreadcrumbs] = useState([...formView.breadcrumb]);

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [display, setDisplay] = useState("loading");

  const [title, setTitle] = useState("Add Notification Template");

  useEffect(() => {
    if (query.id) {
      loadTemplateDetails(query.id);
      setTitle("Edit Notification Template #" + query.id);
      setBreadcrumbs((prev) => {
        prev[prev.length - 1].name = "Edit Notification Template";
        return prev;
      });
    } else {
      setDisplay("form");
      setBreadcrumbs((prev) => {
        prev[prev.length - 1].name = "Add Notification Template";
        return prev;
      });
    }

    // Partner Options for SMS Mode
    // loadPartnerOptions();
  }, [query.id, loadTemplateDetails]);

  function isHSL(color) {
    return /^hsl\(\d+,\s*\d+\%,\s*\d+\%\)$/i.test(color);
  }

  const loadTemplateDetails = useCallback(
    async (id) => {
      setDisplay("loading");

      let m = await NotificationService.getDetailsById(id);

      if (m?.resp?._id) {
        const r = m?.resp || {};

        methods.setValue("to", r.to?.length ? r.to : []); // saving recipients list

        methods.setValue(
          "languages",
          (r.languages || []).map((e) => {
            return { label: e, value: e };
          })
        );

        methods.setValue("body", r?.default?.body || "");
        methods.setValue("subject", r?.default?.subject || "");
        methods.setValue("title", r?.default?.title || "");
        methods.setValue("header", r?.default?.header || "");
        methods.setValue("footer", r?.default?.footer || "");
        methods.setValue("style", r?.default?.style || "");
        methods.setValue("led", hslToHex(r?.default?.ledColor || ""));
        methods.setValue("summaryText", r?.default?.summaryText || "");
        methods.setValue("urlId", r?.default?.urlData?.id || "");
        methods.setValue("urlType", r?.default?.urlData?.type || "");
        methods.setValue("url", r?.default?.url || "");
        methods.setValue("actions", r?.default?.actions.slice(0, 3) || "");
        methods.setValue("partnerConfigId", r?.partnerConfig?.id || "");
        methods.setValue(
          "partnerConfigWebHookCode",
          r?.partnerConfig?.webhookCode || ""
        );

        if (r?.default?.picture?.length) {
          let type = isNaN(r?.default?.picture) ? "link" : "upload";
          methods.setValue("imageType", type);
          type == "link"
            ? methods.setValue("link", r?.default?.picture)
            : methods.setValue("image", r?.default?.picture);
        }

        const formKeys = [
          "businessEntity",
          "name",
          "isActive",
          "subType",
          "type",
          "emailTemplateType",
          "classClassifications",
          "mode",
          "partner",
        ];

        Object.keys(r).forEach((key) => {
          if (formKeys.indexOf(key) != -1) {
            if (key === "images") {
              methods.setValue(
                key,
                r[key].map((img) => ({ asset: img }))
              );
            } else {
              methods.setValue(key, r[key]);
            }
          }
        });

        setDisplay("form");
      } else {
        setDisplay("notFound");
      }
    },
    [hslToHex, methods]
  );

  const hslToHex = useCallback((hsl) => {
    if (!isHSL(hsl)) {
      return hsl;
    }

    const regex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/;
    const match = hsl.match(regex);

    if (!match) {
      return hsl;
    }

    const h = parseInt(match[1]) * 1;
    const s = (parseInt(match[2]) * 1) / 100;
    const l = (parseInt(match[3]) * 1) / 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;

    return "#" + r + g + b;
  }, []);

  const onSubmit = async (data) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want submit this Notification Template Form",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setBusyLoader({
      loading: true,
    });
    const params = preparePayload(data);
    let r;
    let msg = "";
    if (query.id) {
      r = await NotificationService.updateTemplate(query.id, params);
      msg = "Product Updated Successfully";
    } else {
      msg = "Product Added Successfully";
      r = await NotificationService.createTemplate(params);
    }

    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success(msg);
      NavService.to(navigate, "/utility/notifications/list");
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  return (
    <>
      {display === "loading" && <PageLoader />}

      {display === "form" && (
        <>
          <PageInfo
            title={title}
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
          <FormProvider {...methods}>
            <AppCard>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="row">
                  {/* Basic Details */}
                  <div className="col-12 ">
                    <NotifTempBasicForm />
                  </div>
                  <div className="col-7 pe-4">
                    <NotifTempMessageContent />
                    <NotifTempPartnerDetailsForm />
                  </div>
                  <div className="col-5 ps-5">
                    <NotifTempRecipient />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="text-end py-3">
                  <button className="btn btn-lg btn-primary">Submit</button>
                </div>
              </form>
            </AppCard>
          </FormProvider>
        </>
      )}
      {display === "notFound" && <NoDataFound> No Data Found </NoDataFound>}
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default NotifTempForm;
