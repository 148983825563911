import { produce } from "immer";
import { useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

const ProjectTaskStatus = ({ callback }) => {
  const [statuses, setStatuses] = useState([
    {
      value: "all",
      label: "All Tasks",
      checked: false,
    },
    {
      value: "inProgress",
      label: "Pending Tasks",
      checked: true,
    },
    {
      value: "blocked",
      label: "Blocked/Deferred Tasks",
      checked: false,
    },
  ]);

  const handleCheckboxChange = (value) => {
    const newStatuses = produce(statuses, (draft) => {
      draft.forEach((status) => {
        status.checked = status.value === value;
      });
    });

    setStatuses(newStatuses);

    callback(newStatuses.filter((x) => x.checked).map((x) => x.value));
  };

  return (
    <ButtonGroup size="sm">
      {statuses.map((status) => (
        <ToggleButton
          key={status.value}
          id={`radio-${status.value}`}
          type="radio"
          variant="outline-secondary"
          name="task-status"
          checked={status.checked}
          value={status.value}
          onChange={() => handleCheckboxChange(status.value)}
        >
          {status.checked && <i className="bi bi-check me-1"></i>}
          {status.label}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default ProjectTaskStatus;
