import { NotificationService } from "@sk/services";
import { AppCard, AppTitle, NoDataFound, PageLoader } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "35%" };

const NotifTempViewModal = ({ show, callback, id }) => {
  const [data, setData] = useState({});

  const [display, setDisplay] = useState("loading");

  useEffect(() => {
    if (show && id && id.length) {
      loadDetails();
    } else {
      setData({});
      setDisplay("noDataFound");
    }
  }, [id, loadDetails, show]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await NotificationService.getDetailsById(id);
    setData(r?.resp ? r?.resp : {});
    if (r?.resp?._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [id]);

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Notification Template Details #" + id} />
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4 border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <>
              <AppCard title="Body">
                <div>{data?.default?.body}</div>
              </AppCard>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(NotifTempViewModal);
