import { appConfigs } from "@sk/services";
import {
  AppCard,
  FileUpload2,
  ImgRender,
  RadioInput,
  TextInput,
} from "@sk/uis";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const imgStyle = {
  width: "100px",
  height: "100px",
  objectFit: "cover",
};

const btnStyle = {
  height: "20px",
  width: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const mediaOptions = [
  { value: "none", label: "None" },
  { value: "text", label: "Text" },
  { value: "image", label: "Image" },
];

const WhatsAppTemplateHeader = () => {
  const { control, register, setValue } = useFormContext();

  const [mediaType, headerImage] = useWatch({
    control,
    name: ["mediaType", "headerImage"],
  });

  const onFileUpload = (file) => {
    if (file?.id) {
      setValue("headerImage", file.id);
    }
  };

  const onTypeChange = (onChange) => (e) => {
    onChange(e);
    // setValue("headerText", "");
    setValue("headerImage", "");
  };

  return (
    <AppCard title="Header (Optional)">
      <div className="fs-val-md text-secondary mb-2">
        Add a title, or, select the media type you want to get approved for this
        template&apos;s header
      </div>
      <Controller
        name="mediaType"
        control={control}
        render={({ field }) => (
          <RadioInput
            label="Media Type"
            options={mediaOptions}
            onChange={onTypeChange(field.onChange)}
            value={field.value}
            cols="3"
          />
        )}
      />

      {mediaType == "text" && (
        <div>
          <TextInput
            name="headerText"
            label="Header Text"
            register={register}
          />

          <TextInput
            name="headerDynamicSample"
            label="Sample values for the dynamic values. For example, if {{count}} and {{age}} are used in the message, then provide 10,18 in the input"
            register={register}
            placeholder={"e.g., 10,20"}
          />
        </div>
      )}

      {mediaType == "image" && (
        <>
          {headerImage ? (
            <div
              style={imgStyle}
              className="border border-1 border-secondary rounded position-relative overflow-hidden"
            >
              <button
                className="top-0 end-0 position-absolute btn btn-danger p-0 rounded-circle fs-val-lg"
                style={btnStyle}
                onClick={() => setValue("headerImage", null)}
              >
                <i className="bi bi-x"></i>
              </button>
              <ImgRender assetId={headerImage} isAsset={true} />
            </div>
          ) : (
            <FileUpload2
              callback={onFileUpload}
              accept="image/*"
              url={appConfigs.ASSET_UPLOAD}
            >
              <button className="btn btn-primary">Upload Image</button>
            </FileUpload2>
          )}
        </>
      )}
    </AppCard>
  );
};

export default WhatsAppTemplateHeader;
