import { AuthService, CommonService, ProjectService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { add, endOfDay, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import ProjectTaskListFilter from "./ProjectTaskListFilter";

const headers = [
  { label: "#", key: "id", width: "3%" },
  {
    label: "Project",
    key: "projectId",
    width: "8%",
    enableSort: true,
  },
  { label: "Title", key: "name", width: "25%", enableSort: true },
  {
    label: "Assigned To",
    key: "assignedTo.name",
    width: "10%",
    enableSort: true,
  },
  { label: "Status", key: "status", width: "8%", enableSort: true },
  { label: "Start Date", key: "startDate", width: "11%", enableSort: true },
  { label: "End Date", key: "endDate", width: "11%", enableSort: true },
  { label: "Due Date", key: "dueDate", width: "11%", enableSort: true },
];

const defaultFilterLabels = {
  onlyMyTasks: {
    label: "Only My Tasks",
  },
  startDate: {
    label: "Start Date",
    type: "date",
  },
  endDate: {
    label: "End Date",
    type: "date",
  },
  dueDate: {
    label: "Due Date",
    type: "date",
  },
};

const defaultPaginationConfig = {
  totalRecords: 0, // Add totalRecords to track total tasks
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (projectId, params) => {
  let prjId = projectId;
  if (projectId === "all") {
    prjId = "";
  }
  const r = await ProjectService.getTasksByProjectId(prjId, params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  const projectIds = d.map((task) => task.projectId);
  const projectDetailsResponse = await ProjectService.getList({
    filter: {
      _id: { $in: projectIds },
    },
  });

  const projectDetails = Array.isArray(projectDetailsResponse.resp)
    ? projectDetailsResponse.resp
    : [];

  // Attach project details to each task
  const tasksWithProjectDetails = d.map((task) => {
    const projectDetail = projectDetails.find(
      (project) => project._id === task.projectId
    );
    return {
      ...task,
      projectDetail: projectDetail || null,
    };
  });

  return { data: tasksWithProjectDetails };
};

const getCount = async (projectId, params) => {
  const p = { ...params, outputType: "count" };
  let prjId = projectId;
  if (projectId === "all") {
    prjId = "";
  }
  const r = await ProjectService.getTasksByProjectId(prjId, p);
  return {
    count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { projectId: filter.projectId },
  };

  if (filter?.projectId === "all") {
    delete p.filter.projectId;
  }

  const s = (filter?.search || "").trim();

  if (s) {
    p.filter.$or = [{ _id: s }, { title: { $regex: s, $options: "gi" } }];
  }

  if (filter?.employee?.length) {
    p.filter["assignedTo.id"] = filter.employee[0].value._id;
  }

  if (filter?.onlyMyTasks) {
    p.filter["assignedTo.id"] = AuthService.getLoggedInEmpId();
  }

  if (filter?.statuses?.length) {
    const groupFilters = ProjectService.getTaskStatusesGroupFilters(
      filter.statuses
    );
    if (groupFilters.length > 0) {
      p.filter.status = { $in: groupFilters };
    }
  }

  if (filter?.status) {
    const status = filter.status;
    p.filter.status = status;
  }

  if (filter?.priority) {
    const priority = filter.priority;
    p.filter.priority = priority;
  }

  if (filter?.taskType) {
    const taskType = filter.taskType;
    p.filter.type = taskType;
  }

  if (filter?.taskMode) {
    const todayStart = startOfDay(new Date());
    const todayEnd = endOfDay(new Date());
    const yesterdayStart = startOfDay(sub(new Date(), { days: 1 }));
    const yesterdayEnd = endOfDay(sub(new Date(), { days: 1 }));

    if (filter.taskMode === "today") {
      p.filter.startDate = { $gte: todayStart, $lte: todayEnd };
      p.filter.endDate = { $gte: todayStart, $lte: todayEnd };
    } else if (filter.taskMode === "yesterday") {
      p.filter.endDate = { $gte: yesterdayStart, $lte: yesterdayEnd };
    } else if (filter.taskMode === "delayed") {
      p.filter.dueDate = { $lte: startOfDay(new Date()) };
      p.filter.status = { $ne: "Completed" };
    } else if (filter.taskMode === "tomorrow") {
      const tomorrowStart = startOfDay(add(new Date(), { days: 1 }));
      const tomorrowEnd = endOfDay(add(new Date(), { days: 1 }));
      p.filter.endDate = { $gte: tomorrowStart, $lte: tomorrowEnd };
    }
  }

  if (filter?.startDate?.length) {
    p.filter.startDate = {
      $gte: startOfDay(new Date(filter.startDate[0])),
      $lte: endOfDay(new Date(filter.startDate[0])),
    };
  }

  if (filter?.endDate?.length) {
    p.filter.endDate = {
      $gte: startOfDay(new Date(filter.endDate[0])),
      $lte: endOfDay(new Date(filter.endDate[0])),
    };
  }

  if (filter?.dueDate?.length) {
    p.filter.dueDate = {
      $gte: startOfDay(new Date(filter.dueDate[0])),
      $lte: endOfDay(new Date(filter.dueDate[0])),
    };
  }

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value === "asc" ? 1 : -1 };
  }

  if (filter?.isDownload) {
    p.outputType = "download";
  }

  if (!Object.keys(filter).length) {
    delete p.filter;
  }
  return p;
};

const ProjectTaskList = ({
  projectId,
  refreshList,
  callback,
  onlyMyTasks,
  statuses,
}) => {
  const [tasks, setTasks] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterRef = useRef({});
  const paginationConfig = useRef({ ...defaultPaginationConfig });
  const sortRef = useRef({ key: "dueDate", value: "asc" });

  useEffect(() => {
    if (refreshList) {
      applyFilter();
    }
  }, [applyFilter, refreshList]);

  const applyFilter = useCallback(async () => {
    paginationConfig.current = { ...defaultPaginationConfig };

    prepareFilterLabels();

    loadList();

    const params = prepareFilterParams(filterRef.current, {}, sortRef.current);

    callback({ filterData: params, action: "filter" });

    setLoadingTotalRecords(true);
    const count = await getCount(filterRef.current.projectId, params);
    paginationConfig.current.totalRecords = count.count;
    setLoadingTotalRecords(false);
  }, [loadList, callback]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setTasks([]);
    const params = prepareFilterParams(
      filterRef.current,
      paginationConfig.current,
      sortRef.current
    );
    const { data } = await getData(filterRef.current.projectId, params);

    setTasks(data);
    setLoadingData(false);
  }, []);

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      projectId,
      onlyMyTasks,
      statuses,
    };

    if (statuses?.length) {
      filterRef.current.status = "";
    } else {
      filterRef.current.statuses = ["inProgress"];
    }

    applyFilter();
  }, [applyFilter, projectId, onlyMyTasks, statuses]);

  const prepareFilterLabels = () => {
    const v = { ...filterRef.current };

    // Prepare applied filter labels
    let allLabels = CommonService.prepareAppliedFilterLabels(
      defaultFilterLabels,
      v
    );

    if (!v.onlyMyTasks) {
      allLabels = allLabels.filter((label) => label.key !== "onlyMyTasks");
    }

    // Filter labels to only include those defined in defaultFilterLabels
    const filteredLabels = allLabels.filter(
      (label) => Object.keys(defaultFilterLabels).includes(label.key) // Assuming each label has a 'key' property
    );

    setFilterLabels(filteredLabels);
  };

  const filterCb = useCallback(
    async (data) => {
      if (data.action === "download") {
        const params = prepareFilterParams(
          { ...filterRef.current, isDownload: true },
          {},
          sortRef.current
        );
        const r = await getData(filterRef.current.projectId, params);
        return;
      }

      filterRef.current = { ...filterRef.current, ...data.formData };
      applyFilter();
    },
    [applyFilter]
  );

  const paginationCb = useCallback(
    (data) => {
      paginationConfig.current.startSlNo = data.startSlNo;
      paginationConfig.current.endSlNo = data.endSlNo;
      paginationConfig.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const viewTask = (taskId, projectId) => {
    callback({ taskId, action: "viewTask", projectId });
  };

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { key: data.key, value: data.value };
      applyFilter();
    },
    [applyFilter]
  );

  const viewProject = (event, projectId) => {
    event.stopPropagation();
    callback({ projectId, action: "viewProject" });
  };

  return (
    <>
      <AppCard>
        <div className="mb-2">
          <ProjectTaskListFilter
            callback={filterCb}
            hideEmpSearch={filterRef.current.onlyMyTasks}
            statuses={filterRef.current.statuses}
          />
        </div>
        {loadingData && tasks.length === 0 ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="d-flex align-items-center mb-1">
              <PaginationSummary
                paginationConfig={paginationConfig.current}
                loadingTotalRecords={loadingData}
                className="me-2"
              />

              <AppliedFilterLabel labels={filterLabels} />
            </div>

            <table className="table bg-white table-sm table-hover table-striped">
              <TableHeader
                data={headers}
                noBg={true}
                sort={sortRef.current}
                sortCb={sortCb}
              />
              <tbody>
                {!loadingData && !tasks.length ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <NoDataFound>
                        <span className="fs-val-md">No Data found</span>
                      </NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {loadingData && tasks.length > 0 ? (
                  <TableSkeletonLoader
                    cols={headers.length}
                    rows={10}
                    height={40}
                  />
                ) : null}

                {tasks.map((task, index) => (
                  <tr
                    key={task._id}
                    className="fs-val-md cursor-pointer"
                    onClick={() => viewTask(task._id, task.projectId)}
                  >
                    <td>
                      <div
                        className={`border-start border-${task.priorityBadge} border-4 ps-2`}
                      >
                        {paginationConfig.current.startSlNo + index}
                      </div>
                    </td>

                    <td>
                      <button
                        className="btn btn-sm btn-link text-dark fs-val-md p-0"
                        onClick={(e) => viewProject(e, task.projectId)}
                      >
                        {task.projectId}
                      </button>
                      <InfoPopover
                        content={
                          <div className="fs-val-sm">
                            Project Name: {task.projectDetail?.name}
                          </div>
                        }
                        placement="top"
                      />
                    </td>

                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          className={`rounded-circle me-2 bg-${
                            task.type === "Task" ? "success" : "danger"
                          }`}
                          style={{
                            width: "5px",
                            height: "5px",
                          }}
                        ></div>
                        <div
                          className="text-truncate d-inline-block align-middle"
                          style={{
                            maxWidth: "500px",
                          }}
                        >
                          {task.title}
                        </div>
                        <InfoPopover
                          header={task.name}
                          content={task.title}
                          placement="top"
                          className="d-inline-block align-middle"
                        />
                      </div>
                    </td>

                    <td>{task.assignedTo?.name || "--"}</td>

                    <td>
                      <HighlightText
                        status={task.status}
                        isBadge={true}
                        size="xs"
                        template={2}
                        type={task.statusType}
                      >
                        {task.status}
                      </HighlightText>
                    </td>

                    <td>
                      <DateFormatter
                        date={task.startDate}
                        format="dd MMM yyyy"
                      />
                      <span className="fs-val-xs ps-1">
                        <DateFormatter date={task.startDate} format="hh:mm a" />
                      </span>
                    </td>

                    <td>
                      <DateFormatter date={task.endDate} format="dd MMM yyyy" />
                      <span className="fs-val-xs ps-1">
                        <DateFormatter date={task.endDate} format="hh:mm a" />
                      </span>
                    </td>

                    <td>
                      <DateFormatter date={task.dueDate} format="dd MMM yyyy" />
                      <span className="fs-val-xs ps-1">
                        <DateFormatter date={task.dueDate} format="hh:mm a" />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="text-end mt-3">
              <PaginationBlock
                paginationConfig={paginationConfig.current}
                loadingTotalRecords={loadingTotalRecords}
                paginationCb={paginationCb}
                size="sm"
              />
            </div>
          </>
        )}
      </AppCard>
    </>
  );
};

export default ProjectTaskList;
