import { WhatsAppTemplatePreview } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import { NavService, NotificationService } from "@sk/services";
import {
  PageLoader,
  AppCard,
  PageInfo,
  KeyVal,
  TableHeader,
  InfoBlk,
  BusyLoader,
  ImgRender,
  HighlightText,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "WhatsApp Templates",
    link: "/utility/whatsapp-template",
  },
  {
    name: "View Template",
  },
];

const headers = [
  { label: "Action Type", key: "actionType" },
  { label: "Configs", key: "configs" },
  { label: "Action", key: "action" },
];

const previewStyle = {
  top: "100px",
};

const imgStyle = {
  width: "100px",
  height: "100px",
};

const WhatsappView = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [busyloader, setBusyloader] = useState({ show: false });

  const init = useCallback(async () => {
    setLoading(true);
    try {
      const r = await NotificationService.getList({
        filter: {
          _id: query.id,
        },
      });
      setData(r.resp?.[0] || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [query.id]);

  useEffect(() => {
    init();
  }, [init]);

  const onCreateTemplate = async () => {
    setBusyloader({ show: true });
    const response = await NotificationService.syncPartnerTemplate(data);
    setBusyloader({ show: false });

    if (response?.statusCode === 200) {
      Toaster.success("Template created successfully");
      init();
    } else {
      Toaster.error(response?.resp?.message || "Failed to create template");
    }
  };

  const onEditTemplate = () => {
    NavService.to(navigate, "/utility/whatsapp-template/manage", {
      id: data?._id,
    });
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="row my-3 align-items-center">
        <div className="col">
          <PageInfo
            title={`View WhatsApp Template - ${data?._id}`}
            navigate={navigate}
            breadcrumbs={breadcrumbs}
            noMargin={true}
          />
        </div>
        <div className="col-auto">
          <button className="btn btn-success" onClick={onEditTemplate}>
            <i className="bi bi-pencil me-2" />
            Edit Template
          </button>
        </div>
      </div>

      {data?._canCreateInPartner && !data?._createdInPartner ? (
        <div className="mb-3">
          <InfoBlk variant="danger">
            <div className="row align-items-center">
              <div className="col-auto fw-semibold">
                <i className="bi bi-exclamation-triangle me-2" />
                This template has not been created in the partner.
              </div>
              <div className="col">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={onCreateTemplate}
                >
                  <i className="bi bi-plus me-2" />
                  Create in partner
                </button>
              </div>
            </div>
          </InfoBlk>
        </div>
      ) : null}

      {data ? (
        <>
          <AppCard>
            <div className="row">
              <div className="col-3">
                <KeyVal label="Title" template="col">
                  {data.name}
                </KeyVal>
              </div>
              <div className="col-3">
                <KeyVal label="Language" template="col">
                  {data._languageName || "--"}
                </KeyVal>
              </div>
              <div className="col-3">
                <KeyVal label="Partner" template="col">
                  {data.partner || "--"}
                </KeyVal>
              </div>
              <div className="col-3">
                <KeyVal label="Status" template="col">
                  <HighlightText
                    type={data.isActive ? "success" : "danger"}
                    template={2}
                  >
                    {data.isActive ? "Active" : "Inactive"}
                  </HighlightText>
                </KeyVal>
              </div>
            </div>
          </AppCard>

          <div className="row">
            <div className="col">
              <AppCard title="Header">
                {data._headerAsset ? (
                  <div
                    className="border rounded overflow-hidden"
                    style={imgStyle}
                  >
                    <ImgRender assetId={data._headerAsset} />
                  </div>
                ) : (
                  data.default?.header || "--"
                )}
              </AppCard>
              <AppCard title="Body">{data.default?.body || "--"}</AppCard>
              <AppCard title="Footer">{data.default?.footer || "--"}</AppCard>
              <AppCard title="Copy Code, URL, Quick Replies">
                <table className="table table-sm">
                  <TableHeader data={headers} noBg={true} />
                  <tbody className="fs-val-md">
                    {!data.default.buttons?.length ? (
                      <tr>
                        <td colSpan={headers.length} className="text-center">
                          <div className="py-2 fs-val-md">No actions found</div>
                        </td>
                      </tr>
                    ) : null}

                    {data.default.buttons?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AppCard>
            </div>
            <div
              className="col-auto align-self-start position-sticky"
              style={previewStyle}
            >
              <AppCard>
                <WhatsAppTemplatePreview
                  headerText={
                    data.default?.headerType === "text"
                      ? data.default?.header
                      : null
                  }
                  body={data.default?.body}
                  footer={data.default?.footer}
                  actions={data.default?.buttons}
                  headerImage={
                    data.default?.headerType === "image"
                      ? data._headerAsset
                      : null
                  }
                />
              </AppCard>
            </div>
          </div>
        </>
      ) : (
        <div>No template data found.</div>
      )}

      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default WhatsappView;
