import { StoresModal } from "@sk/features";
import { EmployeeService, UserService } from "@sk/services";
import {
  BusyLoader,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";

const defaultDashboards = [
  { label: "Smartcoins", feature: "SCDashboard" },
  { label: "Store Inventory", feature: "DarkStoreInventoryDashboard" },
  { label: "Store Sales", feature: "DarkStoreSalesDashboard" },
];

const headers = [
  {
    label: "Dashboard",
    key: "dashboard",
    width: "20%",
  },
  {
    label: "Description",
    key: "description",
    width: "30%",
  },
  {
    label: "Access",
    key: "access",
    width: "10%",
  },
  {
    label: "Stores",
    key: "stores",
    width: "15%",
  },
  // {
  //   label: "Action",
  //   key: "action",
  //   width: "5%",
  // },
];

const selectStyle = {
  border: "none",
  borderBottom: "1px solid #ced4da",
  boxShadow: "none",
  outline: "none",
  backgroundColor: "transparent",
};

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const getData = async (params) => {
  const res = await UserService.getGroupsList(params);
  return {
    data: Array.isArray(res.resp) ? res.resp : [],
  };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const res = await UserService.getGroupsListCount(p);
  return {
    count: Array.isArray(res.resp) && res.resp.length ? res.resp[0]?.total : 0,
  };
};

const prepareFilter = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      type: "Dashboard",
    },
  };
  return p;
};

const RoleDashAccess = ({ empId }) => {
  const [storesModal, setStoresModal] = useState({
    show: false,
    ids: [],
    index: -1,
  });

  const [loading, setLoading] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false });

  const [dashboards, setDashboards] = useState([]);

  const filterDataRef = useRef({});
  const paginationRef = useRef(defaultPagination);
  const empAccessRef = useRef({});

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;
    loadList();
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);

    setDashboards([]);

    const resp = await getData(
      prepareFilter(filterDataRef.current, paginationRef.current)
    );

    const updatedDashboards = resp.data.map((dashboard) => {
      const groups = empAccessRef.current[dashboard.feature]?.groups || [];
      const group = groups.find((g) => g.id === dashboard._id);
      const stores = group?.stores || [];
      return {
        ...dashboard,
        enabled: group?.enabled,
        stores,
        formData: {
          stores,
          access: group?.enabled ? "enabled" : "disabled",
        },
      };
    });

    setDashboards(updatedDashboards);

    setLoading(false);
  }, []);

  const init = useCallback(() => {
    const fetchData = async () => {
      setLoading(true);
      setDashboards([]);

      const resp = await EmployeeService.getEmpRbacGroup(empId);

      if (Array.isArray(resp.resp)) {
        empAccessRef.current = resp.resp.reduce((acc, curr) => {
          acc[curr.feature] = curr;
          return acc;
        }, {});
      } else {
        empAccessRef.current = {};
      }

      applyFilter();
    };

    if (empId) {
      fetchData();
    }
  }, [applyFilter, empId]);

  useEffect(() => {
    init();
  }, [init]);

  // useEffect(() => {
  //   if (empId) {
  //     const fetchData = async () => {
  //       setLoading(true);

  //       const resp = await EmployeeService.getEmpRbacGroup(empId, {
  //         filter: {
  //           feature: {
  //             $in: defaultDashboards.map((d) => d.feature),
  //           },
  //         },
  //       });
  //       setDashboards(
  //         produce((draft) => {
  //           draft.forEach((d) => {
  //             const { groups = [], stores = [] } =
  //               resp.resp?.find((r) => r.feature === d.feature) || {};
  //             const found = groups.find((r) => r.id === d.feature);
  //             d.formData.access = found?.enabled ? "enabled" : "disabled";
  //             d.formData.stores = stores;
  //             d.enabled = d?.formData?.access === "enabled";
  //             d.stores = stores;
  //           });
  //         })
  //       );
  //       setLoading(false);
  //     };

  //     fetchData();
  //   }
  // }, [empId]);

  const onStoresChange = (e) => {
    if (e?.action === "selectedStore") {
      setDashboards(
        produce(dashboards, (draft) => {
          draft[storesModal.index].formData.stores = e?.store?.map((s) => ({
            id: s._id,
            name: s.name,
          }));
        })
      );
    }
    setStoresModal({ show: false, ids: [], index: -1 });
  };

  const onShowStoresModal = (index) => {
    const stores = dashboards[index].formData.stores || [];
    setStoresModal({ show: true, ids: stores.map((s) => s.id), index });
  };

  const onAccessChange = (index, value) => {
    setDashboards(
      produce(dashboards, (draft) => {
        draft[index].formData.access = value;
      })
    );
  };

  const onSaveAll = async () => {
    let msg = "";

    const noStores = dashboards.find(
      (d) => d.formData?.access === "enabled" && !d.formData?.stores?.length
    );

    console.log(noStores, dashboards);

    if (noStores) {
      msg = "Please assign stores to all enabled dashboards";
      Toaster.error(msg);
      return;
    }

    const groups = produce(dashboards, (draft) => {
      draft.forEach((d) => {
        d.enabled = d.formData.access === "enabled";
        d.stores = d.formData.stores;
      });
    });

    const feature = "Dashboard";
    const payload = {
      feature,
      empId: empId,
      groups: groups.map((g) => ({
        id: g._id,
        name: g.name,
        enabled: g.enabled || false,
        stores: g.stores || [],
      })),
    };

    setBusyLoader({ show: true });
    const resp = await EmployeeService.createEmpRbac(payload);
    setBusyLoader({ show: false });

    if (resp.statusCode === 200) {
      Toaster.success(resp.message || "Saved successfully");
      setDashboards(
        produce((draft) => {
          draft.forEach((d) => {
            d.enabled = d.formData.access === "enabled";
            d.stores = d.formData.stores;
          });
        })
      );
    } else {
      Toaster.error(resp.message || "Failed to save");
    }
  };

  return (
    <>
      <table className="table table-sm">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {loading && <TableSkeletonLoader cols={headers.length} rows={10} />}

          {!loading && dashboards.length === 0 && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                <NoDataFound>No dashboards found</NoDataFound>
              </td>
            </tr>
          )}

          {dashboards.map((d, index) => (
            <tr key={index}>
              <td>
                {d.enabled ? (
                  <i className="bi bi-check-circle-fill me-1 text-success"></i>
                ) : (
                  <i className="bi bi-x-circle-fill me-1 text-danger"></i>
                )}
                {d.name}
              </td>
              <td className="text-muted fs-val-sm">{d.description}</td>
              <td>
                <select
                  style={selectStyle}
                  className="fs-val-md ms-n1"
                  value={d.formData.access}
                  onChange={(e) => {
                    onAccessChange(index, e.target.value);
                  }}
                >
                  <option value="enabled">Enabled</option>
                  <option value="disabled">Disabled</option>
                </select>
              </td>
              <td>
                <button
                  className={classNames("btn btn-sm btn-link fs-val-md p-0", {
                    "text-dark": d.formData.stores?.length > 0,
                    "text-muted": d.formData.stores?.length === 0,
                  })}
                  onClick={() => onShowStoresModal(index)}
                >
                  {d.formData.stores?.length > 0 ? (
                    <>Assigned Stores ({d.formData.stores?.length || 0}) </>
                  ) : (
                    <>Choose Stores</>
                  )}

                  <i className="bi bi-chevron-right ms-1"></i>
                </button>
              </td>
              {/* <td>
                <button
                  className="btn btn-sm btn-success fs-val-sm"
                  onClick={() => onSave(index)}
                >
                  Save
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-end">
        <button onClick={onSaveAll} className="btn btn-success">
          Save All Changes
        </button>
      </div>

      <StoresModal
        callback={onStoresChange}
        selectedStoresIds={storesModal.ids}
        show={storesModal.show}
        multiSelect={true}
      />

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default RoleDashAccess;
