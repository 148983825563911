import { yupResolver } from "@hookform/resolvers/yup";
import { useAttachAdditionalData } from "@sk/hooks";
import { RolesService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppTitle,
  EntitySearchInput,
  PageLoader,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const rolesValidationSchema = yup.object({
  name: yup.string().trim().label("Role Name").required(),
  roleType: yup.string().trim().label("Role Type").required(),
  parentRole: yup.array(),
  department: yup.string().trim().label("Department").required(),
});

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultFromValues = {
  name: "",
  roleType: "",
  parentRole: [],
  department: "",
  groupsData: [],
  menuConfiguration: [],
};

const roleTypeOptions = [
  { label: "Choose Role Type", value: "" },
  { label: "Employee", value: "Employee" },
  { label: "Franchise", value: "Franchise" },
  { label: "Support", value: "Support" },
];

const canvasStyle = { width: "50%" };

// Define a style object for the custom scrollbar
const scrollableStyle = {
  maxHeight: "200px",
  overflowY: "auto",
  overflowX: "hidden",
};

const getData = async (params) => {
  const r = await RolesService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const ManageRolesModal = ({ show, closeModal, roleId, mode }) => {
  const {
    reset,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: defaultFromValues,
    resolver: yupResolver(rolesValidationSchema),
  });

  // Use useWatch to monitor groupsData
  const groupsData = useWatch({ control, name: "groupsData" });

  // Page Loading
  const [loading, setLoading] = useState(false);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState({});

  const [isDuplicate, setIsDuplicate] = useState(false);

  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Reset Form
    if (show) {
      reset();
    }

    const loadDetails = async () => {
      setLoading(true);
      let m = await getData({ filter: { _id: roleId } });
      const d = m?.length ? m[0] : {};
      if (d._id) {
        let tmp = [];

        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });

        loadParentRoleDetails(d);
        setData(d);
      } else {
        setLoading(false);
      }
    };

    if (roleId.length && show) {
      loadDetails(roleId);
    } else {
      setData({});
      setLoading(false);
    }
  }, [
    attachAllData,
    loadParentRoleDetails,
    reset,
    roleId,
    setAdditionalData,
    show,
  ]);

  // Fetching Parent Role Details
  const loadParentRoleDetails = useCallback(
    async (d) => {
      if (d?.parentRoleId) {
        const r = await getData({
          filter: { _id: { $in: d?.parentRoleId } },
        });
        d._parent = r.length ? r[0] : {};
        setData(d);
      }

      // Menu Config form value
      Object.keys(defaultFromValues).forEach((x) => {
        setValue(x, d[x]);
      });
      setValue(
        "menuConfiguration",
        JSON.stringify(d.menuConfiguration, null, 2)
      );
      if (d?._parent?._id) {
        setValue("parentRole", [
          {
            label: d?._parent?.name + "(" + d._parent._id + ")",
            value: d._parent,
          },
        ]);
      }

      if (d?.groups?.length) {
        let g = d.groups.map((e) => {
          return { label: e, value: { _id: e } };
        });
        setValue("groupsData", g);
      }

      setLoading(false);
    },
    [setValue]
  );

  // on submit click
  const onSubmit = async (d) => {
    if (isDuplicate) {
      Toaster.error("Role Name already exist");
      return;
    }

    if (d.menuConfiguration.length) {
      try {
        JSON.parse(d.menuConfiguration);
      } catch (validationError) {
        Toaster.error(
          "Invalid JSON Value Entered in Configure Menu",
          validationError.errors
        );
        return;
      }
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    let params = {
      name: d.name,
      department: d.department,
      roleType: d.roleType,
      parentRoleId: d.parentRole.length ? d.parentRole[0]["value"]["_id"] : "",
      groups: d.groupsData.map((e) => {
        return e?.value?._id;
      }),
      menuConfiguration: d?.menuConfiguration?.length
        ? JSON.parse(d.menuConfiguration)
        : [],
    };

    setSubmitting(true);
    let r = {};
    //  API will Go here
    if (mode == "add") {
      r = await RolesService.createRole(params);
    } else {
      r = await RolesService.updateRole(data._id, params);
    }

    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    reset();
    // setLoading(false);
    setSubmitting(false);
    Toaster.success(`${mode == "add" ? "Created" : "Updated"} Successfully`);
    closeModal({ status: "submit" });
  };

  const onNameChange = useCallback(
    debounce(() => {
      loadGroupNames();
    }, 1000),
    [loadGroupNames]
  );

  const loadGroupNames = useCallback(async () => {
    setIsDuplicate(false);
    setSubmitting(true);
    let p = { filter: { name: getValues("name") } };
    const r = await RolesService.getList(p);
    let d = Array.isArray(r.resp) ? r.resp : [];
    setIsDuplicate(d?.length ? true : false);
    setSubmitting(false);
  }, []);

  // Function to remove a group from the selected groups
  const removeGroup = (groupId) => {
    const updatedGroups = groupsData.filter(
      (group) => group.value._id !== groupId
    );
    setValue("groupsData", updatedGroups);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        backdrop="static"
        keyboard={false}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <AppTitle
            title={
              (mode === "edit" ? "Edit" : "Add") +
              " Manage Access Roles " +
              (mode === "edit" ? "  " + "#" + data._id : "")
            }
          />
        </Offcanvas.Header>

        <Offcanvas.Body className="modal-bg">
          {loading && <PageLoader />}
          {!loading && (
            <form autoComplete="off">
              <AppCard>
                <div className="row">
                  {/* Role Name */}
                  <div className="col-12">
                    <TextInput
                      name="name"
                      register={register}
                      label={"Role Name"}
                      isMandatory={true}
                      placeholder="Enter Role Name Here"
                      error={errors?.name?.message}
                      callback={onNameChange}
                    />
                  </div>

                  {/* Role Type */}
                  <div className="col-6">
                    <SelectInput
                      label="Select Role Type"
                      register={register}
                      name="roleType"
                      isMandatory={true}
                      options={roleTypeOptions}
                      error={errors?.roleType?.message}
                    />
                  </div>

                  {/* Department */}
                  <div className="col-6">
                    <TextInput
                      name="department"
                      register={register}
                      label={"Department"}
                      isMandatory={true}
                      placeholder="Enter Department"
                      error={errors?.department?.message}
                    />
                  </div>

                  {/* Parent Role Search */}
                  <div className="col-12">
                    <Controller
                      control={control}
                      name="parentRole"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="roles"
                          label="Search for Parent Roles"
                          placeholder="Search by Id/Name"
                          value={value}
                          callback={onChange}
                          uid="roles-search"
                          emptyLabel="No Roles Found"
                        />
                      )}
                    />
                  </div>
                </div>
              </AppCard>

              {/* Access Permission Group Search */}
              {/* <div className="col-12">
                  <Controller
                    control={control}
                    name="groupsData"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="groups"
                        label="Search for Groups by Id/Name/Features"
                        placeholder="Search by Id/Name/Features "
                        value={value}
                        callback={onChange}
                        uid="groups-search"
                        emptyLabel="No Groups Found"
                        isMultiple={true}
                      />
                    )}
                  />
                </div> */}

              {/* Display Selected Groups */}

              <AppCard title="Selected Access Groups">
                <div className={`row custom-scrollbar`} style={scrollableStyle}>
                  {groupsData?.map((group, index) => (
                    <div key={index} className="col-4 border-bottom py-1">
                      <span className="fs-val-md">{group.label}</span>
                      <button
                        type="button"
                        className="btn btn-clear btn-sm text-danger"
                        onClick={() => removeGroup(group.value._id)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  ))}
                  {!groupsData?.length && (
                    <div className="col-12">No Groups Selected</div>
                  )}
                </div>
              </AppCard>

              <div className="text-end">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                >
                  Submit {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </form>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ManageRolesModal);
