import { yupResolver } from "@hookform/resolvers/yup";
import { ProjectService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  DateInput,
  EntitySearchInput,
  PageLoader,
  RichTextEditorInput,
  SelectInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object({
  projectName: yup.string().label("Project Name").required(),
  description: yup.string().label("Description").required(),
  projectType: yup.string().label("Project Type"),
  projectManager: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required("Label is required"),
        value: yup.object().required("Value is required"),
      })
    )
    .label("Project Manager")
    .required("Project Manager is required")
    .transform((value) => (value === "" ? [] : value))
    .min(1, "Project Manager is required")
    .default([]),
  status: yup.string().label("Status").required(),
  startDate: yup
    .date()
    .label("Start Date")
    .required()
    .typeError("Start Date must be a valid date"),
  endDate: yup
    .date()
    .label("End Date")
    .required()
    .typeError("End Date must be a valid date")
    .min(yup.ref("startDate"), "End Date cannot be less than Start Date"),
  projectFor: yup.string().label("Project For").required(),
});

const projectTypeOptions = [
  { value: "", label: "Choose" },
  { value: "app", label: "App" },
  { value: "erp", label: "ERP" },
  { value: "ui", label: "UI" },
];

const projectForOptions = ProjectService.getProjectFor();
projectForOptions.unshift({ value: "", label: "Choose" });
const projectStatusOptions = ProjectService.getProjectStatuses();

const canvasStyle = { width: "40%" };

const employeeFilter = {
  filter: {
    "assignment_details.dep": "Technology", // Adjust this as needed
    status: "Active", // Ensure this matches your requirements
  },
};

const defaultFormData = {
  projectName: "",
  projectType: "",
  projectManager: "",
  status: "Active",
  startDate: "",
  endDate: "",
  description: "",
};

const empTemplate = (employee) => {
  return (
    <div>
      <div className="fs-val-md">{employee.label}</div>
      <div className="fs-val-xs text-muted">
        {employee.value?.assignment_details?.designation}
      </div>
    </div>
  );
};

const ProjectManageModal = ({ show = false, callback, editId = "" }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
  } = useForm({
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);
  const [busyloader, setBusyloader] = useState({ show: false });

  useEffect(() => {
    if (show) {
      reset({ ...defaultFormData });

      if (editId) {
        loadEditData(editId);
      }
    }
  }, [show, editId, reset, loadEditData]);

  const loadEditData = useCallback(
    async (id) => {
      setLoading(true);
      const r = await ProjectService.getList({
        filter: { _id: id },
      });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      setValue("projectName", d.name);
      setValue("projectType", d.type);
      if (d.assignedTo && d.assignedTo.id && d.assignedTo.name) {
        setValue("projectManager", [
          {
            label: d.assignedTo.name,
            value: { _id: d.assignedTo.id, firstName: d.assignedTo.name },
          },
        ]);
      }
      setValue("status", d.status);
      setValue("startDate", [d.startDate]);
      setValue("endDate", [d.endDate]);
      setValue("description", d.description);
      setValue("projectFor", d.projectFor);
      setLoading(false);
    },
    [setValue]
  );

  const closeModal = () => {
    callback({ action: "close" });
  };

  const onSubmit = async (data) => {
    let payload = {
      name: data.projectName,
      description: data.description,
      documents: [],
      startDate: data.startDate.toISOString(),
      endDate: data.endDate.toISOString(),
      status: data.status,
      type: data.projectType,
      assignedTo: {
        id: data.projectManager[0].value._id,
        name: data.projectManager[0].value.firstName,
      },
      projectFor: data.projectFor,
    };

    let r;

    setBusyloader({ show: true });
    if (editId) {
      r = await ProjectService.updateProject(editId, payload);
    } else {
      r = await ProjectService.createProject(payload);
    }

    setBusyloader({ show: false });
    if (r.statusCode == 200) {
      Toaster.success("Project submitted successfully");
      callback({ action: "submitted" });
    } else {
      Toaster.error(r.resp.message || "Failed to submit, please try again");
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">
              {editId ? `Edit Project #${editId}` : "Create Project"}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? (
            <PageLoader />
          ) : (
            <AppCard>
              <div className="row">
                <div className="col-12">
                  <TextInput
                    name="projectName"
                    register={register}
                    label="Project Name"
                    error={errors.projectName?.message}
                    isMandatory={true}
                  />
                </div>
                <div className="col-12">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RichTextEditorInput
                        label="Description"
                        value={value}
                        onChangeCb={onChange}
                        error={errors.description?.message}
                        isMandatory={true}
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <SelectInput
                    name="projectType"
                    register={register}
                    label="Project Type"
                    error={errors.projectType?.message}
                    isMandatory={true}
                    options={projectTypeOptions}
                  />
                </div>

                <div className="col-6">
                  <SelectInput
                    label="Project For"
                    register={register}
                    name="projectFor"
                    error={errors.projectFor?.message}
                    isMandatory={true}
                    options={projectForOptions}
                  />
                </div>

                <div className="col-6">
                  <Controller
                    name="projectManager"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <EntitySearchInput
                        type="employee"
                        value={value}
                        callback={onChange}
                        label="Project Manager"
                        error={errors.projectManager?.message}
                        isMandatory={true}
                        filterParams={employeeFilter} // Updated filter params
                        template={empTemplate}
                      />
                    )}
                  />
                </div>

                <div className="col-6">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DateInput
                        value={value}
                        callback={onChange}
                        label="Start Date"
                        error={errors.startDate?.message}
                        isMandatory={true}
                      />
                    )}
                  />
                </div>

                <div className="col-6">
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DateInput
                        value={value}
                        callback={onChange}
                        label="End Date"
                        error={errors.endDate?.message}
                        isMandatory={true}
                      />
                    )}
                  />
                </div>

                <div className="col-6">
                  <SelectInput
                    label="Status"
                    register={register}
                    name="status"
                    error={errors.status?.message}
                    isMandatory={true}
                    options={projectStatusOptions}
                  />
                </div>
              </div>
            </AppCard>
          )}
        </Offcanvas.Body>
        {!loading ? (
          <Offcanvas.Header>
            <div className="text-end w-100">
              <button
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>
            </div>
          </Offcanvas.Header>
        ) : null}
      </Offcanvas>
      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default ProjectManageModal;
