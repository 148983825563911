import {
  FileUpload,
  ImgRender,
  RadioInput,
  TextEditorInput,
  TextInput,
  TextareaInput,
} from "@sk/uis";
import { useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { formView } from "../../../constantService";

const NotifTempMessageContent = () => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const mode = useWatch({ control, name: "mode" });

  const actions = useWatch({ control, name: "actions" });

  const imageType = useWatch({ control, name: "imageType" });

  const image = useWatch({ control, name: "image" });

  const onDescChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e.cnt || "");
    },
    []
  );

  const imagesCb = async (e) => {
    setValue("image", e.asset);
  };

  const removeImg = useCallback(
    (key) => {
      setValue("image", "");
    },
    [setValue]
  );

  const onImageTypeChange = (chngfn) => (e) => {
    setValue("image", "");
    setValue("link", "");
    chngfn(e);
  };

  return (
    <>
      <div className=" fs-val-lg fw-semibold mb-4 pt-4">
        Template Message Details
      </div>
      <div className="row mb-3">
        {/* Title Name */}
        {mode && ["InApp", "PUSH"].indexOf(mode) != -1 ? (
          <div className="col-12">
            <TextInput
              type="text"
              label="Title"
              placeholder="Enter Notification Name Here"
              register={register}
              name="title"
              error={errors?.title?.message}
              isMandatory={true}
            />
          </div>
        ) : null}

        {/* Type options */}
        {mode && ["InApp"].indexOf(mode) != -1 ? (
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              label="Type"
              placeholder="Enter type Name Here"
              register={register}
              name="type"
              error={errors?.type?.message}
              isMandatory={true}
            />
          </div>
        ) : null}

        {/* SubType options */}
        {mode && ["InApp"].indexOf(mode) != -1 ? (
          <div className="col-6 mb-3">
            <TextInput
              type="text"
              label="Subtype"
              placeholder="Enter SubType Name Here"
              register={register}
              name="subType"
              error={errors?.subType?.message}
              isMandatory={true}
            />
          </div>
        ) : null}

        {/* Subject options */}
        {mode && ["EMail"].indexOf(mode) != -1 ? (
          <div className="col-12 mb-3">
            <TextInput
              type="text"
              label="Subject"
              placeholder="Enter Subject Here"
              register={register}
              name="subject"
              error={errors?.subject?.message}
              isMandatory={true}
            />
          </div>
        ) : null}

        {/* Header options */}
        {mode && ["WhatsApp"].indexOf(mode) != -1 ? (
          <div className="col-12 mb-3">
            <TextareaInput
              type="text"
              label="Header"
              placeholder="Enter Header Content Here"
              register={register}
              name="header"
              error={errors?.header?.message}
              isMandatory={true}
              maxLength="150"
              rows="3"
              note="Maximum 150 character allowed"
            />
          </div>
        ) : null}

        {/* Style , Upload Image , and Led for PUSH Notifications */}
        {mode && ["PUSH"].indexOf(mode) != -1 ? (
          <>
            <div className="col-4 mb-3">
              <TextInput
                type="text"
                label="Style"
                placeholder="Enter Style Here"
                register={register}
                name="style"
                error={errors?.style?.message}
              />
            </div>
            <div className="col-4 mb-3">
              <TextInput
                type="color"
                label="Led"
                register={register}
                name="led"
                error={errors?.led?.message}
              />
            </div>
            <div className="col-12 mb-2">
              <Controller
                name="imageType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioInput
                    label="Choose Image Upload Type"
                    register={register}
                    value={value}
                    onChange={onImageTypeChange(onChange)}
                    cols={4}
                    name="status"
                    isMandatory={false}
                    options={formView.imgTypeOptions}
                  />
                )}
              />
            </div>

            {/* Image Link */}
            {imageType == "link" ? (
              <div className="col-6 mb-5">
                <TextInput
                  type="text"
                  label="By Entering Image Link"
                  register={register}
                  name="link"
                />
              </div>
            ) : null}

            {/* Upload Image */}
            {imageType == "upload" ? (
              <div className="row mb-4">
                {/* Image rendering */}
                <div className="col-auto">
                  {!image?.length ? (
                    <div className="upload-placeholder-t1">
                      <i className="bi bi-image"></i>
                    </div>
                  ) : (
                    <div className="uploaded-t1-img-cnt">
                      <i
                        tabIndex={-1}
                        role="button"
                        className="bi bi-x-circle close"
                        onClick={() => {
                          removeImg();
                        }}
                      ></i>
                      <ImgRender
                        assetId={image.length ? image : ""}
                        width="100"
                        height="100"
                      />
                    </div>
                  )}
                </div>
                {/* Action button  */}
                <div className="col col-ms-auto align-self-end">
                  <label className="col-form-label fs-val-md">
                    Image <span className="text-danger"> * </span>
                  </label>
                  <div>
                    <FileUpload template={2} callback={imagesCb} maxSize={10} />
                  </div>
                </div>
              </div>
            ) : null}
            {/* Upload Image Ends Here */}
          </>
        ) : null}

        {/* Body options */}
        {["EMail"].indexOf(mode) == -1 ? (
          <div className="col-12 mb-3">
            <TextareaInput
              name="body"
              placeholder="Enter Body Content Here"
              error={errors?.body?.message}
              label="Body"
              register={register}
              isMandatory={true}
              maxLength="300"
              rows="4"
              note="Maximum 300 character allowed"
            />
          </div>
        ) : null}

        {/* Footer options */}
        {mode && ["WhatsApp"].indexOf(mode) != -1 ? (
          <div className="col-12 mb-3">
            <TextareaInput
              type="text"
              label="Footer"
              placeholder="Enter Footer Content Here"
              register={register}
              name="footer"
              error={errors?.footer?.message}
              isMandatory={true}
              maxLength="150"
              rows="3"
              note="Maximum 150 character allowed"
            />
          </div>
        ) : null}

        {/* Summary Text and buttons UI element details for PUSH Notifications */}
        {mode && ["PUSH"].indexOf(mode) != -1 ? (
          <>
            <div className="col-12 mb-3">
              <TextInput
                type="text"
                label="Summary Text"
                placeholder="Enter Summary Text Here"
                register={register}
                name="summaryText"
                error={errors?.summaryText?.message}
              />
            </div>

            {actions.map((e, index) => (
              <div key={index} className="row pe-0 me-0">
                <div className="col-3 mb-3">
                  <TextInput
                    type="text"
                    label="Button Title"
                    placeholder="Enter Button Title Here"
                    register={register}
                    name={`actions.${index}.title`}
                  />
                </div>

                <div className="col-3 mb-3">
                  <TextInput
                    type="text"
                    label="Button Icon"
                    register={register}
                    placeholder="Enter Button Icon Here"
                    name={`actions.${index}.icon`}
                  />
                </div>

                <div className="col-6 mb-3 pe-0">
                  <TextInput
                    type="text"
                    label="Button Link"
                    register={register}
                    placeholder="Enter Button Link Here"
                    name={`actions.${index}.callback`}
                  />
                </div>
              </div>
            ))}

            <div className="col-12 mb-3 pe-0">
              <TextInput
                type="text"
                label="Url Link"
                register={register}
                placeholder="Enter URL Link Here"
                name="url"
              />
            </div>

            <div className="col-6 mb-3 pe-0">
              <TextInput
                type="text"
                label="Url Data"
                register={register}
                placeholder="Enter URL Data Here"
                name="urlType"
              />
            </div>

            <div className="col-6 mb-3 pe-0">
              <TextInput
                type="text"
                label="Url ID"
                register={register}
                placeholder="Enter URL ID Here"
                name="urlId"
              />
            </div>
          </>
        ) : null}

        {mode && ["EMail"].indexOf(mode) != -1 ? (
          <div className="col-12">
            <Controller
              name="body"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextEditorInput
                  name="body"
                  control={control}
                  label="Body"
                  error={errors?.body?.message}
                  isMandatory={true}
                  value={value}
                  onChangeCb={onDescChange(onChange)}
                />
              )}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NotifTempMessageContent;
