import { MarketingDashboardNavComponent } from "@sk/features";
import { CommonService, NavService, NotificationService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Tabs,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import notifications from "../../constantService";
import listView, { filterView } from "../constantService";
import NotifEventsSummaryCard from "./components/NotifEventSummaryCard";
import NotifEventsTable from "./components/NotifEventsTable";
import NotifEventsAdvanceFilterModal from "./modals/NotifEventsAdvanceFilterModal";
import NotifEventsEditModal from "./modals/NotifEventsEditModal";
import NotifEventsViewModal from "./modals/NotifEventsViewModal";

const defaultSummaryData = listView.summaryData;

const defaultPagination = listView.pagination;

const defaultSort = listView.defaultSortOpt;

const getData = async (params) => {
  const r = await NotificationService.getEventsList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await NotificationService.getEventsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};

  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [{ name: searchKey }, { _id: searchKey }],
    };
  }

  if (d.isActive) {
    p.filter.isActive = d.isActive == "Active" ? true : false;
  }

  if (d.mode?.length) {
    p.filter["rules.mode"] = d?.mode;
  }

  if (d.businessEntity) {
    p.filter.businessEntity = d.businessEntity;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const NotifEventsList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [filterLabels, setFilterLabels] = useState([]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [summaryCard, setSummaryCard] = useState([]);

  const [showViewModal, setShowViewModal] = useState({
    status: false,
    data: {},
  });

  const [showEditModal, setShowEditModal] = useState({
    status: false,
    data: {},
  });

  const filterDataRef = useRef({ ...listView.filterFromData });

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef({ ...defaultSort });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    loadSummaryCard();
    prepareFilterLabels();
    loadList();

    const p = getFilterParams();
    setLoadingTotalRecords(true);

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummaryCard, prepareFilterLabels]);

  const loadSummaryCard = useCallback(() => {
    let s = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter) {
        delete p.filter?.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(s);
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    setData(d);
    setLoadingData(false);
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    if (v.businessEntity) {
      let s = filterView.businessEntityOptions.find(
        (k) => v.businessEntity === k.value
      );
      v.businessEntity = s.label;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const viewCb = (data) => {
    setShowViewModal({ status: true, data: data });
  };

  const closeViewModal = () => {
    setShowViewModal({ status: false, data: {} });
  };

  const editCb = (data) => {
    setShowEditModal({ status: true, data: data });
  };

  const closeEditModal = useCallback(
    (d) => {
      if (d.status && d.status == "success") {
        init();
      }
      setShowEditModal({ status: false, data: {} });
    },
    [init]
  );

  const tabCb = (d) => {
    NavService.to(navigate, d.value.navTo, { tab: d.value.key });
  };

  return (
    <>
      <PageInfo
        title="Notification Events List"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      <MarketingDashboardNavComponent
        activeTab="notification"
        router={navigate}
      />

      <div className="py-3 bg-white">
        <Tabs
          data={notifications.tabs}
          callback={tabCb}
          activeTab={{ key: "events" }}
          template="2"
        />
      </div>
      <div className="bg-white p-3 pt-1">
        {/* Summary Card Overview */}
        <div className="row mb-2">
          {summaryCard.map((card, i) => (
            <div className="col-2" key={i}>
              <NotifEventsSummaryCard
                label={card.label}
                color={card.color}
                filterParams={card.filterParams}
                img={card.img}
              />
            </div>
          ))}
        </div>

        {/* Filter Block */}
        <div className="row align-items-center">
          <div className="col-6">
            <div className="row">
              {/* Name/ID Search Input */}
              <div className="col-8 align-self-center">
                <TextInput
                  name="name"
                  type="text"
                  register={register}
                  callback={onSearch}
                  placeholder="Search By  Event Name / ID"
                />
              </div>

              {/* Filter Button */}
              <div className="col-auto">
                <button
                  className="btn app-filter-btn"
                  type="button"
                  onClick={openAdvFilterModal}
                >
                  <i className="bi bi-funnel"></i> FILTER
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Filter selected Label */}
        <div className="my-3">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>

        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
      </div>

      {/* Table Component */}
      <NotifEventsTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
        editCb={editCb}
      />

      {/* Advance Filter Modal  */}
      <NotifEventsAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      <NotifEventsViewModal
        show={showViewModal.status}
        callback={closeViewModal}
        id={showViewModal.data._id}
        logData={showViewModal.data}
      />

      <NotifEventsEditModal
        show={showEditModal.status}
        callback={closeEditModal}
        id={showEditModal.data._id}
      />
    </>
  );
};

export default NotifEventsList;
