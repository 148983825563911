import { SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

const roleTypeOptions = [
  { label: "All", value: "" },
  { label: "Employee", value: "Employee" },
  { label: "Franchise", value: "Franchise" },
  { label: "Support", value: "Support" },
];

const FilterForm = ({ callback }) => {
  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      roleType: "",
      roles: "",
    },
  });

  // On Submit
  const applyFilter = useCallback(() => {
    callback({ formData: getValues() });
  }, [callback, getValues]);

  // Reset Form Data
  const resetFormData = () => {
    reset();
    callback({ formData: "" });
  };

  // SearchCb for Roles
  const onRolesChange = useCallback(
    debounce(() => {
      applyFilter();
    }, 700),
    [applyFilter]
  );

  return (
    <form onSubmit={handleSubmit(applyFilter)}>
      <div className="row align-items-center">
        {/* Role Type */}
        <div className="col-auto">
          <SelectInput
            label="Select Role Type"
            register={register}
            name="roleType"
            isMandatory={false}
            options={roleTypeOptions}
            callback={applyFilter}
            gap={0}
          />
        </div>

        {/* Search By Role ID/Name */}
        <div className="col-3">
          <TextInput
            name="roles"
            register={register}
            label="Search for Roles"
            placeholder="Search by Id/Name"
            uid="Groups-search"
            emptyLabel="No roles Found"
            callback={onRolesChange}
            gap={0}
          />
        </div>

        {/* <div className="col-auto  ms-auto align-self-center">
          <button className="btn  me-1 mt-1 btn-primary btn-sm" type="submit">
            Apply
          </button>
          <button
            className="btn mt-1 btn-outline-warning btn-sm"
            type="button"
            onClick={resetFormData}
          >
            Reset
          </button>
        </div> */}
      </div>
    </form>
  );
};

export default memo(FilterForm);
