import { NavService, appConfigs } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";

const rbac = {
  viewButton: ["ViewBrand"],
  editButton: ["EditBrand"],
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Upload Ref Id" },
  { label: "Template Name" },
  { label: "Created On" },
  { label: "Total Records ", isCentered: true },
  { label: "Success", isCentered: true },
  { label: "Failure", isCentered: true },
  { label: "Status", isCentered: true },
  { label: "Action", isCentered: true },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
}) => {
  const download = (assetId) => {
    let url = appConfigs.ASSET + "/" + assetId;
    window.open(url, "_blank");
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr
                key={x._id}
                className={classNames({
                  "border-bottom border-success border-2":
                    x?.totalRequestCount == x?.totalSuccessRequestCount,
                  "border-bottom border-danger border-2":
                    x?.totalFailureRequestCount > 0,
                })}
              >
                {/* Serial Number */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Ref ID */}
                <td>{x?._id}</td>

                {/* Template Name */}
                <td>{x?.templateCode}</td>

                {/* Uploaded By */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>
                    By : &nbsp;
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x?._createdBy?.name || x?.createdBy || "N/A"
                    )}
                  </div>
                </td>

                {/* Total Records*/}
                <td className="text-center">{x?.totalRequestCount || 0}</td>

                {/* Success Records*/}
                <td className="text-center  text-success">
                  {x?.totalSuccessRequestCount || 0}
                </td>

                {/* Failure Records*/}
                <td className="text-center text-danger">
                  {x?.totalFailureRequestCount || 0}
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={false} />
                </td>

                {/* Action Buttons */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-2 fs-val-xs"
                      onClick={() => viewCb(x._id)}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning fs-val-xs"
                      onClick={() => download(x?.inputFilePath)}
                    >
                      Download
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default Table;
