import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Type", width: "15%" },
  { label: "Feature", width: "15%" },
  { label: "Group Name", width: "15%" },
  { label: "Is Internal", width: "8%" },
  { label: "Last Updated On", width: "12%" },
  { label: "Actions", isCentered: true, width: "12%" },
];

const rbac = {
  editButton: ["EditGroup"],
  viewButton: ["ViewGroup"],
  deleteButton: ["DeleteGroup"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  sort,
  sortCb,
  editCb,
  deleteCb,
}) => {
  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-striped table-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} noBg />
        <tbody>
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader
              rows={paginationConfig.rowsPerPage}
              cols={headers.length}
              height={40}
            />
          )}

          {/* No Data Found */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* Table List */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{x?.type}</td>
                <td>{x.feature}</td>
                <td className="text-break">{x.name}</td>
                <td>
                  <HighlightText
                    template={2}
                    type={x.isInternalGroup ? "success" : "danger"}
                  >
                    {x.isInternalGroup ? "Yes" : "No"}
                  </HighlightText>
                </td>
                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>
                {/* Action buttons */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-sm"
                      onClick={() => viewCb(x)}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-sm"
                      onClick={() => editCb(x)}
                    >
                      Edit
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.deleteButton}>
                    <button
                      className="btn btn-sm btn-outline-danger me-1 fs-val-sm"
                      onClick={() => deleteCb(x._id)}
                    >
                      Delete
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
