import { AppCard, SelectInput, TableHeader, TextInput, Toaster } from "@sk/uis";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";

const actionOptions = [
  { value: "", label: "Choose Action" },
  // { value: "coupon_code", label: "Coupon Code" },
  { value: "website_url", label: "Website URL" },
  // { value: "quick_replies", label: "Quick Replies" },
  { value: "phone_number", label: "Phone Number" },
];

const tableHeaders = [
  { label: "Action Type", key: "actionType" },
  { label: "Configs", key: "configs" },
  { label: "Action", key: "action" },
  { label: "Remove", key: "remove" },
];

const WhatsAppTemplateActions = () => {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [selectedActionType, actionsArray] = useWatch({
    control,
    name: ["action.type", "actions"],
  });

  // Validation schemas for different action types
  const validationSchemas = {
    coupon_code: Yup.object().shape({
      couponCode: Yup.string()
        .required("Coupon Code is required")
        .max(15, "Coupon Code must be max 15 characters"),
    }),
    website_url: Yup.object().shape({
      websiteUrl: Yup.string()
        .required("Website URL is required")
        .url("Must be a valid URL")
        .max(200, "URL must be max 200 characters"),
    }),
    phone_number: Yup.object().shape({
      phoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
    }),
    quick_replies: Yup.object().shape({
      quickReplyText: Yup.string()
        .required("Quick Reply Text is required")
        .max(25, "Quick Reply Text must be max 25 characters"),
    }),
  };

  const handleAddAction = () => {
    const actionType = selectedActionType;

    // Validate based on action type
    const schema = validationSchemas[actionType];

    try {
      // Check constraints for each action type
      const existingActions = actionsArray || [];

      // Coupon Code constraint: Only one coupon code allowed
      if (actionType === "coupon_code") {
        const existingCouponCode = existingActions.find(
          (action) => action.type === "coupon_code"
        );
        if (existingCouponCode) {
          throw new Error("Only one Coupon Code is allowed");
        }
      }

      // Website URL constraint: Max 10 URLs
      if (actionType === "website_url") {
        const existingUrls = existingActions.filter(
          (action) => action.type === "website_url"
        );
        if (existingUrls.length >= 10) {
          throw new Error("Maximum 10 Website URLs are allowed");
        }
      }

      // Phone Number constraint: Only one phone number allowed
      if (actionType === "phone_number") {
        const existingPhoneNumber = existingActions.find(
          (action) => action.type === "phone_number"
        );
        if (existingPhoneNumber) {
          throw new Error("Only one Phone Number is allowed");
        }
      }

      // Quick Replies constraint: Max 10 quick replies
      if (actionType === "quick_replies") {
        const existingQuickReplies = existingActions.filter(
          (action) => action.type === "quick_replies"
        );
        if (existingQuickReplies.length >= 10) {
          throw new Error("Maximum 10 Quick Replies are allowed");
        }
      }

      // Validate the current form data for the specific action type
      const validData = schema.validateSync({
        couponCode: getValues("action.couponCode"),
        websiteUrl: getValues("action.websiteUrl"),
        phoneNumber: getValues("action.phoneNumber"),
        quickReplyText: getValues("action.quickReplyText"),
      });

      // Prepare action object
      const newAction = {
        type: actionType,
        ...(actionType === "coupon_code" && {
          couponCode: validData.couponCode,
        }),
        ...(actionType === "website_url" && {
          websiteUrl: validData.websiteUrl,
          buttonText: getValues("action.commonButtonText"),
        }),
        ...(actionType === "phone_number" && {
          phoneNumber: validData.phoneNumber,
          buttonText: getValues("action.commonButtonText"),
        }),
        ...(actionType === "quick_replies" && {
          quickReplyText: validData.quickReplyText,
        }),
      };

      // Update form state
      const updatedActions = [...existingActions, newAction];
      setValue("actions", updatedActions);

      // Reset form fields
      setValue("action.type", "");
      setValue("action.couponCode", "");
      setValue("action.websiteUrl", "");
      setValue("action.phoneNumber", "");
      setValue("action.quickReplyText", "");
      setValue("action.commonButtonText", "");
    } catch (validationError) {
      // Handle validation errors
      console.error("Validation Error:", validationError.message);
      Toaster.error(validationError.message);
    }
  };

  const handleRemoveAction = (indexToRemove) => {
    const updatedActions = (actionsArray || []).filter(
      (_, index) => index !== indexToRemove
    );
    setValue("actions", updatedActions);
  };

  return (
    <AppCard title="Actions">
      <div className="fs-val-sm text-secondary mb-2">
        Create buttons that let customers respond to your message or take
        action.
      </div>

      <div className="row">
        <div className="col-3">
          <Controller
            name="action.type"
            control={control}
            render={({ field }) => (
              <SelectInput
                label="Action Type"
                register={register}
                name={field.name}
                options={actionOptions}
                onChange={(value) => {
                  field.onChange(value);
                }}
                gap={1}
              />
            )}
          />
        </div>

        {selectedActionType === "coupon_code" && (
          <div className="col">
            <TextInput
              name="action.couponCode"
              label="Coupon Code"
              placeholder="Enter Coupon Code"
              register={register}
              gap={1}
              maxLength={15}
              note="Max 15 characters"
              error={errors?.action?.couponCode?.message}
            />
          </div>
        )}
        {selectedActionType === "website_url" && (
          <div className="col">
            <TextInput
              name="action.websiteUrl"
              label="Website URL"
              placeholder="Enter Website URL"
              register={register}
              gap={1}
              maxLength={200}
              note="Max 200 characters"
              error={errors?.action?.websiteUrl?.message}
            />
          </div>
        )}
        {selectedActionType === "phone_number" && (
          <div className="col">
            <TextInput
              name="action.phoneNumber"
              label="Phone Number"
              placeholder="Enter Phone Number"
              register={register}
              gap={1}
              maxLength={10}
              note="10 digit phone number"
              error={errors?.action?.phoneNumber?.message}
            />
          </div>
        )}
        {selectedActionType === "quick_replies" && (
          <div className="col">
            <TextInput
              name="action.quickReplyText"
              label="Quick Reply Text"
              placeholder="Enter Quick Reply Text"
              register={register}
              gap={1}
              maxLength={25}
              note="Max 25 characters"
              error={errors?.action?.quickReplyText?.message}
            />
          </div>
        )}
        {(selectedActionType === "website_url" ||
          selectedActionType === "phone_number") && (
          <div className="col-3">
            <TextInput
              name="action.commonButtonText"
              label="Button Text"
              placeholder="Enter Button Text"
              register={register}
              gap={1}
              maxLength={25}
              note="Max 25 characters"
            />
          </div>
        )}
      </div>
      <div className="text-end mb-3">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleAddAction}
          disabled={!selectedActionType}
        >
          <i className="bi bi-plus-square"></i> Add Action
        </button>
      </div>

      <div>
        <table className="table table-bordered table-sm">
          <TableHeader data={tableHeaders} noBg />
          <tbody className="fs-val-sm">
            {(actionsArray || []).map((action, index) => (
              <tr key={index}>
                <td>{action.type}</td>
                <td>
                  {action.type === "coupon_code" && action.couponCode}
                  {action.type === "website_url" && action.websiteUrl}
                  {action.type === "phone_number" && action.phoneNumber}
                  {action.type === "quick_replies" && action.quickReplyText}
                </td>
                <td>{action.buttonText || "-"}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleRemoveAction(index)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default WhatsAppTemplateActions;
