import { useAttachAdditionalData } from "@sk/hooks";
import { EmployeeService } from "@sk/services";
import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import RolesDashAuditViewModal from "../modals/RolesDashAuditViewModal";

const headers = [
  { label: "S.No", key: "sno", width: "5%" },
  { label: "Remarks", key: "remarks" },
  { label: "Created By", key: "createdBy", width: "20%" },
  { label: "Created At", key: "createdAt", width: "20%" },
  { label: "Action", key: "action", width: "5%" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const additionalTableDataConfig = [
  {
    key: "performedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const getData = async (empId, params) => {
  const res = await EmployeeService.getEmpRbacAuditLogs(empId, params);
  return {
    data: Array.isArray(res.resp)
      ? res.resp.map((x) => ({ ...x, remarks: prepareRemarks(x.changes) }))
      : [],
  };
};

const getCount = async (empId, params) => {
  let p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const res = await EmployeeService.getEmpRbacAuditLogs(empId, p);
  return {
    count: Array.isArray(res.resp) && res.resp.length ? res.resp[0]?.total : 0,
  };
};

const prepareFilter = (filter, pagination) => {
  return {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
  };
};

const prepareRemarks = (changes) => {
  const remarks = [];
  if (changes.addedStores && changes.addedStores.length > 0) {
    remarks.push(
      `${changes.addedStores.length} store${
        changes.addedStores.length > 1 ? "s" : ""
      } added`
    );
  }
  if (changes.removedStores && changes.removedStores.length > 0) {
    remarks.push(
      `${changes.removedStores.length} store${
        changes.removedStores.length > 1 ? "s" : ""
      } removed`
    );
  }
  if (changes.addedGroups && changes.addedGroups.length > 0) {
    remarks.push(
      `${changes.addedGroups.length} access${
        changes.addedGroups.length > 1 ? "es" : ""
      } added`
    );
  }
  if (changes.removedGroups && changes.removedGroups.length > 0) {
    remarks.push(
      `${changes.removedGroups.length} access${
        changes.removedGroups.length > 1 ? "es" : ""
      } removed`
    );
  }

  if (changes.updatedStores && changes.updatedStores.length > 0) {
    remarks.push(
      `${changes.updatedStores.length} store${
        changes.updatedStores.length > 1 ? "s" : ""
      } updated`
    );
  }
  if (changes.updatedGroups && changes.updatedGroups.length > 0) {
    remarks.push(
      `${changes.updatedGroups.length} access${
        changes.updatedGroups.length > 1 ? "es" : ""
      } updated`
    );
  }
  return remarks.join(", ");
};

const RoleDashAudit = ({ empId }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const filterDataRef = useRef({});
  const paginationRef = useRef({ ...defaultPagination });

  const [viewModal, setViewModal] = useState({ show: false, data: null });

  const loadData = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const res = await getData(
      filterDataRef.current.empId,
      prepareFilter(filterDataRef.current, paginationRef.current)
    );

    // Attach additional data for user names
    let tmp = [];
    setAdditionalData(res.data, additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length === additionalTableDataConfig.length) {
        setData([...attachAllData(res.data, tmp)]);
      }
    });

    setData(res.data);

    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const applyFilter = useCallback(() => {
    loadData();
  }, [loadData]);

  const init = useCallback(async () => {
    applyFilter();

    setLoadingTotalRecords(true);
    const c = await getCount(
      filterDataRef.current.empId,
      prepareFilter(filterDataRef.current, paginationRef.current)
    );
    setLoadingTotalRecords(false);
    paginationRef.current.totalRecords = c.count;
    console.log(c.count);
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = { empId };
    init();
  }, [empId, init]);

  const paginationCb = ({ activePage, startSlNo, endSlNo }) => {
    paginationRef.current.activePage = activePage;
    paginationRef.current.startSlNo = startSlNo;
    paginationRef.current.endSlNo = endSlNo;
    loadData();
  };

  return (
    <>
      <table className="table table-sm table-hover">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {loadingData && (
            <TableSkeletonLoader cols={headers.length} rows={10} />
          )}

          {!loadingData && data.length === 0 && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                <NoDataFound>No audit logs found</NoDataFound>
              </td>
            </tr>
          )}

          {data.map((d, i) => (
            <tr key={d._id}>
              <td>{i + 1}</td>
              <td>{d.remarks}</td>
              <td>{d._user?.name || d.createdBy}</td>
              <td>
                <DateFormatter date={d.createdAt} />
              </td>
              <td>
                <button
                  className="btn btn-sm btn-outline-primary fs-val-sm"
                  onClick={() => setViewModal({ show: true, data: d })}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-end mt-2">
        <PaginationBlock
          loadingTotalRecords={loadingTotalRecords}
          paginationCb={paginationCb}
          paginationConfig={paginationRef.current}
          size="sm"
        />
      </div>
      <RolesDashAuditViewModal
        show={viewModal.show}
        onHide={() => setViewModal({ show: false, data: null })}
        data={viewModal.data}
      />
    </>
  );
};

export default RoleDashAudit;
