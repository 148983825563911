import { CustomerService } from "@sk/services";
import { AppCard, PageInfo, SummaryCard } from "@sk/uis";
import { produce } from "immer";
import { merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import MarketingLogsFilter from "./MarketingLogsFilter";
import MarketingLogsTable from "./MarketingLogsTable";
import {
  breadcrumbs,
  defaultFilterData,
  defaultPaginationConfig,
  defaultSummaryData,
} from "./constants";
import {
  ClubViewModal,
  CustomerDetailModal,
  PosOrderDetailModal,
} from "@sk/features";
import { set } from "date-fns";

const getData = async (params) => {
  const r = await CustomerService.getCallLogs(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await CustomerService.getCallLogsCount(p);
  return { count: r.count || 0 };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { $and: [] },
    sort: `${sort.value === "asc" ? "" : "-"}${sort.key}`,
  };

  // Regex search for employee name or exact match for employee ID
  if (filter?.employee) {
    p.filter.$and.push({
      $or: [
        { "employeeInfo.id": filter.employee },
        { "employeeInfo.name": { $regex: filter.employee, $options: "i" } },
      ],
    });
  }

  // Regex search for customer name or exact match for customer ID
  if (filter?.customer) {
    p.filter.$and.push({
      $or: [
        { "customerInfo.id": filter.customer },
        { "customerInfo.name": { $regex: filter.customer, $options: "i" } },
      ],
    });
  }

  // Open search for _id
  if (filter?.search) {
    p.filter.$and.push({ _id: filter.search });
  }

  if (filter?.status) {
    p.filter.$and.push({ status: filter.status });
  }

  if (filter?.callAnswered) {
    p.filter.$and.push({ "callbackResponse.Status": filter.callAnswered });
  }

  if (filter?.dateRange?.length) {
    p.filter.$and.push({
      createdAt: {
        $gte: set(filter.dateRange[0], { hours: 0, minutes: 0, seconds: 0 }),
        $lte: set(filter.dateRange[1], { hours: 23, minutes: 59, seconds: 59 }),
      },
    });
  }

  // Remove $and if it's empty to avoid unnecessary query complexity
  if (p.filter.$and.length === 0) {
    delete p.filter.$and;
  }

  return p;
};

const MarketingLogs = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [summary, setSummary] = useState([...defaultSummaryData]);

  const filterDataRef = useRef({ ...defaultFilterData });
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  const [customerModal, setCustomerModal] = useState({
    show: false,
    cid: "",
  });

  const [clubModal, setClubModal] = useState({
    show: false,
    cid: "",
  });

  const [customerOrderModal, setCustomerOrderModal] = useState({
    show: false,
    orderId: "",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadList();
    loadSummary();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setData(r.data);
    setLoadingData(false);
  }, []);

  const loadSummary = async () => {
    let p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    delete p.page;
    delete p.count;

    let arr = [
      getCount(
        merge({}, p, {
          filter: { status: { $in: ["Success", "Failed", "Processing"] } },
        })
      ),
      getCount(merge({}, p, { filter: { status: "Success" } })),
      getCount(merge({}, p, { filter: { status: "Failed" } })),
      getCount(merge({}, p, { filter: { status: "Processing" } })),
    ];

    const r = await Promise.all(arr);

    setSummary(
      produce((draft) => {
        draft.forEach((e, k) => {
          draft[k].value = r[k]?.count;
        });
      })
    );
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onFilterCb = (e) => {
    if (e.action === "apply" || e.action === "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...e.formData };
      applyFilter();
    }
  };

  const sortCb = (e) => {
    sortRef.current = e;
    applyFilter();
  };

  const handleCustomerClick = (customerId) => {
    setCustomerModal({ show: true, cid: customerId });
  };

  const customerModalCb = useCallback((e) => {
    setCustomerModal({ show: false, cid: "" });
    if (e.action === "viewClub") {
      setClubModal({ show: true, cid: e.id });
    } else if (e.action === "viewOrder") {
      setCustomerOrderModal({ show: true, orderId: e.id });
    }
  }, []);

  const clubModalCb = (e) => {
    setClubModal({ cid: "", show: false });
    if (e.action === "viewCustomer") {
      setCustomerModal({ show: true, cid: e.id });
    } else if (e.action === "viewOrder") {
      setCustomerOrderModal({ show: true, orderId: e.id });
    }
  };

  const customerOrderModalCb = (e) => {
    setCustomerOrderModal({ show: false, orderId: "" });
    if (e.action === "viewCustomer") {
      setCustomerModal({ show: true, cid: e.id });
    }
  };

  return (
    <>
      <PageInfo
        title={breadcrumbs[breadcrumbs.length - 1].name}
        breadcrumbs={breadcrumbs}
      />

      <div>
        <MarketingLogsFilter callback={onFilterCb} />
      </div>

      <div className="row">
        {summary.map((e) => (
          <div className="col col-auto" key={e.key}>
            <SummaryCard
              value={e.value}
              title={e.label}
              template={3}
              valueColor={e.color}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <MarketingLogsTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sortCb={sortCb}
          sort={sortRef.current}
          onCustomerClick={handleCustomerClick}
        />
      </AppCard>

      <CustomerDetailModal
        show={customerModal.show}
        cid={customerModal.cid}
        callback={customerModalCb}
      />

      <ClubViewModal
        show={clubModal.show}
        cid={clubModal.cid}
        callback={clubModalCb}
      />

      <PosOrderDetailModal
        show={customerOrderModal.show}
        orderId={customerOrderModal.orderId}
        callback={customerOrderModalCb}
      />
    </>
  );
};

export default MarketingLogs;
