import { ProjectService } from "@sk/services";
import { AppCard, DateInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import ProjectAdvanceFilterModal from "../modals/ProjectAdvanceFilterModal";

const defaultFormData = {
  search: "",
  startDate: null,
  endDate: null,
  projectStatus: "InProgress",
  projectFor: "",
};

const projectForOptions = ProjectService.getProjectFor();
projectForOptions.unshift({ value: "", label: "All" });

const projectStatusOptions = ProjectService.getProjectStatuses();
projectStatusOptions.unshift({ value: "", label: "All" });

function ProjectFilter({ callback, resetData }) {
  const { control, register, getValues, setValue, reset } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [advFilterModal, setAdvFilterModal] = useState({ show: false });

  useEffect(() => {
    if (resetData?.status) {
      reset({ ...defaultFormData, ...(resetData.fields || {}) });
    }
  }, [setValue, resetData, reset]);

  const onSearch = useCallback(
    debounce(() => {
      triggerCb();
    }, 800),
    []
  );

  const advFilterCb = (e) => {
    setAdvFilterModal({ show: false });
    callback(e);
  };

  const triggerCb = () => {
    callback({ action: "apply", formData: getValues() });
  };

  const onProjectStatusChange = (e) => {
    setValue("projectStatus", e);
    triggerCb();
  };

  // New function to handle start date change
  const handleStartDateChange = (onChange) => (date) => {
    onChange(date);
    triggerCb();
  };

  // New function to handle end date change
  const handleEndDateChange = (onChange) => (date) => {
    onChange(date);
    triggerCb();
  };

  const onProjectForChange = () => {
    triggerCb();
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-2">
            <TextInput
              label="Search by ID/Name"
              placeholder="Search..."
              register={register}
              name="search"
              callback={onSearch}
            />
          </div>
          <div className="col-3">
            <Controller
              name="startDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Start Date"
                  value={value}
                  callback={handleStartDateChange(onChange)} // Pass the function
                  placeholder="Select Start Date"
                />
              )}
            />
          </div>
          <div className="col-3">
            <Controller
              name="endDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="End Date"
                  value={value}
                  callback={handleEndDateChange(onChange)} // Pass the function
                  placeholder="Select End Date"
                />
              )}
            />
          </div>
          <div className="col-2">
            <SelectInput
              options={projectStatusOptions}
              register={register}
              name="projectStatus"
              callback={onProjectStatusChange}
              label="Search by Project Status"
            />
          </div>

          <div className="col-2">
            <SelectInput
              options={projectForOptions}
              register={register}
              name="projectFor"
              label="Project For"
              callback={onProjectForChange}
            />
          </div>

          {/* <div className="col-auto align-self-end">
            <button
              className="btn app-filter-btn mb-3"
              type="button"
              onClick={() => setAdvFilterModal({ show: true })}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div> */}
        </div>
      </AppCard>

      {/* <ProjectAdvanceFilterModal
        show={advFilterModal.show}
        callback={advFilterCb}
      /> */}
    </>
  );
}

export default ProjectFilter;
