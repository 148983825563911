import { appConfigs, ProjectService } from "@sk/services";
import {
  AppCard,
  AppSlide,
  AppSwiper,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  Rbac,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "../ProjectManagement.module.scss";
import classNames from "classnames";
import ProjectTaskComments from "../components/ProjectTaskComments";
// import ProjectTaskAuditLog from "../components/ProjectTaskAuditLog";

const rbac = {
  editTask: ["EditTask"],
  viewComments: ["ViewTaskComment"],
};

const ProjectTaskViewModal = ({ show, callback, taskId }) => {
  const [task, setTask] = useState({});

  const [loading, setLoading] = useState(false);

  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (taskId && show) {
      setShowDescription(false);

      const getTask = async () => {
        setLoading(true);
        const response = await ProjectService.getTask(taskId);
        const task = response.resp?.[0] || {};
        if (task.projectId) {
          const project = await ProjectService.getList({
            filter: { _id: task.projectId },
          });
          task.project = project.resp?.[0] || {};
        }
        setTask(task);
        setLoading(false);
      };
      getTask();
    }
  }, [taskId, show]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  const editTask = () => {
    callback({ action: "edit" });
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleDownloadAttachment = (assetId) => {
    window.open(appConfigs.ASSET + "/" + assetId, "_blank");
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      dialogClassName={classNames(styles["task-view-modal"])}
      scrollable={true}
    >
      <Modal.Header closeButton className="bg-white">
        <Modal.Title className="text-dark mb-0 w-100">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">Task Details - #{task._id}</div>
            {/* <div>
              <button
                className="btn btn-outline-primary btn-sm m-0 me-4"
                onClick={() => callback({ action: "addSubTask", taskId })}
              >
                <i className="bi bi-plus me-2"></i>
                Add Sub Task
              </button>
            </div> */}
            <div>
              <Rbac roles={rbac.editTask}>
                <button
                  className="btn btn-outline-primary btn-sm m-0 me-4"
                  onClick={editTask}
                >
                  <i className="bi bi-pencil me-2"></i>
                  Edit Task
                </button>
              </Rbac>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg custom-scrollbar">
        {loading ? <PageLoader /> : null}

        {!loading && !task?._id ? (
          <NoDataFound>
            <div>Task not found</div>
          </NoDataFound>
        ) : null}

        {!loading && task?._id ? (
          <>
            <div className="row">
              <div className="col-8">
                <AppCard>
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    <div>
                      <div className="text-dark fw-bold fs-5 mb-2">
                        <HighlightText template={2} type={task.taskTypeBadge}>
                          {task.type}
                        </HighlightText>
                        <span className="ms-2">{task.title}</span>
                      </div>
                      <div className="text-muted fs-val-sm">
                        Created on:{" "}
                        <DateFormatter
                          date={task.createdAt}
                          format="dd MMM yyyy"
                        />
                      </div>
                      <div className="text-muted fs-val-sm">
                        Created by:{" "}
                        <span className="fw-medium">
                          {task.createdBy || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div>
                      <HighlightText template={2} type={task.statusBadge}>
                        {task.status}
                      </HighlightText>
                    </div>
                  </div>

                  <div className="mb-3">
                    <button
                      className="btn btn-link btn-sm fs-val-sm p-0 text-dark d-flex align-items-center"
                      onClick={toggleDescription}
                    >
                      <i
                        className={classNames("bi me-2", {
                          "bi-text-paragraph": !showDescription,
                          "bi-text-paragraph-reverse": showDescription,
                        })}
                      ></i>
                      {showDescription
                        ? "Hide Description"
                        : "View Description"}
                      <i
                        className={classNames("ms-2 bi", {
                          "bi-chevron-up": showDescription,
                          "bi-chevron-down": !showDescription,
                        })}
                        aria-expanded={showDescription}
                      ></i>
                    </button>
                    {showDescription && (
                      <div
                        className="mt-3 fs-val-md bg-light p-3 rounded"
                        dangerouslySetInnerHTML={{ __html: task.description }}
                      ></div>
                    )}
                  </div>

                  {task.documents?.length > 0 && (
                    <div className="attachments mt-4">
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-paperclip me-2 text-primary"></i>
                        <strong className="fs-val-sm">Attachments</strong>
                        <span className="ms-2 badge bg-secondary">
                          {task.documents.length}
                        </span>
                      </div>
                      <div className="attachment-slider bg-light p-2 rounded">
                        <AppSwiper
                          config={{ slidesPerView: "auto", spaceBetween: 10 }}
                        >
                          {task.documents.map((attachment, index) => (
                            <AppSlide key={attachment.id} autoWidth>
                              <button
                                type="button"
                                onClick={() =>
                                  handleDownloadAttachment(attachment.assetId)
                                }
                                className="btn btn-outline-secondary btn-sm d-flex align-items-center"
                              >
                                <i className="bi bi-file-earmark me-2"></i>
                                <span>Attachment {index + 1}</span>
                              </button>
                            </AppSlide>
                          ))}
                        </AppSwiper>
                      </div>
                    </div>
                  )}
                </AppCard>

                <AppCard className="mt-3">
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="details-group">
                        <KeyVal
                          label={
                            <>
                              <i className="bi bi-calendar-event me-2"></i>Due
                              Date
                            </>
                          }
                          labelCol="col-5"
                          contentCol="col-7"
                        >
                          :{" "}
                          <DateFormatter
                            date={task.dueDate}
                            className="text-danger fw-medium"
                          />
                        </KeyVal>
                        <KeyVal
                          label={
                            <>
                              <i className="bi bi-calendar-check me-2"></i>Start
                              Date
                            </>
                          }
                          labelCol="col-5"
                          contentCol="col-7"
                        >
                          : <DateFormatter date={task.startDate} />
                        </KeyVal>
                        <KeyVal
                          label={
                            <>
                              <i className="bi bi-calendar2-x me-2"></i>End Date
                            </>
                          }
                          labelCol="col-5"
                          contentCol="col-7"
                        >
                          : <DateFormatter date={task.endDate} />
                        </KeyVal>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="details-group">
                        <KeyVal
                          label={
                            <>
                              <i className="bi bi-person me-2"></i>Assigned To
                            </>
                          }
                          labelCol="col-5"
                          contentCol="col-7"
                        >
                          <span className="fw-medium">
                            : {task.assignedTo?.name || "N/A"}
                          </span>
                        </KeyVal>
                        <KeyVal
                          label={
                            <>
                              <i className="bi bi-clock-history me-2"></i>
                              Modified
                            </>
                          }
                          labelCol="col-5"
                          contentCol="col-7"
                        >
                          :{" "}
                          <DateFormatter
                            date={task.modifiedAt}
                            format="dd MMM yyyy hh:mm a"
                          />
                        </KeyVal>

                        <KeyVal
                          labelCol="col-5"
                          contentCol="col-7"
                          label={
                            <>
                              <i className="bi bi-building me-2"></i>Project
                            </>
                          }
                        >
                          : {task.project?.name || "N/A"}
                        </KeyVal>
                      </div>
                    </div>
                  </div>
                </AppCard>

                {/*<ProjectTaskAuditLog data={task.auditLog} />*/}
              </div>
              <div className="col-4">
                <Rbac roles={rbac.viewComments}>
                  <AppCard
                    title={
                      <div className="d-flex align-items-center">
                        <i className="bi bi-chat-dots me-2"></i>
                        <span>Comments</span>
                      </div>
                    }
                    noPad={true}
                  >
                    <ProjectTaskComments
                      taskId={taskId}
                      projectId={task.projectId}
                    />
                  </AppCard>
                </Rbac>
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ProjectTaskViewModal;
