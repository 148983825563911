import { useAttachAdditionalData } from "@sk/hooks";
import { UserService } from "@sk/services";
import {
  Alert,
  AppCard,
  BooleanCheckboxInput,
  BusyLoader,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  SelectInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { sortBy } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Offcanvas, Spinner } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";

const permissionOptions = [
  { label: "Select", value: "" },
  { label: "Read", value: "R" },
  { label: "Write", value: "W" },
];
// Attach Additional Data
const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];
// Canvas Size
const canvasStyle = { width: "55%" };

const ViewGroupsModal = ({ show, closeModalCb, id }) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({ defaultValues: { isAllEnabled: false } });

  // Custom Hook to Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Loading state To Load Data
  const [loading, setLoading] = useState(true);

  const [busyLoader, setBusyLoader] = useState(false);
  // View Data
  const [data, setData] = useState([]);

  const [rulesList, setRulesList] = useState({ loading: false, data: [] });

  const [selectedRule, setSelectedRule] = useState({
    value: "",
    data: {},
    list: [],
  });

  const [isAllEnabled] = useWatch({
    name: ["isAllEnabled"],
    control,
  });

  const groupIdRef = useRef("");

  useEffect(() => {
    if (show && id) {
      groupIdRef.current = id;
      loadAccessGroupDetails(id);
      loadRulesList();
    }
  }, [show, id, loadAccessGroupDetails, loadRulesList]);

  // To load access Group Data
  const loadAccessGroupDetails = useCallback(async () => {
    setLoading(true);
    const r = await UserService.getAccessGroupDetails(id);
    const d = r.resp || {};
    setData(d);
    setLoading(false);

    // if id is there Attach Additional Info
    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    } else {
      Toaster.error("Failed to fetch data, please try again");
      closeModalCb();
    }
  }, [attachAllData, closeModalCb, id, setAdditionalData]);

  const loadRulesList = useCallback(async () => {
    setRulesList({ loading: true, data: [] });
    const r = await UserService.getRulesList({ page: 1, count: 250 });

    // Right side selected data
    let s = Array.isArray(r.resp) ? r.resp[0] : {};

    setSelectedBlockDetails(s);

    setSelectedRule({
      value: s?._id,
      data: s,
      list: sortBy(s?.fields.filter((e) => e.name) || [], ["name"]),
    });

    // left side view Rules list
    setRulesList({
      loading: false,
      data: Array.isArray(r.resp) ? sortBy(r.resp, "_id") : [],
    });
  }, []);

  const onSelectRules = useCallback((d) => {
    setSelectedBlockDetails(d);
    setValue("selectedBlock", []);
    setSelectedRule({
      value: d?._id,
      data: d,
      list: sortBy(d?.fields.filter((e) => e.name) || [], ["name"]),
    });
  }, []);

  const setSelectedBlockDetails = (dataList) => {
    // Auto fill selected Data Starts
    let sp = (dataList?.fields || []).map((e) => {
      let en = false;
      let st = "";
      if (e.groups.length) {
        let f = e.groups.filter((g) => {
          return g.id == groupIdRef.current;
        });
        if (f.length) {
          en = true;
          st = f[0].permission;
        }
      }
      return { isEnabled: en, status: st };
    });
    setValue("selectedBlock", sp);
    // Auto fill selected Data Ends
  };

  const selectAllChange = useCallback((chngFn) => (val) => {
    chngFn(val);
    setValue("allStatus", val ? "R" : "");
    let l = selectedRule?.list.map(() => {
      return { isEnabled: val, status: val ? "R" : "" };
    });
    setValue("selectedBlock", [...l]);
  });

  const onSelectionIndex = useCallback(
    (chngFn, index) => (val) => {
      chngFn(val);
      setValue(`selectedBlock.${index}.isEnabled`, val);

      setValue(`selectedBlock.${index}.status`, val ? "R" : "");
      if (getValues("allStatus")) {
        setValue("allStatus", "");
        setValue("isAllEnabled", false);
        let l = selectedRule?.list.map(() => {
          return { isEnabled: false, status: "" };
        });
        setValue("selectedBlock", [...l]);
      }
      trigger("selectedBlock");
    },
    [setValue]
  );

  const cancelRules = async () => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to Cancel the changes",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }
    closeModalCb();
  };

  const saveRules = async () => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to Save the changes and Close modal",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    let sBlock = getValues("selectedBlock");
    let f = selectedRule.list.map((m, i) => {
      let grp = [...m.groups];
      let glist = grp.filter((e) => {
        return e.id == groupIdRef.current;
      });
      // Checking with existing data and selected data to prepare params based on selectedBlock and selectedRules
      if (!glist.length && sBlock[i].isEnabled) {
        grp.push({
          id: groupIdRef.current,
          permission: sBlock[i]?.status,
        });
      } else if (glist.length && sBlock[i].isEnabled) {
        grp.forEach((e) => {
          e.id == groupIdRef.current, (e.permission = sBlock[i]?.status);
        });
      } else {
        grp.splice(
          glist.findIndex((e) => e.id == groupIdRef.current),
          1
        );
      }
      return {
        ...m,
        groups: grp,
      };
    });

    let p = {
      _id: selectedRule.value,
      fields: f,
    };
    setBusyLoader(true);
    const r = UserService.updateRuleById(selectedRule.value, p);
    setBusyLoader(false);
    if (r.resp?._id) {
      Toaster.error(r.error?.message || "Unable to Update, Please Try Again");
      return;
    } else {
      Toaster.success("Rules Updated Successfully");
      closeModalCb();
    }
  };

  const onPermissionChange = () => {
    let v = getValues("allStatus");

    let l = selectedRule?.list.map(() => {
      return { isEnabled: true, status: v };
    });
    setValue("selectedBlock", [...l]);
    trigger("selectedBlock");
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            {/* Canvas Title */}
            <div className="page-title"> View Group Details - {data?._id} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <>
              {/* Page Loader */}
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {/* Basic Details */}
                  <AppCard title="Basic Details">
                    <div className="row">
                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Group Name">
                          <span className="text-primary"> {data.name}</span>
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Group ID">
                          {data._id}
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Feature">
                          {data?.feature || "N/A"}
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Is Internal Group">
                          <HighlightText
                            template={2}
                            type={data.isInternalGroup ? "success" : "danger"}
                          >
                            {data.isInternalGroup ? "Yes" : "No"}
                          </HighlightText>
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Created At">
                          <DateFormatter date={data.createdAt} />
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Created By">
                          {data?._createdBy?.name || "N/A"}
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Last Updated At">
                          <DateFormatter date={data.lastUpdated} />
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Last Updated By">
                          {data?._lastUpdatedBy?.name || "N/A"}
                        </KeyVal>
                      </div>

                      <div className="col-12 mb-4">
                        <KeyVal template="col" label="Description">
                          {data?.description || "N/A"}
                        </KeyVal>
                      </div>
                    </div>
                  </AppCard>

                  {/* Role Access Permissions */}

                  <div className="d-none">
                    <AppCard title="Role Access Permissions">
                      {rulesList.loading ? (
                        <Spinner></Spinner>
                      ) : (
                        <div className="row">
                          {/* Rules List */}
                          <div className="col-3 border rounded-1 p-0 overflow-hidden">
                            {rulesList.data?.length > 0 ? (
                              <>
                                {rulesList.data.map((e, i) => (
                                  <div
                                    key={i}
                                    className={classNames("p-2", {
                                      "bg-primary text-light":
                                        selectedRule?.value == e._id,
                                    })}
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => {
                                      onSelectRules(e);
                                    }}
                                    style={{
                                      borderBottom:
                                        i == rulesList.data?.length - 1
                                          ? "none"
                                          : "1px solid #e9e6e6",
                                    }}
                                  >
                                    {e?._id}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <NoDataFound />
                            )}
                          </div>

                          {/* Permission block */}
                          <div className="col-9 ps-3">
                            <div className="border rounded-1 ">
                              {/* Cancel Submit Btn */}
                              <div className="row p-3 ">
                                <div className="col-6">
                                  <button
                                    className="btn  btn-sm btn-outline-danger"
                                    onClick={cancelRules}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="col-6 text-end">
                                  <button
                                    className="btn  btn-sm btn-success"
                                    onClick={saveRules}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>

                              {/* block header*/}
                              <div className="row py-3 m-0 border border-bottom-0 bg-light">
                                <div className="col-7 text-center border-right">
                                  Field
                                </div>
                                <div className="col-2 text-center border-right">
                                  <div className="text-center ps-5">
                                    {" "}
                                    <Controller
                                      control={control}
                                      name="isAllEnabled"
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <BooleanCheckboxInput
                                          name="isAllEnabled"
                                          isMandatory={false}
                                          callback={selectAllChange(onChange)}
                                          value={value}
                                        />
                                      )}
                                    />
                                  </div>
                                  Is Enabled
                                </div>
                                <div className="col-3 text-center">
                                  Permission
                                  <div>
                                    <SelectInput
                                      register={register}
                                      options={permissionOptions}
                                      name="allStatus"
                                      disabled={!isAllEnabled}
                                      callback={onPermissionChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/*Block  List */}
                              {selectedRule?.list?.length > 0 ? (
                                <>
                                  {selectedRule?.list?.map((e, index) => (
                                    <div className="row p-1" key={index}>
                                      <div className="col-7 ps-2">
                                        {e?.name}
                                      </div>
                                      <div className="col-2 ps-5 text-center border-right">
                                        <Controller
                                          control={control}
                                          name={`selectedBlock.${index}.isEnabled`}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <BooleanCheckboxInput
                                              name={`selectedBlock.${index}.isEnabled`}
                                              isMandatory={false}
                                              callback={onSelectionIndex(
                                                onChange,
                                                index
                                              )}
                                              value={value}
                                            />
                                          )}
                                        />
                                      </div>

                                      <div className="col-3 text-center">
                                        <SelectInput
                                          error={
                                            errors?.[`selectedBlock.${index}`]
                                              ?.message
                                          }
                                          register={register}
                                          options={permissionOptions}
                                          name={`selectedBlock.${index}.status`}
                                          disabled={
                                            getValues(
                                              `selectedBlock.${index}.isEnabled`
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </AppCard>
                  </div>
                </>
              )}
            </>
          ) : null}
          <BusyLoader show={busyLoader} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewGroupsModal);
