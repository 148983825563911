import { BatchService, CommonService, appConfigs } from "@sk/services";
import {
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "30%",
};

const ViewDetailsModal = ({ show, id, closeModal }) => {
  const [display, setDisplay] = useState("loading");
  const [data, setData] = useState({});

  const download = (assetId) => {
    let url = appConfigs.ASSET + "/" + assetId;
    window.open(url, "_blank");
  };

  useEffect(() => {
    const getHsnDetails = async (id) => {
      let m = await BatchService.getList({ filter: { _id: id } });
      if (m?.resp.length) {
        const d = await CommonService.attachUserInfo(
          [m?.resp[0] || {}],
          ["createdBy", "modifiedBy"]
        );
        const r = d[0] || {};
        setData(r);
        setDisplay("details");
      } else {
        Toaster.error("Invalid Id");
        setDisplay("notFound");
        setData({});
      }
    };
    if (id && show) {
      setDisplay("loading");
      getHsnDetails(id);
    } else {
      setDisplay("details");
    }
  }, [id, show]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle title="Bulk Upload Details" />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-3">
          {show ? (
            <>
              {display == "loading" ? <PageLoader animation="border" /> : null}

              {/* DETAILS */}
              {display == "details" ? (
                // <AppCard>
                <div className="row p-3">
                  {/*Ref ID */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Upload Ref Id">
                      {data?._id}
                    </KeyVal>
                  </div>

                  {/*Template Code */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Template Code">
                      {data?.templateCode}
                    </KeyVal>
                  </div>

                  {/* Status */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Status">
                      <HighlightText status={data?.status} isBadge={false} />
                    </KeyVal>
                  </div>

                  {/* created At */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Created At">
                      <DateFormatter
                        date={data.createdAt}
                        format="dd MMM yyyy hh:mm a"
                      />
                    </KeyVal>
                  </div>

                  {/* created By */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Created By">
                      {data?._createdBy?.name}
                    </KeyVal>
                  </div>

                  {/*Uploaded File */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Download Uploaded File">
                      <button
                        className="btn btn-sm btn-outline-primary mt-1"
                        onClick={() => download(data?.inputFilePath)}
                      >
                        Click Here
                      </button>
                    </KeyVal>
                  </div>

                  {/*Uploaded File */}
                  <div className="col-6 mb-4">
                    <KeyVal template="col" label="Download Output Result File">
                      <button
                        className="btn btn-sm btn-outline-primary mt-1"
                        onClick={() => download(data?.outputFilePath)}
                      >
                        Click Here
                      </button>
                    </KeyVal>
                  </div>
                </div>
              ) : // </AppCard>
              null}

              {/* NO RECORD */}
              {display === "notFound" && (
                <NoDataFound> No Data Found </NoDataFound>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewDetailsModal);
