import { AppCard, TextareaInput, TextInput } from "@sk/uis";
import { useFormContext } from "react-hook-form";

const WhatsAppTemplateBody = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <AppCard title="Body">
      <div className="fs-val-md text-secondary mb-2">
        The WhatsApp message in the language you have selected
      </div>

      <TextareaInput
        register={register}
        name="body"
        isMandatory={true}
        placeholder="Enter Body"
        maxLength={1024}
        note="Max 1024 characters"
        error={errors.body?.message}
      />

      <TextInput
        register={register}
        name="bodyDynamicSample"
        placeholder="e.g., 100,120"
        label="Sample values for the dynamic values. For example, if {{count}} and {{age}} are used in the message, then provide 10,18 in the input."
      />
    </AppCard>
  );
};

export default WhatsAppTemplateBody;
