import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, UserService } from "@sk/services";
import { Alert, AppliedFilterLabel, PageInfo, Rbac, Toaster } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Filter from "./components/Filter";
import Table from "./components/Table";
import ManageGroupsModal from "./modals/ManageGroupsModal";
import ViewGroupsModal from "./modals/ViewGroupsModal";
import { AppCard } from "@sk/uis";

const breadcrumb = [
  { link: "/auth/init", name: "Home" },
  { name: "Group List" },
];

const defaultPaginationOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

// Applied Filter COnfig
const formLabelsConfig = {
  groups: {
    label: "Groups",
    valuePath: "[0]['label']",
  },
  type: {
    label: "Type",
  },
};

// Default Sorting Option
const defaultSortOpt = { key: "createdAt", value: "desc" };

// Additional Data To attach
const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// Default filterFormData Option
const defaultFormDataOpt = { groups: "", type: "" };

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };
  let d = filter || {};

  if (d.groups) {
    p.filter._id = { $regex: d.groups, $options: "i" }; // Case-insensitive regex search
  }

  if (d.type) {
    p.filter.type = d.type;
  }

  if (sort.key) {
    p.sort = {
      [sort.key]: sort.value == "desc" ? -1 : 1,
    };
  }
  return p;
};

// Get Groups Data
const getData = async (params) => {
  const r = await UserService.getGroupsList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await UserService.getGroupsListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Rbac
const rbac = {
  addButton: ["AddGroup"],
};

const ListGroups = () => {
  // To store All Data from API
  const [data, setData] = useState([]);

  // To set Loading State while Calling API
  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To show View Group Modal
  const [showViewGroupsModal, setShowViewGroupsModal] = useState(false);

  // To Handle Manage Groups Modal
  const [showManageGroupsModal, setShowManageGroupsModal] = useState(false);

  // Custom HOOK For To attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // Pagination  Ref
  const paginationRef = useRef({ ...defaultPaginationOpt });

  // Sorting  Ref
  const sortRef = useRef({ ...defaultSortOpt });

  // Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormDataOpt });

  // To Store Manage Modal Data
  const manageModalDataRef = useRef({ id: "", mode: "add", data: {} });

  // Use Navigate Hook
  const navigate = useNavigate();

  // Calling Use Effect Initial Time
  useEffect(() => {
    init();
  }, [init]);

  // Apply Filter
  const applyFilter = useCallback(async () => {
    // Resetting Pagination
    paginationRef.current = { ...defaultPaginationOpt };

    prepareFilterLabels();
    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  // init Function
  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  // To Load List
  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Prepare Filter Label
  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  // Get Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // Filter Form callback
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      init();
    },
    [init]
  );

  // To close View Group Modal
  const closeViewGroupsModalCb = useCallback(() => {
    setShowViewGroupsModal(false);
    manageModalDataRef.current.data = {};
  }, []);

  // To Open View Group Modal
  const viewViewGroupsModalCb = useCallback((x) => {
    manageModalDataRef.current.data = x;
    setShowViewGroupsModal(true);
  }, []);

  // To Close Manage Group Modal
  const closeManageGroupsModal = useCallback(
    (d) => {
      setShowManageGroupsModal(false);
      manageModalDataRef.current.data = {};
      if (d?.status == "submit") {
        init();
      }
    },
    [init]
  );

  // To Open Manage Group Modal For Edit
  const editManageGroupsModalCb = useCallback((x) => {
    manageModalDataRef.current.mode = "edit";
    setShowManageGroupsModal(true);
    manageModalDataRef.current.data = x;
  }, []);

  // To Open Manage Group Modal For Edit
  const addGroupsAccess = useCallback(() => {
    manageModalDataRef.current.mode = "add";
    setShowManageGroupsModal(true);
  }, []);

  // Sort Callback
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // To Delete Access Group
  const deleteAccessGroup = useCallback(
    async (id) => {
      // asking confirm to delete
      let res = await Alert.confirm({
        title: "Please confirm",
        text: "Are you sure you want to delete the Group " + id + " ?",
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      setLoadingData(true);
      const r = await UserService.deleteAccessGroup(id);
      setLoadingData(false);

      if (r.statusCode == 200) {
        Toaster.success("Access Group Deleted Successfully");
        init();
      } else {
        Toaster.error("Oops, Something Went Wrong, Please Try Later");
      }
    },
    [init]
  );

  return (
    <>
      {/* Page Info */}
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Manage Groups"
            breadcrumbs={breadcrumb}
            navigate={navigate}
          />
        </div>
        <div className="col-auto my-2">
          {/* Add Button */}
          <Rbac roles={rbac.addButton}>
            <button className="btn btn-primary" onClick={addGroupsAccess}>
              <span className="bi bi-plus-square me-1"></span>
              Add Group
            </button>
          </Rbac>
        </div>
      </div>

      <AppCard>
        {/* Filter  */}
        <Filter callback={filterFormCb} />
      </AppCard>

      <AppCard>
        {/* Applied Filter Labels */}
        {filterLabels.length > 0 ? (
          <div className="mb-2">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}

        {/* Table Component */}
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          viewCb={viewViewGroupsModalCb}
          editCb={editManageGroupsModalCb}
          sort={sortRef.current}
          sortCb={sortCb}
          deleteCb={deleteAccessGroup}
        />
      </AppCard>

      {/* View Group Modal Component */}
      <ViewGroupsModal
        show={showViewGroupsModal}
        closeModalCb={closeViewGroupsModalCb}
        id={manageModalDataRef.current.data._id}
      />

      {/* Manage Group Modal */}
      <ManageGroupsModal
        closeModal={closeManageGroupsModal}
        show={showManageGroupsModal}
        mode={manageModalDataRef.current.mode}
        modalData={manageModalDataRef.current.data}
      />
    </>
  );
};

export default ListGroups;
