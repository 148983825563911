import { AppSlide, AppSwiper } from "@sk/uis";

const nameCircleStyle = {
  width: "35px",
  height: "35px",
};

const ProjectTaskCommentItem = ({ comment = {}, handleDownloadAttachment }) => {
  return (
    <div className="comment-item py-3">
      <div className="d-flex align-items-start">
        <div className="me-3">
          <div
            className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
            style={nameCircleStyle}
          >
            <span className="fs-val-lg">{comment.userFirstLetter}</span>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center">
              <div className="fs-val-md fw-semibold">{comment.createdBy}</div>
              <div className="text-muted fs-val-sm ms-2">•</div>
              <div className="text-muted fs-val-sm ms-2">
                {comment.createdAgo}
              </div>
            </div>
          </div>
          <div className="mb-2 fs-val-md">{comment.text}</div>
          {comment.documents?.length > 0 && (
            <div className="attachments fs-val-sm bg-light rounded p-2">
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-paperclip me-1"></i>
                <strong>Attachments</strong>
                <span className="ms-2 badge bg-secondary">
                  {comment.documents.length}
                </span>
              </div>
              <AppSwiper
                config={{
                  slidesPerView: "auto",
                  spaceBetween: 8,
                  watchOverflow: true,
                }}
              >
                {comment.documents.map((attachment, index) => (
                  <AppSlide key={attachment.assetId} autoWidth>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm fs-val-sm d-flex align-items-center"
                      onClick={() =>
                        handleDownloadAttachment(attachment.assetId)
                      }
                    >
                      <i className="bi bi-file-earmark me-1"></i>
                      <span>Attachment {index + 1}</span>
                    </button>
                  </AppSlide>
                ))}
              </AppSwiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectTaskCommentItem;
