import { NotificationService } from "@sk/services";
import { AppCard, AppTitle, KeyVal, NoDataFound, PageLoader } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "35%" };

const NotifLogsViewModal = ({ show, callback, logData, id }) => {
  const [data, setData] = useState({});

  const [display, setDisplay] = useState("loading");

  useEffect(() => {
    if (show && id && id.length) {
      loadDetails();
    } else {
      setData({});
      setDisplay("noDataFound");
    }
  }, [id, loadDetails, show]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await NotificationService.getDetailsById(id);
    setData(r?.resp ? r?.resp : {});
    if (r?.resp?._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [id]);

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Notification Logs Details #" + id} />
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4 border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <>
              <div className="fw-semibold fs-val-lg mb-2">Basic Details</div>

              <div className="row mb-4">
                <div className="col-12 mb-4 mt-3">
                  <KeyVal label="Transaction Id" template="col">
                    <span className="fs-bold"> {data?.refId || "N/A"}</span>
                  </KeyVal>
                </div>

                {data?.default?.subject?.length ? (
                  <div className="col-12 mb-4">
                    <AppCard title="Subject">
                      {data?.default?.subject || "N/A"}
                    </AppCard>
                  </div>
                ) : null}

                {data?.default?.body?.length ? (
                  <div className="col-12 mb-4">
                    <AppCard title="Body">
                      {data?.default?.body || "N/A"}
                    </AppCard>
                  </div>
                ) : null}

                {logData?.status && logData?.status == "error" ? (
                  <div className="col-12">
                    <AppCard title="Error Message">
                      {logData?.errorResponse || "N/A"}
                    </AppCard>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(NotifLogsViewModal);
