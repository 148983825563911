import { NotificationService } from "@sk/services";
import { AppCard, DateInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const statusOptions = [
  { value: "", label: "All Status" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const WhatsappTemplateFilter = ({ callback }) => {
  const { register, handleSubmit, setValue, control, getValues } = useForm({
    defaultValues: {
      status: "",
      partner: "",
      startDate: "",
      endDate: "",
    },
  });
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      setLoading(true);
      try {
        const response = await NotificationService.getPartner({
          filter: {
            service: "WhatsApp",
          },
          select: "name,key",
        });
        if (Array.isArray(response.resp)) {
          const partnerOptions = [
            { value: "", label: "All Partners" },
            ...response.resp.map((p) => ({ value: p.key, label: p.name })),
          ];
          setPartners(partnerOptions);
        } else {
          setPartners([]);
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, [setValue]);

  const debouncedSearch = useCallback(
    debounce(() => {
      triggerCallback();
    }, 800),
    []
  );

  const onSearch = () => {
    debouncedSearch();
  };

  const onInpChange = () => {
    triggerCallback();
  };

  const triggerCallback = () => {
    callback({
      action: "apply",
      data: getValues(),
    });
  };

  return (
    <AppCard>
      <div className="row g-3">
        {loading ? (
          <div className="col-12">
            <p>Loading partners...</p>
          </div>
        ) : (
          <>
            <div className="col">
              <TextInput
                name="search"
                label="Search Title / ID"
                register={register}
                placeholder="Enter Title / ID"
                callback={onSearch}
                gap={0}
              />
            </div>
            <div className="col">
              <SelectInput
                name="partner"
                options={partners}
                register={register}
                label="Partner"
                callback={onInpChange}
                gap={0}
              />
            </div>
            <div className="col">
              <SelectInput
                name="status"
                options={statusOptions}
                register={register}
                label="Status"
                callback={onInpChange}
                gap={0}
              />
            </div>
            <div className="col">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    callback={field.onChange}
                    value={field.value}
                    label="Created On"
                    placeholder="Select Created On"
                    gap={0}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </AppCard>
  );
};

export default WhatsappTemplateFilter;
