import { NavService } from "@sk/services";
import {
  Alert,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useCallback } from "react";
import listView from "../../constantService";

const rbac = {
  editButton: ["EditNotificationTemplate"],
  viewButton: ["ViewNotificationTemplate"],
};

const NotifTempTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  callback,
}) => {
  const editDetails = (id) => {
    NavService.openInNewTab("/utility/notifications/manage", { id: id });
  };

  const updateStatus = useCallback(
    async (data) => {
      let type = data.isActive ? "Inactive" : "Active";
      let res = await Alert.confirm({
        title: "Please confirm",
        text: "Do you want to " + type + " the Template - " + data._id,
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }
      callback({ status: "updateStatus", data: data });
    },
    [callback]
  );

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Notification Template Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* ID */}
                <td>
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      viewCb(x?._id);
                    }}
                  >
                    {x._id}
                  </span>
                </td>

                {/* Name */}
                <td>
                  <div
                    className="key-val fw-semibold mb-1"
                    onClick={() => {
                      viewCb(x?._id);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {x?.name || "N/A"}
                  </div>
                </td>

                {/* mode */}
                <td>{x?.mode}</td>

                {/* Business Entity */}
                <td>{x?.businessEntity}</td>

                {/* SMS Partner   */}
                <td>{x?.partner || "N/A"}</td>

                {/* Languages  */}
                <td>
                  {x._languageName || "N/A"}
                  {/* {x.languages?.length ? (
                    <div>
                      {x?.languages.map((e, k) => (
                        <span key={k}>{e}</span>
                      ))}
                    </div>
                  ) : (
                    "N/A"
                  )} */}
                </td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>by : {x?._createdBy?.name || "N/A"}</div>
                </td>

                {/* Last Updated */}
                <td>
                  <DateFormatter date={x.lastUpdated} />
                  <div>by : {x?._modifiedBy?.name || "N/A"}</div>
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText
                    status={x.isActive ? "Active" : "Inactive"}
                    isBadge={true}
                  />

                  <Rbac roles={rbac.editButton}>
                    <div className="text-center">
                      <button
                        className={classNames({
                          "btn btn-sm btn-link me-1 mt-2 fs-val-xs": true,
                          "text-success": x?.isActive ? false : true,
                          "text-danger": x?.isActive,
                        })}
                        onClick={() => updateStatus(x)}
                      >
                        {x?.isActive ? "Mark As Inactive" : "Mark As Active"}
                      </button>
                    </div>
                  </Rbac>
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewCb(x?._id);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => {
                        editDetails(x?._id);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(NotifTempTable);
