import totalImg from "../../assets/imgs/total.svg";
import failedImg from "../../assets/imgs/inactive.svg";
import completedImg from "../../assets/imgs/completed.svg";
import pendingImg from "../../assets/imgs/pending.svg";
import inProgressImg from "../../assets/imgs/in-progress.svg";
import partiallyCompletedImg from "../../assets/imgs/partially-completed.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Zoho Sync Jobs",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
      key: "total",
      isClickable: true,
    },
    {
      label: "Started",
      value: 0,
      loading: true,
      filter: { status: "Started" },
      color: "success",
      img: completedImg,
      isClickable: true,
    },
    {
      label: "In Progress",
      value: 0,
      loading: true,
      filter: { status: "In Progress" },
      color: "warning",
      img: inProgressImg,
      isClickable: true,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "warning",
      img: pendingImg,
      isClickable: true,
    },
    {
      label: "Partially  Completed",
      value: 0,
      loading: true,
      filter: { status: "Partially Completed" },
      color: "warning",
      img: completedImg,
      isClickable: true,
    },
    {
      label: "Completed",
      value: 0,
      loading: true,
      filter: { status: "Completed" },
      color: "success",
      img: completedImg,
      isClickable: true,
    },
    {
      label: "Failed",
      value: 0,
      type: "",
      loading: true,
      filter: { status: "Failed" },
      color: "danger",
      img: failedImg,
      isClickable: true,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    jobType: {
      label: "Job Type",
    },
    _id: {
      label: "ID",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    status: "",
    jobType: "",
    id: "",
    createdAt: [new Date(), new Date()],
  },
  // Filter From Data Ends Here
};

export default listView;
