import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DateInput } from "@sk/uis";

const ProjectTaskFilterModal = ({ show, callback }) => {
  const { control, reset, getValues } = useForm({
    defaultValues: {
      startDate: [],
      endDate: [],
      dueDate: [],
    },
  });

  const onClose = () => {
    callback({ action: "close" });
  };

  const onReset = () => {
    reset();
    onApply();
  };

  const onApply = () => {
    callback({ action: "apply", data: getValues() });
  };

  return (
    <Modal show={show} onHide={onClose} enforceFocus={false} autoFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateInput
                  label="Start Date"
                  placeholder="Select start date"
                  callback={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="col-12">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateInput
                  label="End Date"
                  placeholder="Select end date"
                  callback={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="col-12">
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <DateInput
                  label="Due Date"
                  placeholder="Select due date"
                  callback={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={onReset}>
          Reset
        </button>
        <button className="btn btn-primary" onClick={onApply}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectTaskFilterModal;
