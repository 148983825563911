import { CommonService, NavService, ProjectService } from "@sk/services";
import { AppCard, PageInfo, Rbac, SummaryCard } from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import ProjectFilter from "./components/ProjectFilter";
import ProjectManageModal from "./modals/ProjectManageModal";
import ProjectTable from "./components/ProjectTable";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

const rbac = {
  addProject: ["AddProject"],
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Project List",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const defaultFilterData = {
  projectStatus: "InProgress",
};

const defaultFilterLabels = {
  status: {
    label: "Status",
  },
  projectType: {
    label: "Project Type",
    valuePath: "[0].value.name",
  },
  projectManager: {
    label: "Project Manager",
    valuePath: "[0].value.name",
  },
  createdAt: {
    label: "Date Range",
    type: "dateRange",
    canRemove: true,
    resetVal: [],
  },
};

const defaultSummaryData = [
  {
    label: "Total",
    value: 0,
    color: "dark",
    key: "total",
  },
  {
    label: "Todo",
    value: 0,
    color: "primary",
    key: "todo",
  },
  {
    label: "In Progress",
    value: 0,
    color: "warning",
    key: "inProgress",
  },
  {
    label: "Completed",
    value: 0,
    color: "success",
    key: "completed",
  },
  {
    label: "On Hold",
    value: 0,
    color: "danger",
    key: "onHold",
  },
  {
    label: "Backlog",
    value: 0,
    color: "warning",
    key: "backlog",
  },
];

const getData = async (params) => {
  const r = await ProjectService.getList(params);
  return { data: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await ProjectService.getCount(p);
  return {
    count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    sort: {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    },
  };

  const s = (filter?.search || "").trim();

  if (filter?.projectFor) {
    p.filter.projectFor = filter.projectFor;
  }

  if (s) {
    p.filter.$or = [
      { _id: { $regex: s, $options: "gi" } },
      { name: { $regex: s, $options: "gi" } },
    ];
  }

  if (filter?.projectType?.length > 0) {
    const id = filter.projectType[0].value._id;
    p.filter["projectType"] = id;
  }

  if (filter?.projectManager?.length > 0) {
    const id = filter.projectManager[0].value._id;
    p.filter["projectManager"] = id;
  }

  if (filter?.projectStatus) {
    p.filter.status = filter.projectStatus;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  // Handle startDate and endDate
  if (filter?.startDate?.length) {
    p.filter.startDate = {
      $gte: set(filter.startDate[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };
  }

  if (filter?.endDate?.length) {
    p.filter.endDate = {
      $lte: set(filter.endDate[0], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.mainStatus == "inProgress") {
    p.filter.status = { $in: ["Todo", "InProgress"] };
  }
  if (filter.mainStatus == "blocked") {
    p.filter.status = { $in: ["OnHold"] };
  }
  if (filter.mainStatus == "backlog") {
    p.filter.status = { $in: ["Backlog"] };
  }
  if (filter.mainStatus == "all") {
    delete p.filter.status;
  }

  if (!Object.keys(p.filter).length) {
    delete p.filter;
  }

  return p;
};

const ProjectList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageModal, setManageModal] = useState({ show: false, editId: "" });

  const [summary, setSummary] = useState([...defaultSummaryData]);

  const [resetFilterFormData, setResetFilterFormData] = useState();

  const [mainStatuses, setMainStatuses] = useState([
    {
      value: "all",
      label: "All Projects",
      checked: false,
    },
    {
      value: "inProgress",
      label: "In Progress Projects",
      checked: true,
    },
    {
      value: "blocked",
      label: "On Hold Projects",
      checked: false,
    },
    {
      value: "backlog",
      label: "Backlog Projects",
      checked: false,
    },
  ]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const sortRef = useRef({
    key: "endDate",
    value: "asc",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    prepareFilterLabels();

    loadList();

    loadSummary();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const loadSummary = async () => {
    let p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    delete p.page;
    delete p.count;

    let arr = [
      getCount(merge({}, p)),
      getCount(merge({}, p, { filter: { status: "Todo" } })),
      getCount(merge({}, p, { filter: { status: "InProgress" } })),
      getCount(merge({}, p, { filter: { status: "Completed" } })),
      getCount(merge({}, p, { filter: { status: "OnHold" } })),
      getCount(merge({}, p, { filter: { status: "Backlog" } })),
    ];

    const r = await Promise.all(arr);

    setSummary(
      produce((draft) => {
        draft.forEach((e, k) => {
          draft[k].value = r[k]?.count;
        });
      })
    );
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const manageModalCb = (e) => {
    if (e.action == "submitted") {
      applyFilter();
    }
    setManageModal({ show: false, editId: "" });
  };

  const tblCb = (e) => {
    if (e.action == "edit") {
      setManageModal({
        show: true,
        editId: e.id,
      });
    }

    if (e.action == "view") {
      NavService.to(navigate, `/utility/project-management/tasks`, {
        id: e.id,
      });
    }
  };

  const onFilterCb = (e) => {
    if (e.action == "apply" || e.action == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...e.formData };
      applyFilter();
    }
  };

  const prepareFilterLabels = () => {
    const v = { ...filterDataRef.current };

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }

    const l = CommonService.prepareAppliedFilterLabels(defaultFilterLabels, v);

    setFilterLabels(l);
  };

  const sortCb = (e) => {
    sortRef.current = { key: e.key, value: e.value };
    applyFilter();
  };

  const handleMainStatusChange = (v) => {
    setMainStatuses(
      produce((draft) => {
        draft.forEach((e) => {
          e.checked = e.value == v;
        });
      })
    );
    filterDataRef.current = {
      ...filterDataRef.current,
      mainStatus: v,
    };

    setResetFilterFormData({ status: true, fields: { projectStatus: "" } });
    const t = setTimeout(() => {
      clearTimeout(t);
      setResetFilterFormData({ status: false });
    }, 800);

    applyFilter();
  };

  return (
    <>
      <div className="row align-items-center mt-3 mb-4">
        <div className="col-auto">
          <PageInfo
            title="Project List"
            breadcrumbs={breadcrumbs}
            noMargin={true}
          />
        </div>
        <div className="col text-center">
          <ButtonGroup size="sm">
            {mainStatuses.map((status) => (
              <ToggleButton
                key={status.value}
                id={`radio-${status.value}`}
                type="radio"
                variant="outline-secondary"
                name="task-status"
                checked={status.checked}
                value={status.value}
                onChange={() => handleMainStatusChange(status.value)}
              >
                {status.checked && <i className="bi bi-check me-1"></i>}
                {status.label}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </div>

      <div className="row">
        {summary.map((e) => (
          <div className="col col-auto" key={e.key}>
            <SummaryCard
              value={e.value || 0}
              title={e.label}
              template={3}
              isValueAmt={e.isValueAmt}
              amtDecimalPlace={0}
              valueColor={e.color}
            />
          </div>
        ))}
        <div className="col align-self-center text-end">
          <Rbac roles={rbac.addProject}>
            <button
              className="btn btn-primary mb-3"
              onClick={() => setManageModal({ show: true })}
            >
              <i className="bi bi-plus-square bg-lg"></i>
              <span> Create Project </span>
            </button>
          </Rbac>
        </div>
      </div>

      <div>
        <ProjectFilter callback={onFilterCb} resetData={resetFilterFormData} />
      </div>

      <AppCard>
        <ProjectTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          callback={tblCb}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>

      <ProjectManageModal
        show={manageModal.show}
        editId={manageModal.editId}
        callback={manageModalCb}
      />
    </>
  );
};

export default ProjectList;
