import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Bulk Upload",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      color: "primary",
      img: totalImg,
      key: "TOTAL",
    },
    {
      label: "Processing",
      value: 0,
      loading: true,
      color: "success",
      img: activeImg,
      key: "PROCESSING",
    },
    {
      label: "Partially Processed",
      value: 0,
      loading: true,
      color: "danger",
      img: activeImg,
      key: "PARTIALLYPROCESSED",
    },
    {
      label: "Completed",
      value: 0,
      loading: true,
      color: "danger",
      img: activeImg,
      key: "COMPLETED",
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      color: "danger",
      img: inactiveImg,
      key: "PENDING",
    },
  ],
  //Summary Card Data Ends Here
  statusOptions: [
    { label: "All Status", value: "" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Partially Processed", value: "PARTIALLYPROCESSED" },
    { label: "Pending", value: "PENDING" },
    { label: "Processing", value: "PROCESSING" },
  ],

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    templateCode: {
      label: "Template Code",
      valuePath: "[0].label",
    },
    status: {
      label: "Status",
    },
    dateRange: {
      label: "Created Date Range",
      type: "dateRange",
    },
    createdBy: {
      label: "Created By",
      valuePath: "[0].label",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    status: "",
    dateRange: [],
    templateCode: [],
    createdBy: [],
  },
};
// LIST VIEW EDS HERE
export default listView;
