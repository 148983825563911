import { WhatsAppTemplatePreview } from "@sk/features";
import { AppCard } from "@sk/uis";
import { useFormContext, useWatch } from "react-hook-form";

const WhatsAppTemplatePreviewContainer = () => {
  const { control } = useFormContext();

  const [headerText, body, footer, actions, headerImage] = useWatch({
    control,
    name: ["headerText", "body", "footer", "actions", "headerImage"],
  });

  return (
    <AppCard>
      <WhatsAppTemplatePreview
        headerText={headerText}
        body={body}
        footer={footer}
        actions={actions}
        headerImage={headerImage}
      />
    </AppCard>
  );
};

export default WhatsAppTemplatePreviewContainer;
