import { NotificationService } from "@sk/services";
import {
  AppTitle,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { detailsModalView } from "../../constantService";

const canvasStyle = { width: "40%" };

const NotifEventsViewModal = ({ show, id, callback }) => {
  const [display, setDisplay] = useState("loading");

  const [rules, setRules] = useState([]);

  const [data, setData] = useState({});

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && id) {
      loadTemplateDetails(id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadTemplateDetails, id, show]);

  const loadTemplateDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await NotificationService.getEventsList({ filter: { _id: id } });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    setData(d);

    if (d._id) {
      let ids = d.rules.map((e) => {
        return e.template;
      });
      loadTemplatesByIds(ids);

      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [id, loadTemplatesByIds]);

  const loadTemplatesByIds = useCallback(async (ids) => {
    setDisplay("loading");
    let p = { filter: { _id: { $in: ids } } };
    const r = await NotificationService.getList(p);
    setRules(Array.isArray(r.resp) ? r.resp : []);
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Notification Event Details #" + id} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <div className="mb-3">
              <div className="fw-semibold fs-val-lg mb-2">Basic Details</div>

              <div className="row mb-4">
                <div className="col-7">
                  <KeyVal label="Event ID" template="col">
                    {data._id}
                  </KeyVal>
                </div>

                <div className="col-5">
                  <KeyVal label="Entity" template="col">
                    {data?.businessEntity}
                  </KeyVal>
                </div>

                <div className="col-7 my-4">
                  <KeyVal label="Event Code" template="col">
                    {data?.name}
                  </KeyVal>
                </div>

                <div className="col-5 my-4">
                  <KeyVal label="Status" template="col">
                    <HighlightText
                      status={data.isActive ? "Active" : "Inactive"}
                      isBadge={true}
                    />
                  </KeyVal>
                </div>
              </div>

              <div className="fw-semibold fs-val-lg mb-2">
                Configuration Details
              </div>

              <table className="table table-bordered bg-white">
                <TableHeader data={detailsModalView.defaultHeaders} />
                <tbody>
                  {!rules.length ? (
                    <tr>
                      <td colSpan={detailsModalView.defaultHeaders.length}>
                        <NoDataFound>Data Not Found</NoDataFound>
                      </td>
                    </tr>
                  ) : null}

                  {rules.map((x, index) => (
                    <tr key={x._id} className="fs-val-md">
                      {/* SL No */}
                      <td className="text-center">{1 + index}</td>

                      {/* Name */}
                      <td>{x?.name}</td>

                      {/* Status */}
                      <td className="text-center">
                        {
                          <HighlightText
                            status={x.isActive ? "Active" : "Inactive"}
                            isBadge={true}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NotifEventsViewModal;
