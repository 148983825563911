import { AppCard, TextInput } from "@sk/uis";
import { useFormContext } from "react-hook-form";

const WhatsAppTemplateFooter = () => {
  const { register } = useFormContext();

  return (
    <AppCard title="Footer (Optional)">
      <div className="fs-val-md text-secondary mb-2">
        Add a short line of text to the bottom of your message template.
      </div>
      <TextInput
        name="footer"
        label="Footer"
        placeholder="Enter Footer"
        maxLength={60}
        note="Max 60 characters"
        register={register}
      />
    </AppCard>
  );
};

export default WhatsAppTemplateFooter;
