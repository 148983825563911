import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";
import listView from "../../constantService";

const rbac = {
  editButton: ["EditNotificationTemplate"],
  viewButton: ["ViewNotificationTemplate"],
};

const NotifLogsTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Notification Template Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* RecepientNo */}
                <td>
                  <div>{x?.recepientNo}</div>
                  <div>
                    {x?._franchise?.contact_details?.length > 0
                      ? x?._franchise?.contact_detail?.[0]?.name
                      : ""}
                  </div>
                </td>

                {/* Type */}
                <td>{x.type}</td>

                {/* Bussiness Entity */}
                <td className="text-center">{x.entity}</td>

                {/* Employee Id */}
                <td>{x.employeeId}</td>

                {/* Franchise  */}
                <td>
                  <div
                    className="key-val fw-semibold mb-1"
                    // onClick={() => {
                    //   viewDetails(x?._id);
                    // }}
                    role="button"
                    tabIndex={0}
                  >
                    {x?._franchise?._name || "N/A"}
                  </div>
                </td>

                {/* 	Template Id */}
                <td>{x?.templateId}</td>

                {/* eventId */}
                <td>{x?.eventId}</td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>

                {/* Status */}
                <td className="text-center">
                  {
                    <HighlightText
                      status={x.status == "error" ? "Error" : "Success"}
                      isBadge={true}
                    />
                  }
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewCb(x);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(NotifLogsTable);
