import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Events Config",
    },
  ],
  // Breadcrumbs Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    isActive: {
      label: "Status",
    },
    mode: {
      label: "Mode",
    },
    businessEntity: {
      label: "Business Entity",
    },
  },

  // Form Labels Ends Here

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "ID" },
    { label: "Event Code" },
    {
      label: "Business Entity",
      isCentered: true,
    },
    { label: "No. of Rules", isCentered: true },
    { label: "Status", priority: 10, isCentered: true },
    { label: "Actions", priority: 11, isCentered: true },
  ],

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      loading: true,
      filter: { isActive: true },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      filter: { isActive: false },
      color: "danger",
      img: inactiveImg,
    },
  ],
};

export const filterView = {
  modeOptions: [
    { label: "All", value: "" },
    { label: "Email", value: "EMail" },
    { label: "In App", value: "InApp" },
    { label: "Push", value: "PUSH" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WhatsApp" },
  ],

  statusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],

  businessEntityOptions: [
    { label: "All", value: "" },
    { label: "Account", value: "account" },
    { label: "Aeps", value: "aeps" },
    { label: "Asset", value: "asset" },
    { label: "Banner", value: "banner" },
    { label: "Brand", value: "brand" },
    { label: "Category", value: "category" },
    { label: "Campaign", value: "campaign" },
    { label: "Commission", value: "commission" },
    { label: "Config", value: "config" },
    { label: "Coupons", value: "coupons" },
    { label: "Courier", value: "courier" },
    { label: "Customer", value: "customer" },
    { label: "Deal", value: "deal" },
    { label: "Digital Partner", value: "digitalPartner" },
    { label: "Dmt", value: "dmt" },
    { label: "Employee", value: "employee" },
    { label: "Franchise", value: "franchise" },
    { label: "Gateway", value: "gateway" },
    { label: "Grn", value: "grn" },
    { label: "Insurance", value: "insurance" },
    { label: "Investment", value: "investment" },
    { label: "King Alerts", value: "kingalerts" },
    { label: "King Sale", value: "kingsale" },
    { label: "Kyf", value: "kyf" },
    { label: "Lending", value: "lending" },
    { label: "Logistics", value: "logistics" },
    { label: "Medical Service", value: "Medical Service" },
    { label: "Oms", value: "oms" },
    { label: "Payment Requests", value: "paymentrequests" },
    { label: "Pg", value: "pg" },
    { label: "Po", value: "po" },
    { label: "Product", value: "product" },
    { label: "Recharge", value: "recharge" },
    { label: "Reports", value: "reports" },
    { label: "Returns", value: "returns" },
    { label: "Scheduler", value: "scheduler" },
    { label: "Seller", value: "seller" },
    { label: "Sourcing Partner", value: "sourcingPartner" },
    { label: "Ticket", value: "ticket" },
    { label: "User", value: "user" },
    { label: "Vendor", value: "vendor" },
    { label: "Wallet", value: "wallet" },
    { label: "Wh", value: "wh" },
    { label: "Widgets", value: "widgets" },
    { label: "Wypbid Request", value: "wypbidrequest" },
  ],

  filterFormData: {
    isActive: "",
    mode: "",
    businessEntity: "",
  },
};

export const detailsModalView = {
  defaultHeaders: [
    { label: "Sl No", isCentered: true },
    { label: "Name" },
    { label: "Status", priority: 10, isCentered: true },
  ],
};

export const editView = {
  modeOptions: [
    { label: "Select Mode", value: "" },
    { label: "Email", value: "EMail" },
    { label: "In App", value: "InApp" },
    { label: "Push", value: "PUSH" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WhatsApp" },
  ],

  defaultTableHeader: [
    { label: "Sl No", isCentered: true },
    { label: "Template" },
    { label: "Is Active", width: "10%", isCentered: true },
    { label: "Action", isCentered: true },
  ],

  defaultFormData: { isActive: false, template: [], mode: "" },
};

export default listView;
