import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  HighlightText,
  InfoPopover,
  AudioPlayer,
} from "@sk/uis";
import { useState } from "react";
import MarketingLogModal from "./modals/MarketingLogModal";

const headers = [
  { label: "Sl No", width: "3%", key: "slNo" },
  { label: "Ref Id", width: "10%", key: "refId" },
  {
    label: "Employee Name",
    width: "15%",
    enableSort: true,
    key: "employeeInfo.name",
  },
  { label: "Agent ID", width: "8%", key: "requestPayload.agentID" },
  {
    label: "Customer Name",
    width: "15%",
    enableSort: true,
    key: "customerInfo.name",
  },
  { label: "Call Answered", width: "10%", key: "callbackResponse.Status" },
  { label: "Call Status", width: "8%", key: "status" },
  { label: "Called On", width: "10%", enableSort: true, key: "createdAt" },
  {
    label: "Duration",
    width: "5%",
    enableSort: true,
    key: "callbackResponse.CallDuration",
  },
  { label: "Recording", width: "10%", key: "recordingUrl" },
  { label: "Logs", width: "10%", key: "logs" },
];

// Add container style
const containerStyle = {
  height: "600px",
  overflowY: "auto",
};

const tdStyle = {
  employee: {
    backgroundColor: "#f0f8ff", // Light Alice Blue color
  },
};

function MarketingLogsTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sortCb,
  sort,
  onCustomerClick,
}) {
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [logModalData, setLogModalData] = useState({
    show: false,
    data: [],
    refId: "",
  });

  // Add separate handler for audio state changes
  const handleAudioStateChange = (logId, isPlaying) => {
    setPlayingAudioId(isPlaying ? logId : null);
  };

  const handleViewLogClick = (log) => {
    setLogModalData({ show: true, data: log.auditLog || [], refId: log._id });
  };

  const handleCloseModal = () => {
    setLogModalData({ show: false, data: [], refId: "" });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Add scrollable container */}
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3"
        style={containerStyle}
      >
        <table className="table table-sm bg-white table-hover">
          <TableHeader
            data={headers}
            noBg={true}
            isSticky={true}
            sort={sort}
            sortCb={sortCb}
          />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No call logs found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((log, index) => (
              <tr key={log._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{log._id || "--"}</td>
                <td style={tdStyle.employee}>
                  {log.employeeInfo?.name || "--"} - {log.employeeInfo?.id}
                </td>
                <td>{log.requestPayload?.agentID || "--"}</td>
                <td>
                  <button
                    className="btn btn-link text-dark p-0 text-start fs-val-md"
                    onClick={() => onCustomerClick(log.customerInfo?.id)}
                  >
                    {log.customerInfo?.name || "--"} - {log.customerInfo?.id}
                  </button>
                </td>
                <td>
                  {log.callbackResponse?.Status ? (
                    <HighlightText
                      type={
                        log.callbackResponse?.Status === "Answered"
                          ? "success"
                          : "danger"
                      }
                      template={2}
                    >
                      {log.callbackResponse?.Status}
                    </HighlightText>
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <HighlightText
                    type={
                      log.status === "Success"
                        ? "success"
                        : log.status === "Processing"
                        ? "warning"
                        : "danger"
                    }
                    template={2}
                  >
                    {log.status}
                  </HighlightText>
                  {log.status == "Failed" && log?.responseLog?.data?.message ? (
                    <InfoPopover content={log?.responseLog?.data?.message} />
                  ) : null}
                </td>
                <td>
                  <DateFormatter date={log.createdAt} format="dd MMM yyyy" />
                  <span className="text-muted fs-val-sm">
                    , <DateFormatter date={log.createdAt} format="hh:mm a" />
                  </span>
                </td>
                <td>{log.callbackResponse?.CallDuration || "--"}</td>
                <td>
                  {log.audioUrl ? (
                    <AudioPlayer
                      url={log.audioUrl}
                      isPlaying={playingAudioId === log._id}
                      onStateChange={(isPlaying) =>
                        handleAudioStateChange(log._id, isPlaying)
                      }
                    />
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-link text-dark p-0 text-start fs-val-sm"
                    onClick={() => handleViewLogClick(log)}
                  >
                    <i className="bi bi-info-circle me-1"></i>
                    View Logs
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />

      <MarketingLogModal
        show={logModalData.show}
        callback={handleCloseModal}
        data={logModalData.data}
        refId={logModalData.refId}
      />
    </>
  );
}

export default MarketingLogsTable;
