import { SelectInput, SwitchInput, TextInput } from "@sk/uis";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { filterView } from "../../../constantService";
import { useCallback, useEffect, useState } from "react";
import { NotificationService } from "@sk/services";

const businessOptions = [...filterView.businessEntityOptions];
businessOptions.splice(0, 1);
businessOptions.unshift({ label: "Select", value: "" });
const businessEntityOptions = businessOptions;

const modeOpts = [...filterView.modeOptions];
modeOpts.splice(0, 1);
modeOpts.unshift({ label: "Select", value: "" });
const modeOptions = modeOpts;

const NotifTempBasicForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const mode = useWatch({ control, name: "mode" });

  const [partnerOptions, setPartnerOptions] = useState([]);

  useEffect(() => {
    if (["SMS", "WhatsApp"].indexOf(mode) != -1) {
      loadPartnerOptions(mode);
    }
  }, [mode]);

  const onModeChange = useCallback(async (mode) => {
    if (["SMS", "WhatsApp"].indexOf(mode) != -1) {
      loadPartnerOptions(mode);
    }
  }, []);

  const loadPartnerOptions = async (mode) => {
    const r = await NotificationService.getConfig({
      filter: { service: mode },
      select: "name,key",
    });
    let k = Array.isArray(r.resp) ? r.resp : [];
    let opt = k.map((e) => {
      return { label: e.name, value: e.key };
    });
    opt.unshift({ label: "Choose Partner", value: "" });
    setPartnerOptions(opt);
  };

  return (
    <>
      <div className="row mt-3 pb-3 border-bottom">
        {/* Notification Name */}
        <div className="col-3">
          <TextInput
            type="text"
            label="Notification Name"
            placeholder="Enter Notification Name"
            register={register}
            name="name"
            error={errors?.name?.message}
            isMandatory={true}
          />
        </div>

        {/* Business Entity */}
        <div className="col-2 mb-3">
          <SelectInput
            label="Business Entity"
            register={register}
            name="businessEntity"
            isMandatory={true}
            options={businessEntityOptions}
            error={errors?.businessEntity?.message}
          />
        </div>

        {/* Mode options */}
        <div className="col-2 mb-3">
          <SelectInput
            label="Mode"
            register={register}
            name="mode"
            isMandatory={true}
            options={modeOptions}
            error={errors?.mode?.message}
            callback={onModeChange}
          />
        </div>

        {/* Email Template Type */}
        {mode == "EMail" ? (
          <div className="col-3">
            <TextInput
              type="text"
              label="Email Template Type"
              placeholder="Enter Email Template Type Here"
              register={register}
              name="emailTemplateType"
              error={errors?.emailTemplateType?.message}
              isMandatory={true}
            />
          </div>
        ) : null}

        {/* Partner Options */}
        {["SMS", "WhatsApp"].indexOf(mode) != -1 && partnerOptions?.length ? (
          <div className="col-2 mb-3">
            <SelectInput
              label="Partner"
              register={register}
              name="partner"
              isMandatory={true}
              error={errors?.partner?.message}
              options={partnerOptions}
            />
          </div>
        ) : null}

        {/* Status */}
        <div className="col-1">
          <Controller
            control={control}
            name="isActive"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Active"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default NotifTempBasicForm;
