import { AuthService, ProjectService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { merge } from "lodash";
import { useEffect, useState } from "react";

const getCount = async (projectId, status, onlyMyTasks, filterData) => {
  let params = { filter: { projectId } };

  if (projectId === "all") {
    delete params.filter.projectId;
  }

  if (filterData) {
    params = merge({}, filterData, params);
  }

  if (status && status.length > 0) {
    params.filter.status = { $in: status };
  }

  if (onlyMyTasks) {
    params.filter["assignedTo.id"] = AuthService.getLoggedInEmpId();
  }

  const response = await ProjectService.getTaskCount(params);
  return {
    count:
      Array.isArray(response.resp) && response.resp.length > 0
        ? response.resp[0].total
        : 0,
  };
};

const ProjectTaskSummary = ({ projectId, onlyMyTasks, filterData }) => {
  const [summary, setSummary] = useState([
    {
      label: "Total Tasks",
      value: 0,
      loading: false,
      status: [],
      statusType: "primary",
    },
    {
      label: "To Do",
      value: 0,
      loading: false,
      status: ["Todo", "Assigned"],
      statusType: "success",
    },
    {
      label: "In Progress",
      value: 0,
      loading: false,
      status: ["InProgress", "ReOpen"],
      statusType: "warning",
    },
    {
      label: "Completed",
      value: 0,
      loading: false,
      status: ["Done", "Completed"],
      statusType: "danger",
    },
    {
      label: "Blocked",
      value: 0,
      loading: false,
      status: ["Blocked"],
      statusType: "danger",
    },
    {
      label: "Deferred",
      value: 0,
      loading: false,
      status: ["Deferred"],
      statusType: "info",
    },
  ]);

  useEffect(() => {
    const fetchSummaryData = async () => {
      setSummary((prevSummary) => {
        return prevSummary.map((item) => ({
          ...item,
          loading: true,
        }));
      });
      const promises = summary.map(async (item) => {
        return getCount(projectId, item.status, onlyMyTasks, filterData);
      });
      const counts = await Promise.all(promises);
      console.log(counts);
      setSummary((prevSummary) => {
        return prevSummary.map((item, index) => ({
          ...item,
          value: counts[index].count,
          loading: false,
        }));
      });
    };

    fetchSummaryData();
  }, [projectId, onlyMyTasks, filterData]);

  return (
    <div className="row">
      {summary.map((item, index) => (
        <div key={index} className="col">
          <SummaryCard
            template={3}
            title={item.label}
            value={item.value}
            valueColor={item.statusType}
            loading={item.loading}
          />
        </div>
      ))}
    </div>
  );
};

export default ProjectTaskSummary;
