import { useAttachAdditionalData } from "@sk/hooks";
import { RolesService } from "@sk/services";
import { AppCard, DateFormatter, KeyVal, PageLoader, Toaster } from "@sk/uis";
import { sortBy } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "50%" };

const groupsStyle = {
  maxHeight: "200px",
  overflowY: "auto",
  overflowX: "hidden",
};

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const getData = async (params) => {
  const r = await RolesService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const ViewRolesModal = ({ show, closeModal, roleId }) => {
  // Page Loading
  const [loading, setLoading] = useState(false);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const loadDetails = async () => {
      setLoading(true);
      let m = await getData({ filter: { _id: roleId } });
      const d = m?.length ? m[0] : {};
      if (d._id) {
        let tmp = [];

        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length === attachAdditionalDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });

        loadParentRoleDetails(d);
        const sortedGroups = d.groups ? sortBy(d.groups) : [];
        setData((prevData) => ({
          ...prevData,
          ...d,
          groups: sortedGroups, // Ensure groups are sorted here
        }));
        setLoading(false);
      } else {
        Toaster.error("Failed to fetch data, please try again");
        setLoading(false);
        closeModal();
      }
    };

    if (roleId && show) {
      loadDetails();
    } else {
      setData({});
      setLoading(false);
    }
  }, [
    attachAllData,
    closeModal,
    loadParentRoleDetails,
    roleId,
    setAdditionalData,
    show,
  ]);

  // Fetching Parent Role Details
  const loadParentRoleDetails = useCallback(async (d) => {
    if (d?.parentRoleId) {
      const r = await getData({
        filter: { _id: { $in: d?.parentRoleId } },
      });
      d._parent = r.length ? r[0] : {};
      setData((prevData) => ({
        ...prevData,
        ...d,
      }));
    }
  }, []);

  // Filtered groups based on search term
  const filteredGroups = (data.groups || []).filter((group) =>
    group.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title"> Access Role #{data?.name} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        {loading && <PageLoader />}

        <Offcanvas.Body className="border-top modal-bg">
          {!loading && (
            <div>
              {/* Basic Details */}
              <AppCard title="Basic Details">
                <div className="row">
                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Role Name">
                      <span className="text-primary"> {data.name}</span>
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Role Type">
                      {data.roleType}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Parent Role">
                      {data?._parent?.name || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Department">
                      {data?.department || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Created On">
                      <DateFormatter date={data.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Created By">
                      {data?._createdBy?.name || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Modified On">
                      <DateFormatter date={data.lastUpdated} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal template="col" label="Modified By">
                      {data?._modifiedBy?.name || "N/A"}
                    </KeyVal>
                  </div>
                </div>
              </AppCard>

              {/* Access Groups */}
              <AppCard title="Enabled Access Groups">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search groups..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="fs-val-sm text-muted mb-3">
                  Showing {filteredGroups.length} of {data.groups?.length}
                </div>
                <div className="custom-scrollbar" style={groupsStyle}>
                  <div className="row">
                    {filteredGroups.map((e, index) => (
                      <div
                        key={index}
                        className="col-4 fs-val-md border-bottom py-1"
                      >
                        {e}
                      </div>
                    ))}
                    {!filteredGroups.length && (
                      <div className="col-12">N/A</div>
                    )}
                  </div>
                </div>
              </AppCard>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewRolesModal);
