import { yupResolver } from "@hookform/resolvers/yup";
import { UserService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppTitle,
  BooleanCheckboxInput,
  PageLoader,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

// Canvas Style for Total width Required For Modal
const canvasStyle = { width: "30%" };

// Group Validation Schema
const groupsValidationSchema = yup.object({
  name: yup.string().trim().required("Group name is required"),

  feature: yup.string().trim().required("Feature name is required"),

  description: yup.string().trim(),

  isInternalGroup: yup.boolean(),

  type: yup.string().trim().required("Type is required"),
});

// Default Form Values
const defaultFromValues = {
  name: "",
  feature: "",
  isInternalGroup: false,
  description: "",
  type: "",
};

const groupTypes = [
  {
    label: "Choose Type",
    value: "",
  },
  {
    label: "Dashboard",
    value: "Dashboard",
  },
  {
    label: "Management",
    value: "Management",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const ManageGroupsModal = ({ show, closeModal, mode, modalData }) => {
  const [pageLoading, setPageLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [isDuplicate, setIsDuplicate] = useState(false);
  // form configuration
  const {
    reset,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
  } = useForm({
    defaultValues: defaultFromValues,
    resolver: yupResolver(groupsValidationSchema),
  });

  useEffect(() => {
    // when the modal get opened clear the form data
    if (show) {
      resetFormData();
    }

    // If mode is edit
    if (mode == "edit") {
      // When Both Exists
      if (modalData._id && show) {
        loadEditData(modalData);
      }

      // When ID is not There
      if (!modalData._id && show) {
        Toaster.error("Invalid ID");
        triggerCloseModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, modalData, show]);

  // Preparing Payload
  const preparePayload = (data) => {
    const p = {
      ...data,
      _id: mode == "add" ? data.name.replace(/\s/g, "") : modalData._id,
    };
    return p;
  };

  // for reseting the form data and its dependent state
  const resetFormData = () => {
    reset(defaultFromValues);
  };

  // Loading Edit Data
  const loadEditData = async (modalData) => {
    setPageLoading(true);

    // preparing Form Values
    Object.keys(defaultFromValues).forEach((x) => {
      setValue(x, modalData[x]);
    });

    setPageLoading(false);
  };

  // Submitting Form
  const onSubmit = async (data) => {
    if (isDuplicate) {
      Toaster.error("Group Name already exist");
      return;
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);
    setSubmitting(true);

    let r = {};
    //  API will Go here
    if (mode == "add") {
      r = await UserService.createAccessGroup(p);
    } else {
      r = await UserService.updateAccessGroup(modalData._id, p);
    }

    // After api call removed loader
    setSubmitting(false);

    if (r.statusCode !== 200) {
      Toaster.error(
        r.resp.message?.length ? r.resp.message[0] : "Failed to update"
      );
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Created" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  // Closing Modal
  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  const onNameChange = useCallback(
    debounce(() => {
      loadGroupNames();
    }, 1000),
    [loadGroupNames]
  );

  const loadGroupNames = useCallback(async () => {
    setIsDuplicate(false);
    setSubmitting(true);
    let p = { filter: { name: getValues("name") } };
    const r = await UserService.getGroupsList(p);
    let d = Array.isArray(r.resp) ? r.resp : [];
    setIsDuplicate(d?.length ? true : false);
    setSubmitting(false);
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        backdrop="static"
        keyboard={false}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <AppTitle
            title={
              (mode === "edit" ? "Edit" : "Add") +
              " Groups" +
              (mode === "edit" ? "  " + " - " + modalData._id : "")
            }
          />
        </Offcanvas.Header>
        {/* Page Loading  */}
        {pageLoading && <PageLoader />}

        {/* Page is Not Loading */}
        {!pageLoading && (
          <>
            <Offcanvas.Body className="modal-bg">
              <AppCard>
                <form autoComplete="off">
                  {/* Group Name */}
                  <TextInput
                    name="name"
                    register={register}
                    label={"Group Name"}
                    isMandatory={true}
                    placeholder="Enter Group Name Here"
                    error={errors?.name?.message}
                    callback={onNameChange}
                  />

                  {/* Feature Name  */}
                  <TextInput
                    name="feature"
                    register={register}
                    label={"Feature Name"}
                    isMandatory={true}
                    placeholder="Enter Feature Name Here"
                    error={errors?.feature?.message}
                  />

                  <SelectInput
                    name="type"
                    register={register}
                    label="Type"
                    isMandatory={true}
                    options={groupTypes}
                    error={errors?.type?.message}
                  />

                  {/* Description */}
                  <TextareaInput
                    name="description"
                    register={register}
                    label="Description"
                    placeholder="Enter Description"
                    error={errors?.description?.message}
                  />

                  {/* Is Internal Group */}
                  <Controller
                    name="isInternalGroup"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <BooleanCheckboxInput
                        label="Is Internal Group ?"
                        name="isInternalGroup"
                        error={errors?.isInternalGroup?.message}
                        isMandatory={false}
                        callback={onChange}
                        value={value}
                      />
                    )}
                  />
                </form>
                {/* Action Button */}
                <div className="w-100">
                  <div className="text-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submitting}
                    >
                      Submit {submitting ? <Spinner isSmall={true} /> : null}
                    </button>
                  </div>
                </div>
              </AppCard>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
};

export default memo(ManageGroupsModal);
