import { AppCard, DateInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  callAnsweredOptions,
  defaultFilterData,
  statusOptions,
} from "./constants";

const dateInputConfig = {
  mode: "range",
  maxDate: new Date(),
};

const MarketingLogsFilter = ({ callback, filterData }) => {
  const { register, control, getValues, setValue, reset } = useForm({
    defaultValues: defaultFilterData,
  });

  useEffect(() => {
    if (filterData) {
      Object.keys(filterData).forEach((key) => {
        setValue(key, filterData[key]);
      });
    }
  }, [filterData, setValue]);

  const triggerCb = useCallback(
    debounce(() => {
      callback({ action: "apply", formData: getValues() });
    }, 500),
    [callback, getValues]
  );

  const onInputChange = useCallback(() => {
    triggerCb();
  }, [triggerCb]);

  const onReset = () => {
    reset(defaultFilterData);
    triggerCb();
  };

  const onStatusChange = () => {
    triggerCb();
  };

  const onDateRangeChange = (chngFn) => (dates) => {
    chngFn(dates);
    triggerCb();
  };

  const onCallAnsweredChange = () => {
    triggerCb();
  };

  return (
    <AppCard>
      <div className="row g-2">
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search by Call ID"
            type="text"
            register={register}
            name="search"
            error={null}
            callback={onInputChange}
            value={getValues("search")}
            onChange={(e) => {
              setValue("search", e.target.value);
              onInputChange();
            }}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-3">
          <TextInput
            label="Employee"
            placeholder="Search by Employee Name/ID"
            type="text"
            register={register}
            name="employee"
            error={null}
            callback={onInputChange}
            value={getValues("employee")}
            onChange={(e) => {
              setValue("employee", e.target.value);
              onInputChange();
            }}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-3">
          <TextInput
            label="Customer"
            placeholder="Search by Customer Name/ID"
            type="text"
            register={register}
            name="customer"
            error={null}
            callback={onInputChange}
            value={getValues("customer")}
            onChange={(e) => {
              setValue("customer", e.target.value);
              onInputChange();
            }}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-3">
          <SelectInput
            label="Call Status"
            name="status"
            options={statusOptions}
            register={register}
            callback={onStatusChange}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-3">
          <SelectInput
            label="Call Answered"
            name="callAnswered"
            options={callAnsweredOptions}
            register={register}
            callback={onCallAnsweredChange}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="dateRange"
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Date Range"
                value={value}
                callback={onDateRangeChange(onChange)}
                size="sm"
                gap={0}
                config={dateInputConfig}
                template={2}
              />
            )}
          />
        </div>

        <div className="col-3 align-self-end">
          <button
            className="btn btn-warning btn-sm px-3"
            type="button"
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(MarketingLogsFilter);
