import { AppCard, PageLoader, SelectInput, TextInput } from "@sk/uis"; // Adjust the path as necessary
import { useState, useEffect, useRef, useCallback } from "react";
import { debounce, orderBy } from "lodash"; // Ensure lodash is installed
import { EmployeeService, UtilityService } from "@sk/services";
import { useForm } from "react-hook-form";
import { produce } from "immer";

const employeeStyle = {
  height: "calc(100vh - 320px)",
  overflowY: "auto",
  overflowX: "hidden",
};

const select = [
  "firstName",
  "lastName",
  "empCode",
  "assignment_details",
  "contact_details",
  "lastLogin",
  "createdAt",
  "agentId",
  "sipId",
];

const getData = async (params) => {
  const resp = await EmployeeService.getEmployees(params);
  return { data: Array.isArray(resp.resp) ? resp.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await EmployeeService.getEmployeesCount(p);
  return { count: typeof r.resp == "number" ? r.resp : 0 };
};

const prepareParams = (filter, pagination) => {
  let p = {
    page: pagination.page,
    count: pagination.count,
    filter: {
      status: "Active",
      "assignment_details.department": "Office Employee",
    },
    select: select.join(","),
  };

  if (filter?.search) {
    p.filter.$or = [
      { firstName: { $regex: filter.search, $options: "i" } },
      { _id: filter.search },
      { empCode: filter.search },
    ];
  }

  if (filter?.designation) {
    p.filter["assignment_details.designation"] = filter.designation;
  }

  return p;
};

const RoleDashEmp = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [recordCount, setRecordCount] = useState({ loading: false, count: 0 });
  const [designations, setDesignations] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const paginationRef = useRef({
    page: 1,
    count: 10,
  });

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    setSelectedEmployeeId(null);
    paginationRef.current.page = 1;
    setEmployees([]);
    loadList();

    setRecordCount({ loading: true, count: 0 });
    const count = await getCount(
      prepareParams(getValues(), paginationRef.current)
    );
    setRecordCount({ loading: false, count: count.count });
  }, [getValues, loadList]);

  const loadList = useCallback(
    async (isLoadMore = false) => {
      if (isLoadMore) {
        setIsMoreLoading(true);
      } else {
        setIsLoading(true);
      }

      const params = prepareParams(getValues(), paginationRef.current);

      const resp = await getData(params);

      if (isLoadMore) {
        setIsMoreLoading(false);
        setEmployees(
          produce((draft) => {
            draft.push(...resp.data);
          })
        );
      } else {
        setIsLoading(false);
        setEmployees(resp.data || []);
        setSelectedEmployeeId(resp.data[0]?._id);
        callback({ emp: resp.data[0], action: "default" });
      }

      setHasMore(resp.data?.length >= paginationRef.current.count);
    },
    [callback, getValues]
  );

  useEffect(() => {
    const fetchDesignations = async () => {
      const r = await UtilityService.getOfficeDesignations({
        page: 1,
        count: 100,
      });
      const options = Array.isArray(r.resp)
        ? orderBy(r.resp, ["name"]).map((d) => ({
            label: d.name,
            value: d.name,
          }))
        : [];
      options.unshift({ label: "All", value: "" });
      setDesignations(options);
    };
    fetchDesignations();
    init();
  }, [init]);

  const onEmployeeSelect = (e) => {
    setSelectedEmployeeId(e._id);
    callback({ emp: e, action: "select" });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      applyFilter();
    }, 800),
    []
  );

  const onDepartmentChange = (e) => {
    applyFilter();
  };

  const onLoadMore = async () => {
    paginationRef.current.page++;
    setIsMoreLoading(true);
    await loadList(true);
    setIsMoreLoading(false);
  };

  return (
    <>
      <AppCard title="Employees">
        <div>
          <SelectInput
            name="designation"
            options={designations}
            callback={onDepartmentChange}
            register={register}
          />
        </div>

        <div>
          <TextInput
            name="search"
            register={register}
            placeholder="Search for Employee"
            callback={onSearchChange}
          />
        </div>

        {isLoading ? <PageLoader /> : null}

        {employees.length === 0 && !isLoading && (
          <div className="text-center mt-3 fs-val-xs">No data found</div>
        )}

        <div style={employeeStyle} className="custom-scrollbar">
          {employees.map((e) => (
            <button
              key={e._id}
              className={`d-flex justify-content-between align-items-center w-100 btn text-start mb-2 ${
                selectedEmployeeId === e._id ? "btn-primary" : "btn-light"
              }`}
              onClick={() => onEmployeeSelect(e)}
            >
              <div>
                <div className="fs-val-md">
                  {e.firstName} {e.lastName} - {e.empCode}
                </div>
                <div
                  className={`fs-val-xs ${
                    selectedEmployeeId === e._id ? "text-white" : "text-muted"
                  }`}
                >
                  {e.assignment_details?.designation}
                </div>
              </div>
              <i className="bi bi-chevron-right"></i>
            </button>
          ))}
        </div>

        {hasMore && (
          <div className="text-center mt-2">
            <button
              className="btn btn-light btn-sm"
              onClick={onLoadMore}
              disabled={isMoreLoading}
            >
              <i className="bi bi-arrow-repeat"></i>{" "}
              {isMoreLoading ? "Loading..." : "Load More"}
              <span className="text-muted fs-val-sm ms-1">
                ({employees.length} / {recordCount.count})
              </span>
            </button>
          </div>
        )}
      </AppCard>
    </>
  );
};

export default RoleDashEmp;
