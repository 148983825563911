import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { RouterProvider, createHashRouter } from "react-router-dom";

import Layout from "./pages/Layout";

import BulkUploadList from "./pages/bulk-upload/BulkUploadList";
import ListCronJobs from "./pages/cron-jobs/ListCronJobs";
import ListGroups from "./pages/groups/ListGroups";
import NotifCallLogsList from "./pages/notifications/call-logs/list/NotifCallLogsList";
import NotifEventsList from "./pages/notifications/events/list/NotifEventsList";
import NotifLogsList from "./pages/notifications/template-logs/list/NotifLogsList";
import NotifTempList from "./pages/notifications/template/list/NotifTempList";
import NotifTempForm from "./pages/notifications/template/manage/NotifTempForm";
import NotifTempView from "./pages/notifications/template/view/NotifTempView";
import ProjectList from "./pages/project-management/projects/ProjectList";
import ProjectTasks from "./pages/project-management/tasks/ProjectTasks";
import RolesDashboard from "./pages/roles/dashboard/RolesDashboard";
import ListRoles from "./pages/roles/ListRoles";
import MarketingLogs from "./pages/marketing/support-logs/MarketingLogs";
import WhatsAppTemplateList from "./pages/marketing/whatsapp-templates/list/MarketingCampaignList";
import WhatsAppTemplateManage from "./pages/marketing/whatsapp-templates/manage/WhatsAppTemplateManage";
import WhatsappView from "./pages/marketing/whatsapp-templates/view/WhatsappView";

const roles = {
  roles: ["ViewRole"],
  groups: ["ViewGroup"],
  bulkUpload: ["EditDeal"],
  notifications: ["ViewNotificationTemplate", "EditNotificationTemplate"],
  events: ["ViewEvent"],
  cronJobs: ["ViewZohoDetails"],
  project: ["ViewProject", "EditProject", "AddProject"],
  task: ["ViewTask"],
  manageEmployeeAccess: ["ManageEmployeeAccess"],
  marketing: ["ListMarketinglog"],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "utility",
        element: <Layout />,
        children: [
          {
            path: "roles",
            element: (
              <ProtectedRoute roles={roles.roles}>
                <ListRoles />
              </ProtectedRoute>
            ),
          },
          {
            path: "groups",
            element: (
              <ProtectedRoute roles={roles.groups}>
                <ListGroups />
              </ProtectedRoute>
            ),
          },
          {
            path: "bulkupload",
            element: (
              <ProtectedRoute>
                <BulkUploadList />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/list",
            element: (
              <ProtectedRoute roles={roles.notifications}>
                <NotifTempList />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/manage",
            element: (
              <ProtectedRoute roles={roles.notifications}>
                <NotifTempForm />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/view",
            element: (
              <ProtectedRoute roles={roles.notifications}>
                <NotifTempView />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/logs/list",
            element: (
              <ProtectedRoute roles={roles.notifications}>
                <NotifLogsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/events/list",
            element: (
              <ProtectedRoute roles={roles.events}>
                <NotifEventsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "notifications/callLogs",
            element: (
              <ProtectedRoute roles={roles.notifications}>
                <NotifCallLogsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "cron-jobs",
            element: (
              <ProtectedRoute roles={roles.cronJobs}>
                <ListCronJobs />
              </ProtectedRoute>
            ),
          },
          {
            path: "roles/dashboard",
            element: (
              <ProtectedRoute roles={roles.manageEmployeeAccess}>
                <RolesDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "project-management/projects",
            element: (
              <ProtectedRoute roles={roles.project}>
                <ProjectList />
              </ProtectedRoute>
            ),
          },
          {
            path: "project-management/tasks",
            element: (
              <ProtectedRoute roles={roles.task}>
                <ProjectTasks />
              </ProtectedRoute>
            ),
          },
          {
            path: "marketing/logs",
            element: (
              <ProtectedRoute roles={roles.marketing}>
                <MarketingLogs />
              </ProtectedRoute>
            ),
          },
          {
            path: "whatsapp-template/manage",
            element: (
              <ProtectedRoute>
                <WhatsAppTemplateManage />
              </ProtectedRoute>
            ),
          },
          {
            path: "whatsapp-template",
            element: (
              <ProtectedRoute>
                <WhatsAppTemplateList />
              </ProtectedRoute>
            ),
          },
          {
            path: "whatsapp-template/view",
            element: (
              <ProtectedRoute>
                <WhatsappView />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
