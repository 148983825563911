// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BhuZLRhisNm8NhNlrwnO{height:calc(100vh - 380px);overflow-y:auto;overflow-x:hidden}.SSAbpZ4w5ItT0QU0oSAv,.yGFRtC2ItdSHp2S3jbp6{max-width:75%}`, "",{"version":3,"sources":["webpack://./src/pages/project-management/ProjectManagement.module.scss"],"names":[],"mappings":"AACA,sBACE,0BAAA,CACA,eAAA,CACA,iBAAA,CAGF,4CAEE,aAAA","sourcesContent":["// Start Generation Here\n.commentsContainer {\n  height: calc(100vh - 380px);\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.task-view-modal,\n.task-manage-modal {\n  max-width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentsContainer": `BhuZLRhisNm8NhNlrwnO`,
	"task-view-modal": `SSAbpZ4w5ItT0QU0oSAv`,
	"task-manage-modal": `yGFRtC2ItdSHp2S3jbp6`
};
export default ___CSS_LOADER_EXPORT___;
