import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../ProjectManagement.module.scss"; // Import styles from SCSS module
import ProjectTaskCommentItem from "./ProjectTaskCommentItem";
import classNames from "classnames";
import {
  AppSlide,
  AppSwiper,
  FileUpload2,
  Rbac,
  Spinner,
  Toaster,
} from "@sk/uis";
import { appConfigs, ProjectService } from "@sk/services";

const defaultPagination = { page: 1, count: 10 };

const rbac = {
  addComment: ["AddTaskComment"],
};

const getData = async (taskId, params) => {
  const r = await ProjectService.getComments(taskId, params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const prepareFilterParams = (pagination) => {
  return {
    page: pagination.page,
    count: pagination.count,
  };
};

const ProjectTaskComments = ({ taskId, projectId }) => {
  const [newComment, setNewComment] = useState("");
  const [attachments, setAttachments] = useState([]);

  const [comments, setComments] = useState([]);

  const [loadingComments, setLoadingComments] = useState(false);

  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const pginationRef = useRef({ ...defaultPagination });

  const applyFilter = useCallback(async () => {
    pginationRef.current = { ...defaultPagination };

    setLoadingComments(true);
    const r = await getData(
      taskId,
      projectId,
      prepareFilterParams(pginationRef.current)
    );
    setComments(r.data);
    setLoadingComments(false);
  }, [taskId, projectId]);

  const loadMore = async () => {
    pginationRef.current.page++;
    setLoadMoreLoading(true);
    const r = await getData(taskId, prepareFilterParams(pginationRef.current));
    setComments([...comments, ...r.data]);
    setLoadMoreLoading(false);
  };

  useEffect(() => {
    applyFilter();
  }, [taskId, projectId, applyFilter]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newComment.trim()) {
      Toaster.error("Comment cannot be empty.");
      return;
    }

    setSubmitLoading(true);
    const r = await ProjectService.postComment(taskId, projectId, {
      text: newComment,
      documents: attachments.map((e) => ({ assetId: e._id, type: e.type })),
    });
    setSubmitLoading(false);
    if (r.statusCode === 200) {
      Toaster.success("Comment sent successfully.");
      applyFilter();
      setNewComment("");
      setAttachments([]);
    } else {
      Toaster.error("Failed to send comment.");
    }
  };

  const handleFileUpload = (fileData) => {
    if (fileData.status === "success") {
      setAttachments((prev) => [...prev, fileData.resp]); // Add the uploaded file to attachments
    } else {
      Toaster.error("File upload failed.");
    }
  };

  const handleDownloadAttachment = (id) => {
    window.open(appConfigs.ASSET + "/" + id, "_blank");
  };

  return (
    <div className="comments-wrapper">
      <div
        className={classNames(
          styles.commentsContainer,
          "custom-scrollbar px-3"
        )}
      >
        {loadingComments && (
          <div className="text-center py-4">
            <Spinner />
          </div>
        )}

        {comments.map((comment) => (
          <div key={comment.id} className="border-bottom">
            <ProjectTaskCommentItem
              comment={comment}
              handleDownloadAttachment={handleDownloadAttachment}
            />
          </div>
        ))}

        {comments.length === 0 && !loadingComments && (
          <div className="text-center py-4">
            <i className="bi bi-chat-left-dots fs-4 text-muted mb-2"></i>
            <p className="text-muted mb-0">No comments available.</p>
          </div>
        )}

        {comments.length > 0 && (
          <div className="text-center py-3">
            <button
              className="btn btn-light btn-sm"
              disabled={loadMoreLoading}
              onClick={loadMore}
            >
              {loadMoreLoading ? (
                <span className="d-flex align-items-center">
                  <Spinner size="sm" className="me-2" />
                  Loading...
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  <i className="bi bi-arrow-repeat me-2"></i>
                  Load More
                </span>
              )}
            </button>
          </div>
        )}
      </div>

      <Rbac roles={rbac.addComment}>
        <div className="border-top p-3">
          <form onSubmit={handleSubmit} className={styles.commentForm}>
            <div className="mb-3">
              <textarea
                value={newComment}
                onChange={handleCommentChange}
                placeholder="Write a comment..."
                className="form-control"
                rows="3"
              />
            </div>

            {attachments.length > 0 && (
              <div className="mb-3 bg-light rounded p-2">
                <AppSwiper config={{ slidesPerView: "auto", spaceBetween: 8 }}>
                  {attachments.map((file, index) => (
                    <AppSlide key={index} autoWidth>
                      <div className="d-flex align-items-center bg-white rounded px-2 py-1">
                        <button
                          className="btn btn-link text-dark fs-val-sm p-0 me-2"
                          onClick={() => handleDownloadAttachment(file._id)}
                          type="button"
                        >
                          <i className="bi bi-file-earmark me-1"></i>
                          <span>Attachment {index + 1}</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link text-danger p-0"
                          onClick={() => handleRemoveAttachment(index)}
                        >
                          <i className="bi bi-x-lg"></i>
                        </button>
                      </div>
                    </AppSlide>
                  ))}
                </AppSwiper>
              </div>
            )}

            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <FileUpload2
                  url={appConfigs.ASSET_UPLOAD}
                  callback={handleFileUpload}
                >
                  <button type="button" className="btn btn-light btn-sm">
                    <i className="bi bi-paperclip me-2"></i>
                    Attach Files
                  </button>
                </FileUpload2>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  disabled={submitLoading}
                >
                  {submitLoading ? (
                    <span className="d-flex align-items-center">
                      <Spinner size="sm" className="me-2" />
                      Sending...
                    </span>
                  ) : (
                    <span className="d-flex align-items-center">
                      <i className="bi bi-send me-2"></i>
                      Send
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Rbac>
    </div>
  );
};

export default ProjectTaskComments;
