import { CronJobService } from "@sk/services";
import {
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ViewRecordModal from "../ViewRecordModal";
import { debounce } from "lodash";

const defaultFormData = {
  search: "",
};

const defaultSortOpt = { key: "errorMessage", value: "async" };

const getData = async (params) => {
  const r = await CronJobService.getErrorLogs(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.page;
  delete params.count;
  try {
    const r = await CronJobService.getErrorLogsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const header = [
  { label: "Sl.no" },
  { label: "ID" },
  { label: "Record", isCentered: true },
  { label: "Error Message", key: "errorMessage", enableSort: true },
];

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  const { jobId } = additionalData;

  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      jobId,
    },
  };

  let search = filter?.search?.trim();

  let searchRegex = { $regex: search, $options: "gi" };

  if (search) {
    p.filter.$or = [{ _id: searchRegex }, { recordId: searchRegex }];
  }
  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }
  return p;
};

const defaultPaginationOpt = {
  totalRecords: 0,
  rowsPerPage: 25,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 25,
};

const ErrorLogsTable = ({ jobId }) => {
  const { register, getValues, reset } = useForm();

  const [data, setData] = useState([]);
  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  const [showRecordModal, setShowRecordModal] = useState(false);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const filterDataRef = useRef({ ...defaultFormData });

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const recordModalRef = useRef({ details: {} });

  // To Store Sorting  Data
  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    if (jobId) {
      init();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [jobId, init]);

  const openRecordModal = useCallback((details) => {
    recordModalRef.current.details = details;
    setShowRecordModal(true);
  }, []);

  const recordModalCb = useCallback(() => {
    recordModalRef.current.details = {};
    setShowRecordModal(false);
  }, []);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        jobId,
      }
    );
  }, [jobId]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    // let tmp = [];
    // setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
    //   tmp.push(x);
    //   if (tmp.length == listView.additionalTableDataConfig.length) {
    //     setData([...attachAllData(d, tmp)]);
    //   }
    // });

    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationOpt };

    loadList();

    loadCount();
  }, [loadList, loadCount]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = { ...getValues() };
      applyFilter();
    }, 800),
    [applyFilter]
  );

  const resetFilter = useCallback(() => {
    reset(defaultFormData);
    applyFilter();
  }, [applyFilter, reset]);

  //  To Sort Cb
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <div className="row mb-2 px-2 align-items-center">
        <div className="col-4">
          <TextInput
            type="text"
            register={register}
            name="search"
            callback={onSearch}
            placeholder="Search for ID / RecordID "
          />
        </div>
        <div className="col-auto ms-auto ">
          <button className="btn btn-outline-warning" onClick={resetFilter}>
            Reset
          </button>
        </div>
      </div>
      <div className="pb-2">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <table className="table table-bordered table-sm px-4 mx-0">
        <TableHeader data={header} sort={sortRef.current} sortCb={sortCb} />
        <tbody>
          {loadingData && (
            <TableSkeletonLoader rows={10} cols={header.length} height={40} />
          )}

          {!loadingData && data?.length == 0 && (
            <tr>
              <td
                colSpan={header.length}
                className="text-center py-2 fs-val-sm"
              >
                No data found
              </td>
            </tr>
          )}

          {!loadingData &&
            data?.length > 0 &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-md">
                <td className="text-center">
                  {paginationRef.current.startSlNo + i}{" "}
                </td>
                <td className="text-center">{x._id}</td>
                <td className="text-center">
                  <div className="mb-2">{x.recordId}</div>
                  <button
                    className="btn btn-link"
                    onClick={() => openRecordModal(x)}
                  >
                    View Record
                  </button>
                </td>
                <td className="text-break">{x.errorMessage}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />

      <ViewRecordModal
        show={showRecordModal}
        callback={recordModalCb}
        details={recordModalRef.current.details}
      />
    </>
  );
};

export default ErrorLogsTable;
